import React, { useEffect, useState } from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Navbar from '../../../layouts/NavBar/Navbar';
import { getExternalDashboardMetrics } from '../../../services/utilityServices';

const ExternalMetrics = () => {
  const [history, setHistory] = useState<any>([]);
  const { t } = useTranslation();
  const theme: 'light' | 'dark' = 'dark';
  const [cards, setCards] = useState([
    {
      id: 1,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/person.svg`,
      number: 0,
      text: `${t('applications', 'Applications')}`,
    },
    {
      id: 2,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/person-check.svg`,
      number: 0,
      text: `${t('verifiedSuppliers', 'Verified Suppliers')}`,
    },
    {
      id: 3,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/person.svg`,
      number: 0,
      text: `${t('foreignSuppliers', 'Foreign Suppliers')}`,
    },
    {
      id: 4,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/script.svg`,
      number: 0,
      text: `${t('verifiedOperators', 'Verified Operators')}`,
    },
    {
      id: 5,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/script.svg`,
      number: 0,
      text: `${t('foreignOperators', 'Foreign Operators')}`,
    },
    {
      id: 6,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/gas.svg`,
      number: 0,
      text: `${t('totalOperators', 'Total Operators')}`,
    },
    {
      id: 7,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/support.svg`,
      number: 0,
      text: `${t('ticketsFeedback', 'Tickets/Feedback')}`,
    },
    {
      id: 8,
      icon: `${process.env.PUBLIC_URL}/assets/icons/external/portfolio.svg`,
      number: 0,
      text: `${t('goodsServices', 'Goods & Services')}`,
    },
  ]);

  const getExternalMetricsData = async () => {
    try {
      const response = await getExternalDashboardMetrics();
      if (response?.isSuccess) {
        const data = response.data;
        setCards((prevCards) =>
          prevCards.map((card) => {
            switch (card.id) {
              case 2:
                return { ...card, number: data.verifiedSuppliers };
              case 3:
                return { ...card, number: data.foreignSuppliers };
              case 4:
                return { ...card, number: data.verifiedOperators };
              case 5:
                return { ...card, number: data.foreignOperators };
              case 8:
                return { ...card, number: data.goodsAndServices };
              default:
                return card;
            }
          })
        );
      } else {
        toast.error(response.errorMessage);
      }
    } catch (error) {
      toast.error('There was a problem with the fetch operation');
    }
  };

  useEffect(() => {
    getExternalMetricsData();
  }, []);

  return (
    <div>
      <Navbar theme={theme} />
      <div className="view-area">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          {cards.map((card) => (
            <div key={card.id} className="card flex">
              <div className=" ">
                <img src={card.icon} alt="Icon" />
              </div>
              <div className="card-info">
                <p className="card-number">{card.number}</p>
                <p className="text-[14px]" style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                  {card.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="footer bg-white">
        <div className="social-links flex justify-center py-4">
          <a href="https://instagram.com" className="mx-2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/social/instagram.svg`}
              alt="Facebook"
            />
          </a>
          <a href="https://linkedin.com" className="mx-2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/social/linkedin.svg`}
              alt="Facebook"
            />
          </a>
          <a href="https://facebook.com" className="mx-2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/social/facebook-2.svg`}
              alt="Facebook"
            />
          </a>
          <a href="https://twitter.com" className="mx-2">
            <img src={`${process.env.PUBLIC_URL}/assets/icons/social/twitter.svg`} alt="Facebook" />
          </a>
        </div>
        <hr className="border-t-3 mx-auto w-4/5 border-black" />
        <div className="footer-text py-4 text-center">
          <p className="text-xs">Copyright &copy; 2024 ANP ESSC Portal. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default ExternalMetrics;

import { Search } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const ContractsHomePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialStatus = searchParams.get('status') || 'Active';

  const [searchTerm, setSearchTerm] = useState('');
  const [activeStatus, setActiveStatus] = useState(initialStatus);

  const statusMap = {
    Active: 0,
    Completed: 1,
    Expired: 2,
    'Offers Sent': 3,
  };

  useEffect(() => {
    setSearchParams({ status: activeStatus });
  }, []);

  const handleStatusChange = (status: string) => {
    setActiveStatus(status);
    setSearchParams({ status });
    // queryClient.invalidateQueries({ queryKey: ['procurements', status] });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {};
  return (
    <div className="mx-auto max-w-screen-xl">
      <div className="p-8">
        <div className="mb-8">
          <h1 className="mb-1 text-2xl font-semibold text-gray-900">Contract Management</h1>
          <p className="text-base text-[#525252]">Track and manage all your contracts</p>
        </div>

        <div className="min-h-[80vh] w-full rounded-2xl border border-[#E5E5E5] bg-white p-8">
          <div className="relative mb-6">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full rounded-lg border border-[#D0D5DD] bg-white px-3.5 py-2.5 pl-12 text-base text-gray-800"
            />
            <Search
              className="absolute left-4 top-1/2 -translate-y-1/2 transform text-gray-500"
              size={20}
            />
          </div>

          <>
            <div className="mb-6 flex space-x-10">
              {Object.keys(statusMap).map((status) => (
                <button
                  key={status}
                  className={`min-w-[106px] rounded-full px-3.5 py-2.5 text-base ${
                    activeStatus === status
                      ? 'bg-green-600 font-semibold text-white'
                      : 'bg-none text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => {
                    handleStatusChange(status);
                  }}
                >
                  {status}
                </button>
              ))}
            </div>

            {Array.from({ length: 5 }).length > 0 ? (
              <div className="overflow-auto rounded-xl border border-[#E4E7EC] shadow-sm">
                <div className="grid grid-cols-[1fr_1.3fr_.5fr_.5fr] gap-4 rounded-t-xl border-b border-[#D2D6DB] bg-[#F5F5F5] px-5 py-[14px] text-sm font-semibold text-[#40474F]">
                  <span>Business Name</span>
                  <span>Contract Name</span>
                  <span>Date</span>
                  <span>Status</span>
                </div>

                {Array.from({ length: 5 }).map((_, index) => {
                  const currentStatus = searchParams.get('status');

                  return (
                    <Link
                      key={index}
                      to="/contracts/123"
                      className="grid grid-cols-[1fr_1.3fr_.5fr_.5fr] items-center gap-4 border-b border-[#D2D6DB] px-5 py-[14px]"
                    >
                      <span className="text-base font-semibold text-[#101828]">
                        Green Earth Consultant Ltd
                      </span>
                      <span className="text-sm font-medium text-[#525252]">
                        Pipeline Inspection and Maintenance
                      </span>
                      <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
                      <span
                        className={`w-fit min-w-[93px] rounded-[60px] px-2.5 py-1 text-center text-sm font-medium text-white ${currentStatus === 'Active' || currentStatus === 'Completed' ? 'bg-[#24A148]' : currentStatus === 'Offers Sent' ? 'bg-[#134C9D]' : 'bg-[#FA4D56]'}`}
                      >
                        {currentStatus === 'Offers Sent' ? 'Pending' : currentStatus}
                      </span>
                    </Link>
                  );
                })}

                <div className="flex items-center justify-between px-6 pb-4 pt-3">
                  <span className="text-sm text-[#344054]">Page 1 of 10</span>

                  <div className="flex gap-x-3">
                    <button className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
                      Previous
                    </button>

                    <button className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex min-h-[40vh] items-center justify-center">
                <div>
                  <h3 className="text-center text-2xl font-semibold text-[#161616]">
                    There are no active contracts
                  </h3>
                  <p className="text-center text-sm font-medium text-[#7F7F7F]">
                    Contracts you’re actively working on will appear here.
                  </p>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ContractsHomePage;

import { Form, Formik } from 'formik';

import FormikInput from '../../components/CustomTextInput/CustomTextInput';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CreateExpressionOfInterestBid,
  CreateTechnicalInfoBid,
} from '../../services/procurementService';
import { toast } from 'react-toastify';
import { TechnicalInfoValues } from '../../types/procurement-types';
import FormikDateInput from '../../components/CustomDatePicker/CustomDatePicker';
import moment from 'moment';

interface deliverables {
  id?: string;
  delivarbles: string;
  dueDate: string;
  amount: number;
}
const TechnicalInformationPage = () => {
  const [declaration, setDeclaration] = useState(false);

  const [deliverables, setDeliverables] = useState<deliverables[]>([]);

  const navigate = useNavigate();

  const { tenderBidId } = useParams<{
    tenderBidId: string;
  }>();

  const validationSchema = Yup.object().shape({
    delivarbles: Yup.string().required('Milestone tag is required'),

    amount: Yup.number()
      .typeError('Amount must be a number')
      .required('Amount is required')
      .positive('Amount must be a positive number'),
    dueDate: Yup.date()
      .required('Due date is required')
      .min(new Date(), 'Due date cannot be in the past'),
  });

  const handleAddMilestone = async (
    values: TechnicalInfoValues,
    {
      resetForm,
      setValues,
    }: { resetForm: () => void; setValues: (values: TechnicalInfoValues) => void }
  ) => {
    console.log('handleSubmit values: ', values);
    setDeliverables([...deliverables, values]);
    resetForm();
    setValues({ delivarbles: '', dueDate: '', amount: 0 });
  };

  const handleSubmit = async () => {
    if (!declaration) {
      toast.error('You must agree to the statutory declaration');
      return;
    }

    if (deliverables.length === 0) {
      toast.error('You must add at least one milestone');
      return;
    }

    const payload = {
      declaration,
      tenderBidId: tenderBidId as string,
      technicalEvaluationDelivarable: deliverables,
    };

    console.log('technical info Payload:', payload);

    try {
      const response = await CreateTechnicalInfoBid(payload);
      console.log('Expression of Interest submitted:', response);

      if (response.isSuccess) {
        toast.success('Technical info submitted successfully');

        setTimeout(() => {
          navigate(`/procurement/bids/${response.data.id}`, { replace: true });
        }, 1000);
      } else {
        toast.error('Failed to submit Technical info. Please try again later.');
      }
    } catch (error) {
      console.error('Failed to submit Technical info:', error);
      toast.error('Failed to submit Technical info . Please try again later.');
    }
  };

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Technical Evaluation</h1>

      <div className="2xl:w-11/12">
        <div className="mb-2.5 mt-8 flex flex-col justify-between gap-x-7 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12 xl:flex-row xl:items-center">
          <div className="grid w-full grid-cols-2 gap-3 xl:grid-cols-4">
            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Contract Name</span>
              <span className="text-sm font-medium text-[#525252]">
                Pipeline Inspection and Maintenance
              </span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Type</span>
              <span className="text-sm font-medium text-[#525252]">Joint Venture</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Date</span>
              <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Status</span>
              <span className="text-sm font-medium text-[#24A148]">Active</span>
            </div>
          </div>

          <button className="ml-auto flex h-12 w-[140px] items-center justify-center rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
            View Details
          </button>
        </div>

        <div className="my-9">
          {/* Background Bar */}
          <div className="relative h-[3px] w-full bg-[#E5E5E5]">
            {/* Completed Section 1 */}
            <div className="absolute -top-[20px] z-10 flex h-[38px] w-[38px] items-center justify-center rounded-full bg-[#42BE65] text-lg font-medium text-white">
              1
            </div>

            <div className="absolute left-0 top-0 h-full w-[50%] bg-[#42BE65]"></div>

            <div className="absolute right-0 top-0 h-full w-[50%] bg-[#42BE65]"></div>

            {/* Completed Section 2 */}
            {/* <div className="absolute top-[25%] h-[25%] w-full bg-[#42BE65]"></div> */}
          </div>
        </div>

        <div className="my-4 rounded-lg bg-[#24A148] px-5">
          <p className="text-xl font-semibold text-white">Stage 2 of 3</p>
        </div>

        <div className="rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[5%] pr-[10%] pt-[33px]">
          <h3 className="mb-[34px] text-xl font-medium text-[#101828]">Deliverables</h3>

          {deliverables.length > 0 && (
            <>
              <div className="w-3/5">
                <div className="mb-4 grid grid-cols-3 border-b border-[#E5E5E5] pb-4 text-sm font-medium text-[#525252]">
                  <span className="">Milestone</span>
                  <span className="">Due date</span>
                  <span className="">Amount</span>
                </div>

                {deliverables.map((deliverableItem, index) => (
                  <div
                    key={index}
                    className="mb-4 grid grid-cols-3 border-b border-[#E5E5E5] pb-4 text-sm font-medium text-[#101828]"
                  >
                    <span className="">{deliverableItem?.delivarbles}</span>
                    <span className="">
                      {moment(deliverableItem?.dueDate).format('MMM D, YYYY')}
                    </span>
                    <span className="">
                      ${new Intl.NumberFormat('en-US').format(deliverableItem?.amount)}
                    </span>
                  </div>
                ))}
              </div>
            </>
          )}

          <Formik<TechnicalInfoValues>
            initialValues={{
              delivarbles: '',
              dueDate: '',
              amount: 0,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleAddMilestone}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <button
                  className="ml-auto mt-7 flex h-9 items-center justify-center rounded-lg border border-[#101828] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm"
                  type="submit"
                >
                  + Add
                </button>
                <div className="flex w-full gap-x-4">
                  <FormikInput
                    label="Deliverables"
                    name="delivarbles"
                    type="text"
                    placeholder="deliverables"
                    isRequired
                  />

                  <FormikDateInput label="Due date" name="dueDate" isRequired />

                  <FormikInput
                    label="Amount"
                    name="amount"
                    type="text"
                    money
                    placeholder="amount"
                    isRequired
                  />
                </div>

                <div className="my-8">
                  <h5 className="mb-8 text-xl font-semibold text-[#101828]">
                    Statutory Declaration
                  </h5>

                  <label className="custom-checkbox flex items-center">
                    <input
                      type="checkbox"
                      checked={declaration} // Bind to Formik value
                      onChange={(e) => {
                        setDeclaration(e.target.checked); // Update Formik value
                      }}
                    />
                    <span className="checkbox"></span>
                    <span className="label !text-sm !text-[#525252]">
                      I, the applicant, hereby attest that the information provided in this
                      submission is accurate and true to the best of my knowledge.
                    </span>
                  </label>
                </div>

                {/* 
                <UploadInput
                  label="Upload Signature"
                  acceptedFileTypes={['image/png', 'image/jpeg', 'application/pdf']}
                  maxFileSizeMB={10}
                  multiple={true}
                  onUpload={handleFileUpload}
                /> */}
              </Form>
            )}
          </Formik>
          <div className="ml-auto mt-10 flex w-full items-center justify-end gap-x-[18px]">
            {/* <button
              className="min-w-[195px] rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0]"
              // disabled={isLoading}
              // onClick={() => setSaveAndContinue(false)} // Save and exit
            >
              Save & Exit
            </button> */}
            <button
              className="min-w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
              // disabled={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalInformationPage;

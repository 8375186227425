import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DeliverablesComponent from './DeliverablesComponent';
import OverviewComponent from './OverviewComponent';

const ContractsDetailsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab = searchParams.get('tab') || 'Overview';

  const [activeTab, setActiveTab] = useState(initialTab);

  // Update the query parameter when the active tab changes
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  // Sync the active tab with query params when the component mounts
  useEffect(() => {
    if (searchParams.get('tab') !== activeTab) {
      setSearchParams({ tab: activeTab });
    }
  }, [activeTab, searchParams, setSearchParams]);

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <div className="2xl:w-11/12">
        <div className="rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          <div className="mb-6 flex space-x-8 border-b border-gray-200">
            {['Overview', 'Deliverables'].map((tab) => (
              <button
                key={tab}
                className={`px-4 py-3 text-sm text-[#525252] ${activeTab === tab ? 'border-b-2 border-primary font-semibold' : ''}`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          <div className="grid items-center gap-y-6 xl:grid-cols-[1fr_1fr]">
            <div className="flex items-center gap-x-6">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
                alt="vendor icon"
                className="h-[58px] w-[58px] rounded-full"
              />

              <div className="flex flex-col items-start gap-y-2">
                <span className="text-sm text-[#8D8D8D]">Contractor Name:</span>
                <span className="font-semibold text-[#101828] underline">
                  Green Earth Consultants Ltd
                </span>

                <span className="rounded-[30px] bg-[#42BE65] px-2.5 py-1 text-[10px] font-medium text-white">
                  Verified Operator
                </span>
              </div>
            </div>

            <div className="grid grid-cols-[1.5fr_1fr]">
              <div className="flex flex-col gap-y-2">
                <span className="text-sm text-[#525252]">Contract Value:</span>
                <span className="text-base font-medium text-[#161616]">$62,000</span>
              </div>

              <div className="flex flex-col gap-y-2">
                <span className="text-sm text-[#525252]">Contract ID:</span>
                <span className="text-base font-medium text-[#161616]">PI-2024</span>
              </div>
            </div>
          </div>

          {activeTab === 'Overview' && <OverviewComponent />}
          {activeTab === 'Deliverables' && <DeliverablesComponent />}
        </div>
      </div>
    </div>
  );
};

export default ContractsDetailsPage;

import React, { InputHTMLAttributes, useState } from 'react';
import { useField } from 'formik';

type FormikInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url';
  isRequired?: boolean;
  inputClassName?: string;
  money?: boolean; // New prop for money-specific behavior
};

const FormikInput: React.FC<FormikInputProps> = ({
  label,
  isRequired,
  inputClassName,
  money = false, // Default to false
  ...props
}) => {
  const [field, meta] = useField({
    name: props.name,
    type: props.type,
    value: props.value,
    multiple: props.multiple,
  });

  const [displayValue, setDisplayValue] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, ''); // Remove existing commas

    if (money) {
      // Allow only numbers (including decimals) for money fields
      if (/^\d*\.?\d*$/.test(rawValue)) {
        const numberValue = parseFloat(rawValue);

        // Format the value as a money figure (comma-separated)
        const formattedValue = new Intl.NumberFormat('en-US').format(numberValue);
        setDisplayValue(formattedValue);

        // Update Formik's field value with the raw number (without commas)
        field.onChange({
          target: {
            name: props.name,
            value: rawValue, // Store the raw value without commas
          },
        });
      } else {
        // If the input is invalid, clear the displayed value
        setDisplayValue('');
        field.onChange({
          target: {
            name: props.name,
            value: '', // Clear the value if it's not a valid number
          },
        });
      }
    } else {
      // For non-money fields, use the input as-is
      setDisplayValue(rawValue);
      field.onChange({
        target: {
          name: props.name,
          value: rawValue,
        },
      });
    }
  };

  return (
    <div className={`mb-5 w-full ${inputClassName}`}>
      {label ? (
        <label
          htmlFor={props.id || props.name}
          className="mb-1.5 block text-sm font-medium text-[#344054]"
        >
          {label}
          {isRequired && <span className="ml-1 text-red-500">*</span>}
        </label>
      ) : null}

      <input
        {...field}
        {...props}
        value={money ? displayValue : field.value} // Use displayValue for money fields
        onChange={handleChange}
        onBlur={field.onBlur} // Ensure Formik's onBlur is called
        className={`w-full rounded-md border px-3.5 py-2.5 shadow-sm ${meta.touched && meta.error ? 'border-red-500' : 'border-[#D0D5DD]'} ${props.className || ''} `}
      />

      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikInput;

import React, { TextareaHTMLAttributes } from 'react';
import { useField } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

type FormikWysiwygProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  name: string;
  isRequired?: boolean;
};

const FormikWysiwyg: React.FC<FormikWysiwygProps> = ({ label, isRequired, ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (value: string) => {
    helpers.setValue(value); // Update Formik's value
  };

  return (
    <div className="mb-5">
      {label && (
        <label htmlFor={props.name} className="mb-1.5 block text-sm font-medium text-[#344054]">
          {label}
          {isRequired && <span className="ml-1 text-red-500">*</span>}
        </label>
      )}

      <ReactQuill
        value={field.value || ''}
        onChange={handleChange}
        placeholder={props.placeholder}
        modules={{
          toolbar: [
            // [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // ['link', 'image'],
            ['link'],
            // ['clean'],
          ],
        }}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'list',
          'bullet',
          'link',
          'image',
        ]}
        className="custom-wysiwyg min-h-32"
        // className={`rounded-md bg-white shadow-sm ${meta.touched && meta.error ? 'border border-red-500' : 'border border-[#D0D5DD]'} `}
      />

      {meta.touched && meta.error && <div className="mt-1 text-sm text-red-600">{meta.error}</div>}
    </div>
  );
};

export default FormikWysiwyg;

import * as Yup from 'yup';

export const TenderDocumentValuesValidation = Yup.object().shape({
  documentName: Yup.string().required('Document type is required'),
  assignPoint: Yup.number()
    .optional()
    .min(0, 'Points cannot be less than 0')
    .max(100, 'Points cannot exceed 100'),
});

export const StartEndDateValidationSchema = Yup.object().shape({
  startdate: Yup.date()
    .required('Start date is required')
    .min(new Date(), 'Start date cannot be in the past'),
  endDate: Yup.date()
    .required('End date is required')
    .min(Yup.ref('startdate'), 'End date must be after the start date'),
});

import { Link } from 'react-router-dom';

interface DeliverableItemProps {
  title: string;
  deadline?: string;
  submissionStatus?: string;
  statusLabel: string;
  statusClass: string;
  iconSrc?: string;
}

const DeliverableItem: React.FC<DeliverableItemProps> = ({
  title,
  deadline,
  submissionStatus,
  statusLabel,
  statusClass,
  iconSrc,
}) => {
  return (
    <div className="relative rounded-2xl border border-[#E9EFF2] px-4 py-6">
      <div className="grid grid-cols-[2fr_1.5fr_.5fr] items-center justify-between">
        <div>
          <h4 className="mb-1 text-base font-medium text-[#292D32]">{title}</h4>

          <div className="flex flex-col gap-x-4 gap-y-1 xl:flex-row">
            {deadline && (
              <p className="text-xs text-[#292D32]">
                <span className="text-[#DA1E28]">Due Date:</span> {deadline}
              </p>
            )}

            {submissionStatus && (
              <p className="text-xs text-[#292D32]">
                <span className="text-[#24A148]">Submitted:</span> {submissionStatus}
              </p>
            )}
          </div>
        </div>

        <div>
          <p className="text-sm italic">Approved 3 months ago</p>
          <Link to="" className="text-sm font-semibold text-[#24A148]">
            View Submissions
          </Link>
        </div>

        <div
          className={`flex h-[33px] items-center justify-center rounded-2xl border px-5 py-3 text-sm font-semibold ${statusClass}`}
        >
          <span>{statusLabel}</span>
        </div>
      </div>

      {iconSrc && (
        <img
          src={iconSrc}
          alt="Icon"
          className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </div>
  );
};

const DeliverablesComponent = () => {
  const deliverables = [
    {
      title: 'Documentation and cost analysis',
      deadline: '29/11/2024',
      submissionStatus: '02/10/2024',
      statusLabel: 'Completed',
      statusClass: 'border-[#42BE65] text-[#42BE65]',
      iconSrc: `${process.env.PUBLIC_URL}/assets/images/tender-completed.svg`,
    },
    {
      title: 'Procurement of equipment',
      deadline: '29/11/2024',
      submissionStatus: 'Not submitted',
      statusLabel: 'In progress',
      statusClass: 'border-[#128FFF] text-[#128FFF]',
      iconSrc: `${process.env.PUBLIC_URL}/assets/images/tender-completed.svg`,
    },
    {
      title: 'Initial Pipeline Inspection',
      deadline: 'N/A',
      submissionStatus: 'N/A',
      statusLabel: 'Upcoming',
      statusClass: 'border-[#D0D5DD] text-[#D0D5DD]',
      iconSrc: `${process.env.PUBLIC_URL}/assets/images/tender-pending.svg`,
    },
    {
      title: 'First Inspection Report',
      deadline: 'N/A',
      submissionStatus: 'N/A',
      statusLabel: 'Upcoming',
      statusClass: 'border-[#D0D5DD] text-[#D0D5DD]',
      iconSrc: `${process.env.PUBLIC_URL}/assets/images/tender-pending.svg`,
    },
    {
      title: 'Mid-Project Review',
      deadline: 'N/A',
      submissionStatus: 'N/A',
      statusLabel: 'Upcoming',
      statusClass: 'border-[#D0D5DD] text-[#D0D5DD]',
      iconSrc: `${process.env.PUBLIC_URL}/assets/images/tender-pending.svg`,
    },
    {
      title: 'Final Project Review',
      deadline: 'N/A',
      submissionStatus: 'N/A',
      statusLabel: 'Upcoming',
      statusClass: 'border-[#D0D5DD] text-[#D0D5DD]',
      iconSrc: `${process.env.PUBLIC_URL}/assets/images/tender-pending.svg`,
    },
  ];

  return (
    <>
      <div className="mt-[33px] flex gap-x-6">
        <div>
          <div className="relative left-[50%] top-0 h-full w-[3px] -translate-x-1/2 bg-[#E5E5E5]">
            <div className="absolute top-0 h-[25%] w-full bg-[#42BE65]"></div>
            <div className="absolute top-[25%] h-[25%] w-full bg-[#42BE65]"></div>
          </div>
        </div>

        <div className="flex w-full flex-col gap-y-4">
          {deliverables.map((deliverable, index) => (
            <DeliverableItem key={index} {...deliverable} />
          ))}
        </div>
      </div>
    </>
  );
};

export default DeliverablesComponent;

const baseUrl = process.env.REACT_APP_BASE_URL;
export const Endpoint = {
  account: {
    login: `${baseUrl}/account/login`,
    register: `${baseUrl}/account/register`,
    confirmEmail: `${baseUrl}/account/confirmEmail`,
    resendOtp: `${baseUrl}/account/resend/otp`,
    googleAuth: `${baseUrl}/account/google`,
    externalOAuth: `${baseUrl}/account/externallogin?provider=`,
    refreshToken: `${baseUrl}/account/token/refresh`,
    forgotPassword: `${baseUrl}/account/forgotPassword`,
    resetPassword: `${baseUrl}/account/resetPassword`,
    changePassword: `${baseUrl}/account/changePassword`,
    profile: `${baseUrl}/account/profile`,
    accessibleModules: `${baseUrl}/account/accessible/module`,
    role: {
      create: `${baseUrl}/account/role/create`,
      get: `${baseUrl}/account/roles`,
      getOne: (roleId: string) => `${baseUrl}/account/role/${roleId}`,
      update: `${baseUrl}/account/role/edit`,
      delete: `${baseUrl}/account/role/delete`,
    },

    user: {
      get: `${baseUrl}/account/employee/list`,
      getStaff: `${baseUrl}/account/ads/users`,
      create: `${baseUrl}/account/employee/create`,
      update: `${baseUrl}/account/employee/edit`,
      delete: `${baseUrl}/account/employee/remove`,
      getOne: (userId: string) => `${baseUrl}/account/employee/${userId}`,
    },
    menu: {
      create: `${baseUrl}/account/menu/create`,
      getAll: `${baseUrl}/account/menu/all`,
      update: `${baseUrl}/account/menu/edit`,
      delete: `${baseUrl}/account/menu/delete`,
      get: (menuId: string) => `${baseUrl}/account/menu/${menuId}`,
      mymenu: `${baseUrl}/account/menu/mymenu`,
    },
    permission: {
      get: `${baseUrl}/account/permissions`,
    },
    lookup: {
      getType: (lookupType: string) => `${baseUrl}/lookup/${lookupType}`,
      create: `${baseUrl}/lookup/create`,
      update: `${baseUrl}/lookup/edit`,
      delete: `${baseUrl}/lookup/remove`,
    },
  },
  suppliers: {
    getAll: `${baseUrl}/supplier/list`,
    getSubsidiaries: `${baseUrl}/supplier/subsidiaries`,
    getOne: (id: string) => `${baseUrl}/supplier/suppliers?supplierId=${id}`,
    create: `${baseUrl}/supplier/create`,
    update: `${baseUrl}/supplier/edit`,
    delete: `${baseUrl}/supplier/remove`,
    setStatus: `${baseUrl}/supplier/setstatus`,
    checkSetup: `${baseUrl}/supplier/checkSetup`,
    progress: (supplierId: string) => `${baseUrl}/supplier/progress?supplierId=${supplierId}`,
    history: (supplierId: string) => `${baseUrl}/supplier/history?supplierId=${supplierId}`,
    contact: {
      create: `${baseUrl}/supplier/contact/create`,
      update: `${baseUrl}/supplier/contact/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/contacts/${supplierId}`,
      delete: `${baseUrl}/supplier/contact/remove`,
    },
    personnel: {
      create: `${baseUrl}/supplier/personnel/create`,
      update: `${baseUrl}/supplier/personnel/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/personnel/personnels/${supplierId}`,
      delete: `${baseUrl}/supplier/personnel/remove`,
    },
    industry: {
      create: `${baseUrl}/supplier/industry/create`,
      update: `${baseUrl}/supplier/industry/edit`,
      get: `${baseUrl}/supplier/industries`,
      delete: `${baseUrl}/supplier/industry/delete`,
    },
    shareholder: {
      create: `${baseUrl}/supplier/shareholder/create`,
      update: `${baseUrl}/supplier/shareholder/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/shareholders/${supplierId}`,
      delete: `${baseUrl}/supplier/shareholder/remove`,
    },
    project: {
      create: `${baseUrl}/supplier/project/create`,
      update: `${baseUrl}/supplier/project/edit`,
      get: `${baseUrl}/supplier/projects`,
      delete: `${baseUrl}/supplier/project/delete`,
    },
    award: {
      create: `${baseUrl}/supplier/award/create`,
      update: `${baseUrl}/supplier/award/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/awards/${supplierId}`,
      delete: `${baseUrl}/supplier/award/delete`,
    },
    additionalInfo: {
      create: `${baseUrl}/supplier/additional/create`,
      update: `${baseUrl}/supplier/additional/edit`,
      get: (supplierId: string) => `${baseUrl}/supplier/additional/${supplierId}`,
      delete: `${baseUrl}/supplier/additional/delete`,
    },
    tags: {
      create: `${baseUrl}/supplier/tag/create`,
      update: `${baseUrl}/supplier/tag/edit`,
      get: (supplierId: string) => `${baseUrl}/goods/supplier/tags/${supplierId}`,
      delete: `${baseUrl}/supplier/tag/delete`,
    },
  },
  workflow: {
    get: `${baseUrl}/workflow/list`,
    create: `${baseUrl}/workflow/create`,
    update: `${baseUrl}/workflow/edit`,
    delete: `${baseUrl}/workflow/delete`,
    getTypes: `${baseUrl}/workflow/types`,
    initiate: `${baseUrl}/workflow/initiate`,
    processTask: `${baseUrl}/workflow/processTask`,
    command: {
      create: `${baseUrl}/workflow/command/create`,
      update: `${baseUrl}/workflow/command/edit`,
      get: `${baseUrl}/workflow/commands`,
      delete: `${baseUrl}/workflow/command/remove`,
    },
    assignedTask: {
      get: `${baseUrl}/workflow/assignedTask`,
    },
    taskHistory: {
      getOne: (requestId: string) => `${baseUrl}/workflow/history/${requestId}`,
    },
    process: {
      create: `${baseUrl}/workflow/process/create`,
      update: `${baseUrl}/workflow/process/edit`,
      get: `${baseUrl}/workflow/process/list`,
      delete: `${baseUrl}/workflow/process/remove`,
      getOne: (id: string) => `${baseUrl}/workflow/process/${id}`,
    },
    parameter: {
      create: `${baseUrl}/workflow/parameter/create`,
      update: `${baseUrl}/workflow/parameter/edit`,
      get: `${baseUrl}/workflow/parameter/list`,
      delete: `${baseUrl}/workflow/parameter/remove`,
      getTypes: (id: number) => `${baseUrl}/workflow/workflowParameters/${id}`,
    },
    step: {
      create: `${baseUrl}/workflow/step/create`,
      update: `${baseUrl}/workflow/step/edit`,
      get: `${baseUrl}/workflow/steps`,
      delete: `${baseUrl}/workflow/steps/remove`,
      getOne: (id: string) => `${baseUrl}/workflow/step/${id}`,
    },
  },
  procurement: {
    create: `${baseUrl}/procurement/create`,
    edit: `${baseUrl}/procurement/edit`,
    getAllProcurements: `${baseUrl}/procurement/listServiceInformation`,
    getProcurement: `${baseUrl}/procurement/getAServiceInformation`,
    deleteProcurement: `${baseUrl}/procurement/deleteServiceInfo`,
    getSupplierTenders: `${baseUrl}/procurement/supplier/tenders`,
    getSupplierBids: `${baseUrl}/procurement/supplier/bids`,
    requestAccess: `${baseUrl}/procurement/requestForAccess`,
    allowAccess: `${baseUrl}/procurement/allow/access`,
    requestProposal: `${baseUrl}/procurement/requestForProposal`,
    allowProposal: `${baseUrl}/procurement/allow/proposal`,
    createGoodsAndServices: `${baseUrl}/procurement/TenderGoodsService/create`,
    editGoodsAndServices: `${baseUrl}/procurement/TenderGoodsService/edit`,
    deleteGoodsAndServices: `${baseUrl}/procurement/TenderGoodsServices`,
    listGoodsAndServices: `${baseUrl}/procurement/listTenderGoodsServices`,
    createTenderDocument: `${baseUrl}/procurement/TenderDocument/create`,
    editTenderDocument: `${baseUrl}/procurement/TenderDocument/edit`,
    deleteTenderDocument: `${baseUrl}/procurement/deleteTenderDocument`,
    listTenderDocuments: `${baseUrl}/procurement/listTenderDocument`,
    updateTenderStatus: `${baseUrl}/procurement/setTenderStatus`,
    getProcurementProposals: `${baseUrl}/procurement/proposals`,
    getProcurementProposalById: `${baseUrl}/procurement/proposal/detail`,
    createContractOffer: `${baseUrl}/procurement/contracts/create`,
    getIndustries: `${baseUrl}/supplier/industries`,
    getSupplierTypes: `${baseUrl}/lookup/SupplierType`,
    getBusinessTypes: `${baseUrl}/lookup/BusinessType`,
    getSuppliersTags: `${baseUrl}/supplier/tags`,
    unpublishProcurement: `${baseUrl}/procurementProjects/unpublish`,
    initiateWorkFlow: `${baseUrl}/workflow/initiate`,
    initiateWorkFlowApproval: `${baseUrl}/workflow/processTask`,
    bid: {
      createExpression: `${baseUrl}/procurement/bids/expression/create`,
      createTechnicalInfo: `${baseUrl}/procurement/bids/technical/create`,
      createCommercialsInfo: `${baseUrl}/procurement/bids/financial/create`,
      getTenderBidsDetails: `${baseUrl}/procurement/bids/detail`,
      getProcurementBidApplicants: `${baseUrl}/procurement/bids/applicants`,
      getExpressionBid: `${baseUrl}/procurement/bids/expression`,
      getTechnicalInfoBid: `${baseUrl}/procurement/bids/technical`,
      getCommercialsBid: `${baseUrl}/procurement/bids/financial`,
      getDocumentTypes: `${baseUrl}/procurement/bids/document/type`,
    },
  },
  goods: {
    list: `${baseUrl}/goods/list`,
    getProduct: (productId: string) => `${baseUrl}/goods/product/${productId}`,
    create: `${baseUrl}/goods/create`,
    update: `${baseUrl}/goods/edit`,
    delete: `${baseUrl}/goods/remove`,
    setStatus: `${baseUrl}/goods/setGoodsServices`,
    category: {
      create: `${baseUrl}/goods/category/create`,
      update: `${baseUrl}/goods/category/edit`,
      delete: `${baseUrl}/goods/category/delete`,
      getAll: `${baseUrl}/goods/category/categories`,
    },
    cart: {
      add: `${baseUrl}/goods/cart/add`,
      list: (customerId: string, pageNumber: number, pageSize: number) =>
        `${baseUrl}/goods/cart/list?customerId=${customerId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      remove: `${baseUrl}/goods/cart/remove`,
    },
    review: {
      create: `${baseUrl}/goods/review/create`,
      update: `${baseUrl}/goods/review/edit`,
      get: (productId: string) =>
        `${baseUrl}/goods/review/getProductReviews?productId=${productId}`,
      delete: `${baseUrl}/goods/review/delete`,
      getSupplierReviews: (supplierId: string) =>
        `${baseUrl}/goods/review/getSupplierReview?supplierId=${supplierId}`,
      getSupplierRating: (supplierId: string) =>
        `${baseUrl}/goods/review/getSupplierRating?supplierId=${supplierId}`,
    },
  },
  edms: {
    imageUpload: `${baseUrl}/edms/imageUpload`,
    uploadDocument: `${baseUrl}/edms/uploadDocument`,
    delete: (id: string) => `${baseUrl}/edms/image/${id}`,
    get: `${baseUrl}/edms/list`,
    getOne: (id: string) => `${baseUrl}/edms/${id}`,
    getDocuments: `${baseUrl}/edms/documents`,
    getSupplierDocuments: `${baseUrl}/edms/supplier/documents`,
    deleteDocument: `${baseUrl}/edms/document`,
    view: `${baseUrl}/edms/document/view`,
  },
  lookup: {
    get: `${baseUrl}/lookup/Lookup`,
    getType: (typeId: string) => `${baseUrl}/lookup/${typeId}`,
    create: `${baseUrl}/lookup/create`,
    update: `${baseUrl}/lookup/edit`,
    delete: `${baseUrl}/lookup/remove`,
  },
  language: {
    supportedLanguages: `${baseUrl}/lang/supported-languages`,
    create: `${baseUrl}/lang/create`,
    update: `${baseUrl}/lang/edit`,
    delete: `${baseUrl}/lang/delete`,
    all: `${baseUrl}/lang/all-langs`,
    keys: `${baseUrl}/lang/keys`,
  },
  operator: {
    create: `${baseUrl}/operator/create`,
    update: `${baseUrl}/operator/edit`,
    delete: (operatorId: string) => `${baseUrl}/operator/${operatorId}`,
    get: `${baseUrl}/operator/list`,
    getOne: (operatorId: string) => `${baseUrl}/operator/${operatorId}`,
    getAOperator: (operatorId: string) =>
      `${baseUrl}/operator/Operator/GetAOperator?operatorId=${operatorId}`,
    createUser: `${baseUrl}/operator/createUser`,
    contact: {
      create: `${baseUrl}/operator/contact/create`,
      update: `${baseUrl}/operator/contact/edit`,
      get: `${baseUrl}/operator/contacts/list`,
      delete: (contactId: string) => `${baseUrl}/operator/contact/remove/${contactId}`,
      getOne: (contactId: string) => `${baseUrl}/operator/contact/${contactId}`,
    },
  },
  external: {
    dashboardMetrics: `${baseUrl}/dashboard/matrix`,
  },
};

import axiosInstance from '../axiosInstance';
import { Endpoint } from './endpoint/endpoint';
import { objToQueryParams } from '../utils/helper';

export const createLookup = async (data: any) => {
  const url = Endpoint.lookup.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateLookup = async (data: any) => {
  const url = Endpoint.lookup.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getLookups = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.lookup.get + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteLookup = async (lookupId: string) => {
  const url = Endpoint.lookup.delete;

  const body = { id: lookupId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getSupportedLanguages = async () => {
  const url = Endpoint.language.supportedLanguages;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getAllLangs = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.language.all + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getLangKeys = async () => {
  const url = Endpoint.language.keys;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createLang = async (data: any) => {
  const url = Endpoint.language.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateLang = async (data: any) => {
  const url = Endpoint.language.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteLang = async (langId: string) => {
  const url = Endpoint.language.delete;

  const body = { languageId: langId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getExternalDashboardMetrics = async () => {
  const url = Endpoint.external.dashboardMetrics;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteDocument, downloadDocument, uploadDocument } from '../../services/edmsService';
import { DocumentInterface } from '../../types/interfaces';
import Delete from '../Delete/Delete';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FileUpload from '../FileUpload/file-upload';

interface DocumentListProps {
  entityId: string | null;
  module: string;
  subModule?: string | null;
  documentTypeList: { key: string; value: string }[];
  documents: DocumentInterface[];
  fetchDocuments: () => void;
}

const ProcurementDocumentList: React.FC<DocumentListProps> = ({
  entityId,
  module,
  subModule = null,
  documentTypeList,
  documents,
  fetchDocuments,
}) => {
  const { t } = useTranslation();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentInterface | null>(null);

  const handleDeleteDocument = async () => {
    if (!selectedDocument) return;
    setIsDeleteModalOpen(false);
    const payload = {
      documentId: selectedDocument.documentId,
      module,
      ...(subModule && { subModule }),
    };
    const response = await deleteDocument(payload);
    if (response?.isSuccess) {
      toast.success(response?.data.message);
      fetchDocuments();
    }
  };

  const handleViewDocument = (doc: DocumentInterface) => async () => {
    const request = {
      documentId: doc.documentId,
      module,
      ...(subModule && { subModule }),
    };

    try {
      const response = await downloadDocument(request);
      if (response) {
        const fileExtension = doc.documentName.split('.').pop()?.toLowerCase();
        if (fileExtension === 'pdf') {
          // Open the PDF in a new tab
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');
          if (!newTab) {
            toast.error('Popup blocked. Please allow popups to view the document.');
          }
        } else {
          // Download the file
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', doc.documentName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        toast.error('Failed to view document');
      }
    } catch (error) {
      toast.error('An error occurred while viewing the document');
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <>
      <div className="mt-6 rounded-md border border-[#D0D5DD]">
        <div className="grid grid-cols-[7fr_1fr] items-center border-b border-[#E5E5E5] p-3 pr-6">
          <span className="text-sm text-[#8D8D8D]">Documents</span>
          <button
            className="rounded-lg border border-black bg-white px-3 py-1.5 font-medium text-[#101828]"
            onClick={() => {
              setIsAddModalOpen(true);
              setSelectedDocument(null);
            }}
            type="button"
          >
            + Add
          </button>
        </div>

        {documents?.map((item, index) => (
          <div
            key={item.documentId}
            className={`grid grid-cols-[7fr_1fr] items-center p-3 pr-6 ${documents?.length - 1 !== index ? 'border-b border-[#E5E5E5]' : ''}`}
          >
            <span className="text-sm text-[#252C32]">{item.documentName}</span>

            <div className="flex justify-end gap-x-3">
              <button
                onClick={handleViewDocument(item)}
                className="border-b border-[#42BE65] text-sm font-semibold text-[#42BE65]"
              >
                View
              </button>
              <button
                className="border-b border-error text-sm font-semibold text-error"
                onClick={() => {
                  setSelectedDocument(item);
                  setIsDeleteModalOpen(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure?')}
          desc={t('deleteConfirmation', 'This action is permanent, all data would be lost.')}
          onDelete={handleDeleteDocument}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}

      {/* Add Document Modal */}
      {isAddModalOpen && (
        <AddDocument
          onClose={() => {
            setIsAddModalOpen(false);
            setSelectedDocument(null);
          }}
          onAdd={() => {
            fetchDocuments();
            setIsAddModalOpen(false);
            setSelectedDocument(null);
          }}
          entityId={entityId}
          module={module}
          subModule={subModule}
          documentTypeList={documentTypeList}
        />
      )}
    </>
  );
};

export default ProcurementDocumentList;

interface AddDocumentProps {
  onClose: () => void;
  onAdd: () => void;
  initialValues?: DocumentInterface | null;
  //   isEditable: boolean;
  entityId: string | null;
  module: string;
  subModule: string | null;
  documentTypeList: { key: string; value: string }[];
}

const validationSchema = Yup.object({
  documentType: Yup.string().required('Document Type is required'),
  documents: Yup.mixed().required('Document is required'),
  //documents: Yup.array().min(1, 'At least one document is required').required('Documents are required'),
});

const AddDocument: React.FC<AddDocumentProps> = ({
  onClose,
  onAdd,
  initialValues,
  //   isEditable,
  entityId,
  module,
  subModule,
  documentTypeList,
}) => {
  const { t } = useTranslation();

  const formInitialValues = useMemo<any>(() => {
    if (initialValues) {
      return {
        ...initialValues,
      };
    }
    return {
      documentType: '',
      documents: {},
    };
  }, [initialValues]);

  const handleSubmit = async (values: any) => {
    console.log('values: ', values);

    if (!entityId) {
      toast.error(t('entityIdRequired', 'Entity ID is required'));
      return;
    }
    try {
      const payload = {
        entityId,
        module,
        ...(subModule && { subModule }),
        documentTypeName: values.documentType,
        file: values.documents,
      };

      const formData = new FormData();
      formData.append('entityId', payload.entityId);
      formData.append('module', payload.module);
      if (payload.subModule) {
        formData.append('subModule', payload.subModule);
      }
      formData.append('documentTypeName', payload.documentTypeName);
      formData.append('file', payload.file);

      const response = await uploadDocument(formData);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        onAdd();
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="w-full max-w-3xl rounded-2xl bg-white p-8 shadow-lg">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold">{t('addDocument', 'Add Document')}</h2>
          <button className="text-xl text-gray-500" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt={t('close', 'close')}
            />
          </button>
        </div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="grid grid-cols-2 gap-6">
                {/* Document Type */}
                <div className="col-span-2 mb-4">
                  <label
                    htmlFor="documentType"
                    className="mb-1 block text-sm font-medium text-[#344054]"
                  >
                    {t('documentType', 'Document Type')}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    name="documentType"
                    // disabled={!isEditable}
                    className="h-11 w-full rounded-lg border p-2 text-sm"
                  >
                    <option value="">{t('select', 'Select')}</option>
                    {documentTypeList.map((type) => (
                      <option key={type.key} value={type.value}>
                        {t(type.value, type.value)}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="documentType"
                    component="span"
                    className="text-sm text-red-500"
                  />
                </div>

                {/* Upload Documents */}
                <div className="col-span-2 mb-4">
                  <label className="mb-1 block text-sm font-medium text-[#344054]">
                    {t('uploadDocument', 'Upload Document')}
                    <span className="text-red-500">*</span>
                  </label>
                  <FileUpload
                    onFileSelect={(file: File | null) => setFieldValue('documents', file)}
                    showUpload={false}
                  />
                  <ErrorMessage
                    name="documents"
                    component="span"
                    className="text-sm text-red-500"
                  />
                </div>
              </div>

              <div className="divider my-6 !bg-[#E5E5E5]"></div>

              <div className="mt-4 flex justify-end">
                <button
                  type="submit"
                  className="h-12 cursor-pointer rounded-lg bg-primary px-12 py-2 font-semibold text-black"
                >
                  {initialValues ? t('update', 'Update') : t('save', 'Save')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

import React, {
  useRef,
  RefObject,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalContext } from '../../contexts/GlobalContext';
import {
  createSupplier,
  getIndustries,
  getSupplierById,
  updateSupplier,
} from '../../services/supplierService';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, MultiValue } from 'react-select';
import PhoneInput from 'react-phone-input-2';
import {
  parsePhoneNumberFromString,
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  CountryCode,
} from 'libphonenumber-js';
import { getLookupType } from '../../services/AccountServices';
import { OperatorCompanyInfo } from '../../types/interfaces';
import { createOperator, getOperatorById, updateOperator } from '../../services/OperatorServices';

interface OptionType {
  value: string;
  label: string;
}
interface FormComponentProps {
  isEditMode: boolean;
  affiliates: any[];
  onFormSubmitSuccess: () => void;
  onValuesChange: (values: OperatorCompanyInfo) => void;
}

const CompanyInformation = forwardRef<{ submitForm: () => void }, FormComponentProps>(
  ({ isEditMode, onFormSubmitSuccess, onValuesChange }, ref) => {
    const isEditable = true;
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { id } = useParams();
    const operatorId = id;

    const [selectedIndustries, setSelectedIndustries] = useState<OptionType[]>([]);
    const [industryOptions, setIndustryOptions] = useState<OptionType[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
    const [tagOptions, setTagOptions] = useState<OptionType[]>([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('US');
    const [operatorTypeList, setOperatorTypeList] = useState<{ key: string; value: string }[]>([]);
    const [businessTypeList, setBusinessTypeList] = useState<{ key: string; value: string }[]>([]);

    const formik = useFormik<OperatorCompanyInfo>({
      initialValues: {
        organizationName: '',
        dateOfEstablishment: '',
        businessType: '',
        country: '',
        regNumber: '',
        addressLine1: '',
        addressLine2: '',
        region: '',
        city: '',
        town: '',
        phoneNumber: '',
        website: '',
        email: '',
        domain: '',
        operatorType: '',
      },
      validationSchema: Yup.object({
        organizationName: Yup.string()
          .required(t('required', 'Required'))
          .min(5, t('minLength', 'Must be at least 5 characters')),
        dateOfEstablishment: Yup.string().required(t('required', 'Required')),
        businessType: Yup.string().required(t('required', 'Required')),
        country: Yup.string().required(t('required', 'Required')),
        regNumber: Yup.string().required(t('required', 'Required')),
        addressLine1: Yup.string().required(t('required', 'Required')),
        // addressLine2: Yup.string(),
        region: Yup.string().required(t('required', 'Required')),
        city: Yup.string().required(t('required', 'Required')),
        town: Yup.string().required(t('required', 'Required')),

        website: Yup.string()
          .required(t('required', 'Required'))
          .matches(
            /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/,
            t('invalidWebsite', 'Invalid website')
          ),
        // email: Yup.string()
        //   .email(t('invalidEmail', 'Invalid email'))
        //   .required(t('required', 'Required')),
        domain: Yup.string().required(t('required', 'Required')),
        operatorType: Yup.string().required(t('required', 'Required')),
      }),
      onSubmit: (values: OperatorCompanyInfo) => {
        try {
          const payload: any = {
            organizationName: values.organizationName,
            dateOfEstablishment: values.dateOfEstablishment
              ? new Date(values.dateOfEstablishment).toISOString()
              : new Date().toISOString(),
            businessType: values.businessType,
            country: values.country,
            regNumber: values.regNumber,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            region: values.region,
            city: values.city,
            town: values.town,
            phoneNumber: values.phoneNumber,
            website: values.website,
            email: 'test2@yopmail.com',
            // email: values.email,
            domain: values.domain,
            operatorType: values.operatorType,
          };

          if (isEditMode) {
            payload.organizationId = operatorId;
            handleUpdate(payload);
          } else {
            handleSubmit(payload);
          }
        } catch {
          // toast.error(t('errorCreatingSupplier', 'Error creating supplier'));
        } finally {
          // setIsLoading(false);
        }
      },
    });

    const handleSubmit = async (formData: any) => {
      const response = await createOperator(formData);
      if (response) {
        const newParamValue = response?.id;
        const updatedParams = new URLSearchParams(searchParams);
        updatedParams.set('id', newParamValue);
        navigate(`${response.id}`, { replace: true });
        toast.success(response?.message);
        onFormSubmitSuccess(); // Notify parent component on successful form submission
        getOperatorInformation();
      } else {
        toast.error(t('errorCreatingOperator', 'Error creating operator'));
      }
    };

    const handleUpdate = async (formData: any) => {
      const response = await updateOperator(formData);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        onFormSubmitSuccess(); // Notify parent component on successful form submission
        getOperatorInformation();
      } else {
        toast.error(t('errorUpdatingOperator', 'Error updating operator'));
      }
    };

    const handleIndustriesChange = (
      newValue: MultiValue<OptionType>,
      actionMeta: ActionMeta<OptionType>
    ) => {
      const selectedOptions = newValue as OptionType[];
      setSelectedIndustries(selectedOptions);
      formik.setFieldValue('industries', selectedOptions);
    };

    const handleTagChange = (
      newValue: MultiValue<OptionType>,
      actionMeta: ActionMeta<OptionType>
    ) => {
      const selectedOptions = newValue as OptionType[];
      setSelectedTags(selectedOptions);
      formik.setFieldValue('tags', selectedOptions);
    };

    // Use useImperativeHandle to expose the submitForm function to the parent component
    useImperativeHandle(ref, () => ({
      submitForm: () => {
        formik.submitForm();
      },
    }));

    const getOperatorInformation = async () => {
      try {
        if (!operatorId) return;
        const response = await getOperatorById(operatorId);
        if (response?.isSuccess) {
          const supplierData = response.data;
          formik.setValues({
            organizationName: supplierData.organizationName,
            dateOfEstablishment: supplierData.dateOfEstablishment
              ? new Date(supplierData.dateOfEstablishment).toISOString().split('T')[0]
              : '',
            businessType: supplierData.businessType ? supplierData.businessType : '',
            country: supplierData.country ? supplierData.country : '',
            regNumber: supplierData.registrationNumber,
            addressLine1: supplierData.addressLine1,
            addressLine2: supplierData.addressLine2,
            region: supplierData.region,
            city: supplierData.city,
            town: supplierData.town,
            phoneNumber: supplierData.phoneNumber,
            website: supplierData.website,
            email: supplierData.email,
            domain: supplierData.domain,
            operatorType: supplierData.operatorType,
          });

          const parsedNumber = parsePhoneNumberFromString(`+${supplierData.phoneNumber}`);
          setSelectedCountryCode(parsedNumber?.country || 'US');
          onValuesChange(supplierData);
        }
      } catch (error) {
        toast.error(t('errorGettingSupplierInformation', 'Error getting supplier information'));
      }
    };

    useEffect(() => {
      const getOperatorTypes = async () => {
        const response = await getLookupType('operator-admin');
        if (response?.isSuccess) {
          setOperatorTypeList(
            response.data.map((lookup: any) => ({
              key: lookup.lookupName,
              value: lookup.lookupName,
            }))
          );
        }
      };

      const getBusinessTypes = async () => {
        const response = await getLookupType('BusinessType');
        if (response?.isSuccess) {
          setBusinessTypeList(
            response.data.map((lookup: any) => ({
              key: lookup.lookupName,
              value: lookup.lookupName,
            }))
          );
        }
      };

      getOperatorTypes();
      getBusinessTypes();
      getOperatorInformation();
    }, []);

    return (
      <>
        <div className="bg-white px-8 py-6 shadow-sm">
          <h1 className="m-6 ml-0 flex justify-start text-center text-xl font-medium">
            {t('fillOutCompanyInformation', 'Fill out your company’s information')}
          </h1>
          <div className="divider my-10 mb-4"></div>

          <form onSubmit={(e) => e.preventDefault()} className="flex flex-col gap-7">
            <div className="flex gap-4"></div>

            <div className="flex gap-4">
              <div className="form-group w-[33%]">
                <label
                  className="font-500 text-base font-medium text-gray-500"
                  htmlFor="operatorType"
                >
                  {t('operatorType', 'Operator Type')}
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <select
                  id="operatorType"
                  name="operatorType"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.operatorType}
                  className="h-[3.5rem] w-full rounded-md border border-[#cccccc] text-base focus:border-gray-700 focus:outline-none"
                >
                  <option value="">{t('select', 'Select')}</option>
                  {operatorTypeList.map((type) => (
                    <option key={type.key} value={type.value}>
                      {t(type.value, type.value)}
                    </option>
                  ))}
                </select>
                {formik.touched.operatorType && formik.errors.operatorType && (
                  <span className="error text-red-500">{formik.errors.operatorType}</span>
                )}
              </div>
              <div className="form-group w-[33%]">
                <label
                  className="font-500 text-base font-medium text-gray-500"
                  htmlFor="organizationName"
                >
                  {t('businessName', 'Business Name')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="organizationName"
                  name="organizationName"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.organizationName}
                />
                {formik.touched.organizationName && formik.errors.organizationName && (
                  <span className="error text-red-500">{formik.errors.organizationName}</span>
                )}
              </div>
              <div className="form-group w-[33%]">
                <label className="font-500 text-base font-medium text-gray-500" htmlFor="regNumber">
                  {t('commercialRegNo', 'Commercial Reg. No')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="regNumber"
                  name="regNumber"
                  onChange={formik.handleChange}
                  disabled={!isEditable}
                  value={formik.values.regNumber}
                />
                {formik.touched.regNumber && formik.errors.regNumber && (
                  <span className="error text-red-500">{formik.errors.regNumber}</span>
                )}
              </div>{' '}
            </div>

            <div className="flex gap-4">
              <div className="form-group w-[33%]">
                <label
                  className="font-500 text-base font-medium text-gray-500"
                  htmlFor="businessType"
                >
                  {t('businessType', 'Business Type')}
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <select
                  id="businessType"
                  name="businessType"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.businessType}
                  className="h-[3.5rem] w-full rounded-md border border-[#cccccc] text-base focus:border-gray-700 focus:outline-none"
                >
                  <option value="">{t('select', 'Select')}</option>
                  {businessTypeList.map((type) => (
                    <option key={type.key} value={type.value}>
                      {t(type.value, type.value)}
                    </option>
                  ))}
                </select>
                {formik.touched.businessType && formik.errors.businessType && (
                  <span className="error text-red-500">{formik.errors.businessType}</span>
                )}
              </div>
              <div className="form-group w-[33%]">
                <label
                  className="font-500 text-base font-medium text-gray-500"
                  htmlFor="dateOfEstablishment"
                >
                  {t('dateOfEstablishment', 'Date of Establishment')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  className="h-[3.5rem]"
                  id="dateOfEstablishment"
                  name="dateOfEstablishment"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.dateOfEstablishment}
                  max={new Date().toISOString().split('T')[0]} // Disable future dates
                />
                {formik.touched.dateOfEstablishment && formik.errors.dateOfEstablishment && (
                  <span className="error text-red-500">{formik.errors.dateOfEstablishment}</span>
                )}
              </div>
              <div className="form-group w-[33%]">
                <label className="font-500 text-base font-medium text-gray-500" htmlFor="country">
                  {t('country', 'Country')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="country"
                  name="country"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.country}
                />
                {formik.touched.country && formik.errors.country && (
                  <span className="error text-red-500">{formik.errors.country}</span>
                )}
              </div>
            </div>

            <div className="divider my-10 mb-4"></div>
            <div>
              {/* Office Address */}
              <div className="flex gap-4">
                <div className="form-group w-[50%]">
                  <label
                    className="font-500 text-base font-medium text-gray-500"
                    htmlFor="addressLine1"
                  >
                    {t('officeAddressLine1', 'Office Address Line 1')}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="h-[3.5rem]"
                    id="addressLine1"
                    name="addressLine1"
                    disabled={!isEditable}
                    onChange={formik.handleChange}
                    value={formik.values.addressLine1}
                  />
                  {formik.touched.addressLine1 && formik.errors.addressLine1 && (
                    <span className="error text-red-500">{formik.errors.addressLine1}</span>
                  )}
                </div>

                <div className="form-group w-[50%]">
                  <label
                    className="font-500 text-base font-medium text-gray-500"
                    htmlFor="addressLine2"
                  >
                    {t('officeAddressLine2', 'Office Address Line 2')}
                  </label>
                  <input
                    type="text"
                    className="h-[3.5rem]"
                    id="addressLine2"
                    disabled={!isEditable}
                    name="addressLine2"
                    onChange={formik.handleChange}
                    value={formik.values.addressLine2}
                  />
                </div>
              </div>
            </div>

            {/* Municipality and City */}
            <div className="flex gap-4">
              <div className="form-group w-[33%]">
                <label className="font-500 text-base font-medium text-gray-500" htmlFor="region">
                  {t('municipalityRegion', 'Municipality/Region')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="region"
                  disabled={!isEditable}
                  name="region"
                  onChange={formik.handleChange}
                  value={formik.values.region}
                />
                {formik.touched.region && formik.errors.region && (
                  <span className="error text-red-500">{formik.errors.region}</span>
                )}
              </div>

              <div className="form-group w-[33%]">
                <label className="font-500 text-base font-medium text-gray-500" htmlFor="city">
                  {t('postAdministrativeCity', 'Post Administrative/City')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="city"
                  name="city"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city && (
                  <span className="error text-red-500">{formik.errors.city}</span>
                )}
              </div>
              <div className="form-group w-[33%]">
                <label className="font-500 text-base font-medium text-gray-500" htmlFor="town">
                  {t('suburbOrTown', 'Suburb or Town')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="town"
                  name="town"
                  onChange={formik.handleChange}
                  disabled={!isEditable}
                  value={formik.values.town}
                />
                {formik.touched.town && formik.errors.town && (
                  <span className="error text-red-500">{formik.errors.town}</span>
                )}
              </div>
            </div>
            <div className="flex gap-4">
              <div className="form-group w-[33%]">
                <label
                  className="font-500 text-base font-medium text-gray-500"
                  htmlFor="phoneNumber"
                >
                  {t('phone_Number', 'Phone Number')}
                  <span className="text-red-500">*</span>
                </label>
                <PhoneInput
                  containerStyle={{ width: '100%' }}
                  inputStyle={{ width: '100%', height: '3.5rem' }}
                  country={'us'}
                  value={formik.values.phoneNumber}
                  onChange={(value, country: any) => {
                    formik.setFieldValue('phoneNumber', value);
                    setSelectedCountryCode(country.countryCode.toUpperCase());
                  }}
                  disabled={!isEditable}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <span className="error text-red-500">{formik.errors.phoneNumber}</span>
                )}
              </div>

              <div className="form-group w-[33%]">
                <label className="font-500 text-base font-medium text-gray-500" htmlFor="domain">
                  {t('Company Email Domain', 'Company Email Domain')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="domain"
                  name="domain"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.domain}
                />
                {formik.touched.domain && formik.errors.domain && (
                  <span className="error text-red-500">{formik.errors.domain}</span>
                )}
              </div>
              <div className="form-group w-[33%]">
                <label className="font-500 text-base font-medium text-gray-500" htmlFor="website">
                  {t('website', 'Website')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="website"
                  name="website"
                  placeholder="https://www.example.com"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.website}
                />
                {formik.touched.website && formik.errors.website && (
                  <span className="error text-red-500">{formik.errors.website}</span>
                )}
              </div>
            </div>

            {/* Submit Button */}
            {/* <div className="button-container">
            {isEditable ? (
              <AppButton
                title={t('submit', 'Submit')}
                className="text-lg font-semibold bg-[#FFC726] rounded-lg w-[92px] h-[36px]"
                onClick={() => formik.submitForm()} // Submits the form when editable
              />
            ) : null}
          </div> */}
          </form>
        </div>
      </>
    );
  }
);

export default CompanyInformation;

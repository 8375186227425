import { Link } from 'react-router-dom';

const OverviewComponent = () => {
  return (
    <>
      <div className="mb-[35px] mt-4 grid items-center gap-y-6 border-y border-[#EDE8E7] pb-6 pt-4 xl:grid-cols-[1fr_1fr]">
        <div className="flex flex-col gap-y-2">
          <span className="text-sm text-[#525252]">Contract Start date:</span>
          <span className="text-base font-medium text-[#161616]">28/08/2024</span>
        </div>

        <div className="grid grid-cols-[1.5fr_1fr]">
          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#525252]">Contract End date:</span>
            <span className="text-base font-medium text-[#161616]">31/10/2024</span>
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#525252]">Current status:</span>
            <span className="text-sm font-medium text-[#24A148]">Active</span>
          </div>
        </div>
      </div>

      <div className="flex w-3/5 flex-col gap-y-12">
        <div className="flex flex-col gap-y-2">
          <h3 className="text-xl font-semibold text-[#101828]">Offer Description</h3>
          <p className="text-sm text-[#101828]">
            Green Earth Consultants Ltd is pleased to submit this proposal for the Pipeline
            Inspection and Maintenance project. This proposal outlines the scope, budget, and
            timeline for ensuring pipeline integrity and safety.
          </p>

          <Link
            to=""
            className="text-sm font-semibold text-[#24A148] underline"
            target="_blank"
            rel="noreferrer noopener"
          >
            View original job post
          </Link>
        </div>

        <div className="flex flex-col gap-y-2">
          <h3 className="text-xl font-semibold text-[#101828]">Attachment</h3>
          <div className="flex items-center gap-x-6">
            <div className="w-full rounded-[5px] bg-[#F5F5F5] py-2.5">
              Pipeline Inspection and Maintenance project.pdf
            </div>

            <button className="px-3 py-1.5 font-semibold text-[#24A148] underline">Download</button>
          </div>
        </div>

        <div>
          <h3 className="mb-6 text-xl font-semibold text-[#101828]">Deliverables</h3>
          <div>
            <div className="mb-4 grid grid-cols-[1fr_1fr_.5fr] border-b border-[#E5E5E5] pb-4 text-sm font-medium text-[#525252]">
              <span>Milestone</span>
              <span>Due date</span>
              <span>Amount</span>
            </div>

            <div className="mb-4 grid grid-cols-[1fr_1fr_.5fr] border-b border-[#E5E5E5] pb-4 text-sm font-medium text-[#101828]">
              <span>First review</span>
              <span>Nov 10, 2024</span>
              <span>$1,200</span>
            </div>

            <div className="mb-4 grid grid-cols-[1fr_1fr_.5fr] border-b border-[#E5E5E5] pb-4 text-sm font-medium text-[#101828]">
              <span>Second review</span>
              <span>Nov 10, 2024</span>
              <span>$1,200</span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="mb-6 text-xl font-semibold text-[#101828]">Payment Terms</h3>

          <div>
            <div className="mb-4 grid grid-cols-[2fr_.5fr] border-b border-[#E5E5E5] pb-4">
              <div>
                <span className="text-base font-semibold text-[#101828]">Offer</span>
                <p className="text-sm font-medium text-[#525252]">
                  Total amount for this opportunity
                </p>
              </div>

              <span>$62,000</span>
            </div>
          </div>

          <div>
            <div className="mb-4 grid grid-cols-[2fr_.5fr] border-b border-[#E5E5E5] pb-4">
              <span className="text-base font-semibold text-[#101828]">
                10% ANP Withholding Tax
              </span>

              <span>$62,000</span>
            </div>
          </div>

          <div>
            <div className="mb-4 grid grid-cols-[2fr_.5fr] border-b border-[#E5E5E5] pb-4">
              <span className="text-base font-semibold text-[#101828]">
                7% ANP ESSC Portal Service Fee (VAT)
              </span>

              <span>$4, 340</span>
            </div>
          </div>

          <div>
            <div className="mb-4 grid grid-cols-[2fr_.5fr] border-b border-[#E5E5E5] pb-4">
              <div>
                <span className="text-base font-semibold text-[#101828]">You will get</span>
                <p className="mt-5 max-w-[326px] text-sm font-medium text-[#525252]">
                  The estimated amount you will receive after service fees.
                </p>
              </div>

              <span>$51,460</span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="mb-6 text-xl font-semibold text-[#101828]">Contract details</h3>

          <div className="mb-4 grid grid-cols-[1.5fr_2fr] border-b border-[#E5E5E5] pb-4 text-sm font-medium">
            <span className="text-[#525252]">Total Amount</span>
            <span className="text-[#101828]">$62,000</span>
          </div>

          <div className="mb-4 grid grid-cols-[1.5fr_2fr] border-b border-[#E5E5E5] pb-4 text-sm font-medium">
            <span className="text-[#525252]">Category</span>
            <span className="text-[#101828]">Oil and gas</span>
          </div>

          <div className="mb-4 grid grid-cols-[1.5fr_2fr] border-b border-[#E5E5E5] pb-4 text-sm font-medium">
            <span className="text-[#525252]">Offer date</span>
            <span className="text-[#101828]">Oct 10, 2024</span>
          </div>

          <div className="mb-4 grid grid-cols-[1.5fr_2fr] border-b border-[#E5E5E5] pb-4 text-sm font-medium">
            <span className="text-[#525252]">Offer expires</span>
            <span className="text-[#101828]">Dec 10, 2024</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewComponent;

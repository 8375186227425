import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getTenderBidDetails } from '../../services/procurementService';
import moment from 'moment';

const imageLookup = {
  InProgress: 'tender-inprogress.svg',
  Completed: 'tender-completed.svg',
  Rejected: 'tender-rejected.svg',
};

const ProcurementBidTimelinePage = () => {
  const navigate = useNavigate();
  const { tenderBidId } = useParams<{ tenderBidId: string }>();

  const { data: tenderBidDetails = [] } = useQuery({
    queryKey: ['bids-applicants', tenderBidId],
    queryFn: async () => {
      const response = await getTenderBidDetails(tenderBidId as string);
      return response.data || [];
    },
    enabled: !!tenderBidId,
    refetchOnWindowFocus: false,
  });

  const isExpressionOfInterestCompleted = tenderBidDetails[0]?.stageStatus === 'Completed';
  const isTechnicalEvaluationCompleted = tenderBidDetails[1]?.stageStatus === 'Completed';
  const isCommercialsCompleted = tenderBidDetails[2]?.stageStatus === 'Completed';

  const handleContinueApplication = () => {
    if (!isExpressionOfInterestCompleted) {
      navigate(`/procurement/bids/${tenderBidId}/expressionOfInterest`);
    } else if (!isTechnicalEvaluationCompleted) {
      navigate(`/procurement/bids/${tenderBidId}/technicalInformation`);
    } else {
      navigate(`/procurement/bids/${tenderBidId}/commercialInformation`);
    }
  };

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Electronic Procurement</h1>

      <div className="2xl:w-11/12">
        <div className="mb-2.5 mt-8 flex flex-col justify-between gap-x-7 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12 xl:flex-row xl:items-center">
          <div className="grid w-full grid-cols-2 gap-3 xl:grid-cols-4">
            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Contract Name</span>
              <span className="text-sm font-medium text-[#525252]">
                Pipeline Inspection and Maintenance
              </span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Type</span>
              <span className="text-sm font-medium text-[#525252]">Joint Venture</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Date</span>
              <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Status</span>
              <span className="text-sm font-medium text-[#24A148]">Active</span>
            </div>
          </div>

          <button className="ml-auto flex h-12 w-[140px] items-center justify-center rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
            View Details
          </button>
        </div>

        <div className="rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          <div className="grid items-center gap-y-6 xl:grid-cols-[1fr_1.5fr]">
            <div className="flex items-center gap-x-6">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
                alt="vendor icon"
                className="h-[58px] w-[58px] rounded-full"
              />

              <div className="flex flex-col items-start gap-y-2">
                <span className="text-sm text-[#8D8D8D]">Contractor Name:</span>
                <span className="font-semibold text-[#101828] underline">
                  Green Earth Consultants Ltd
                </span>

                <span className="rounded-[30px] bg-[#42BE65] px-2.5 py-1 text-[10px] font-medium text-white">
                  Verified Operator
                </span>
              </div>
            </div>
          </div>

          <div className="mt-[33px] flex gap-x-6">
            <div>
              <div className="relative left-[50%] top-0 h-full w-[3px] -translate-x-1/2 bg-[#E5E5E5]">
                {tenderBidDetails[0]?.stageStatus === 'Completed' && (
                  <>
                    <div className="absolute top-0 h-[25%] w-full bg-[#42BE65]"></div>
                    <div className="absolute top-[25%] h-[25%] w-full bg-[#42BE65]"></div>
                  </>
                )}

                {tenderBidDetails[1]?.stageStatus === 'Completed' && (
                  <div className="absolute top-[50%] h-[25%] w-full bg-[#42BE65]"></div>
                )}

                {tenderBidDetails[2]?.stageStatus === 'Completed' && (
                  <div className="absolute top-[75%] h-[25%] w-full bg-[#42BE65]"></div>
                )}
              </div>
            </div>

            <div className="flex w-full flex-col gap-y-4">
              {/* Expression of Interest */}
              <div className="relative cursor-not-allowed rounded-2xl border border-[#E9EFF2] px-4 py-6 opacity-70">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="mb-1 text-base font-medium text-[#292D32]">
                      Expression of interest
                    </h4>

                    <div className="flex flex-col gap-x-4 gap-y-1 xl:flex-row">
                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#DA1E28]">Deadline:</span>{' '}
                        {moment(tenderBidDetails[0]?.deadLine).format('DD/MM/YYYY')}
                      </p>

                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#24A148]">Bid Submitted:</span>
                        {moment(tenderBidDetails[0]?.submissionDate).format('DD/MM/YYYY')}
                      </p>
                    </div>
                  </div>

                  {tenderBidDetails[0]?.stageStatus === 'InProgress' ? (
                    <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#128FFF] px-5 py-3 text-sm font-semibold text-[#128FFF]">
                      <span>In progress</span>
                    </div>
                  ) : tenderBidDetails[0]?.stageStatus === 'Completed' ? (
                    <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#42BE65] px-5 py-3 text-sm font-semibold text-[#42BE65]">
                      <span>Completed</span>
                    </div>
                  ) : (
                    <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#DA1E28] px-5 py-3 text-sm font-semibold text-[#DA1E28]">
                      <span>Rejected</span>
                    </div>
                  )}
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/${imageLookup[tenderBidDetails[0]?.stageStatus]}`}
                  alt="in-progress"
                  className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                />
              </div>

              {/* Technical Evaluation */}
              <button
                className={`relative rounded-2xl border border-[#E9EFF2] px-4 py-6 ${
                  !isExpressionOfInterestCompleted || isTechnicalEvaluationCompleted
                    ? 'cursor-not-allowed opacity-70'
                    : ''
                }`}
                onClick={() => {
                  if (isExpressionOfInterestCompleted) {
                    navigate(`/procurement/bids/${tenderBidId}/technicalInformation`);
                  }
                }}
                disabled={!isExpressionOfInterestCompleted || isTechnicalEvaluationCompleted}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="mb-1 text-base font-medium text-[#292D32]">
                      Technical Evaluation
                    </h4>

                    <div className="flex flex-col items-start gap-x-4 gap-y-1 xl:flex-row">
                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#DA1E28]">Deadline:</span>{' '}
                        {moment(tenderBidDetails[1]?.deadLine).format('DD/MM/YYYY')}
                      </p>

                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#24A148]">Bid Submitted:</span>
                        {tenderBidDetails[1]?.submissionDate
                          ? moment(tenderBidDetails[1]?.submissionDate).format('DD/MM/YYYY')
                          : 'N/A'}
                      </p>
                    </div>
                  </div>

                  {tenderBidDetails[1]?.stageStatus ? (
                    <>
                      {tenderBidDetails[1]?.stageStatus === 'InProgress' ? (
                        <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#128FFF] px-5 py-3 text-sm font-semibold text-[#128FFF]">
                          <span>In progress</span>
                        </div>
                      ) : tenderBidDetails[1]?.stageStatus === 'Completed' ? (
                        <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#42BE65] px-5 py-3 text-sm font-semibold text-[#42BE65]">
                          <span>Completed</span>
                        </div>
                      ) : (
                        <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#DA1E28] px-5 py-3 text-sm font-semibold text-[#DA1E28]">
                          <span>Rejected</span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#D0D5DD] px-5 py-3 text-sm font-semibold text-[#D0D5DD]">
                      <span>Upcoming</span>
                    </div>
                  )}
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/${tenderBidDetails[1]?.stageStatus ? imageLookup[tenderBidDetails[1]?.stageStatus] : 'tender-upcoming.svg'}`}
                  alt="Completed"
                  className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                />
              </button>

              {/* Commercial Information */}
              <button
                className={`relative rounded-2xl border border-[#E9EFF2] px-4 py-6 ${
                  !isTechnicalEvaluationCompleted || isCommercialsCompleted
                    ? 'cursor-not-allowed opacity-70'
                    : ''
                }`}
                onClick={() => {
                  if (isTechnicalEvaluationCompleted) {
                    navigate(`/procurement/bids/${tenderBidId}/commercialInformation`);
                  }
                }}
                disabled={!isTechnicalEvaluationCompleted || isCommercialsCompleted}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="mb-1 text-base font-medium text-[#292D32]">
                      Commercial Information/Financial Projection
                    </h4>

                    <div className="flex flex-col gap-x-4 gap-y-1 xl:flex-row">
                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#DA1E28]">Deadline:</span>{' '}
                        {moment(tenderBidDetails[2]?.deadLine).format('DD/MM/YYYY')}
                      </p>

                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#24A148]">Bid Submitted:</span>
                        {tenderBidDetails[2]?.submissionDate
                          ? moment(tenderBidDetails[2]?.submissionDate).format('DD/MM/YYYY')
                          : 'N/A'}
                      </p>
                    </div>
                  </div>

                  {tenderBidDetails[2]?.stageStatus ? (
                    <>
                      {tenderBidDetails[2]?.stageStatus === 'InProgress' ? (
                        <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#128FFF] px-5 py-3 text-sm font-semibold text-[#128FFF]">
                          <span>In progress</span>
                        </div>
                      ) : tenderBidDetails[2]?.stageStatus === 'Completed' ? (
                        <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#42BE65] px-5 py-3 text-sm font-semibold text-[#42BE65]">
                          <span>Completed</span>
                        </div>
                      ) : (
                        <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#DA1E28] px-5 py-3 text-sm font-semibold text-[#DA1E28]">
                          <span>Rejected</span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#D0D5DD] px-5 py-3 text-sm font-semibold text-[#D0D5DD]">
                      <span>Upcoming</span>
                    </div>
                  )}
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/${tenderBidDetails[2]?.stageStatus ? imageLookup[tenderBidDetails[2]?.stageStatus] : 'tender-upcoming.svg'}`}
                  alt="Completed"
                  className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                />
              </button>
            </div>
          </div>

          <div className="mt-7 flex justify-end gap-x-4">
            <button className="min-w-[172px] rounded-lg bg-[#F5F5F5] text-sm font-semibold text-[#101828] hover:bg-[#E5E5E5]">
              Withdraw
            </button>

            <button
              className={`min-w-[172px] rounded-lg py-3 text-sm font-semibold text-[#101828] ${
                !isExpressionOfInterestCompleted && !isTechnicalEvaluationCompleted
                  ? 'cursor-not-allowed bg-[#FFC72680]'
                  : 'bg-[#FFC726] hover:bg-[#e6b800]'
              }`}
              onClick={handleContinueApplication}
              disabled={!isExpressionOfInterestCompleted && !isTechnicalEvaluationCompleted}
            >
              Continue Application
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcurementBidTimelinePage;

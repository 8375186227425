import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './SideBar/Sidebar';
import MobileSidebar from '../components/MobileSideBar/MobileSidebar';
import Navbar from './NavBar/Navbar';

const ProtectedLayout: React.FC = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const handleHamburgerClick = () => {
    setIsMobileSidebarOpen(true);
  };
  const handleCloseSidebar = () => {
    setIsMobileSidebarOpen(false);
  };
  return (
    <div>
      <div className="">{/* <Navbar onHamburgerClick={handleHamburgerClick} /> */}</div>
      {/* <Navbar /> */}
      <div className="overview-container gap-2">
        <Sidebar />
        {/* Desktop Sidebar */}
        {/* {!isMobileSidebarOpen && <Sidebar/>} */}
        {/* {!isMobileSidebarOpen && (
        <div className='' >
          <Sidebar />
        </div>) } */}

        {/* Mobile Sidebar */}
        {/* {isMobileSidebarOpen && (
          <MobileSidebar  />
        )} */}
        <div className="min-h-screen bg-[#FAFAFA] md:ml-[300px] md:px-8">
          {/* This will render the child routes */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProtectedLayout;

import { Search, XIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { ProcurementProject } from '../../../../types/procurement-types';
import { toast } from 'react-toastify';
import { deleteProcurement, getAllProcurements } from '../../../../services/procurementService';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ProcurementProjectDetails from './ProcurementProjectDetails';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

const TenderOpportunitiesComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialStatus = searchParams.get('status') || 'Approved';

  const [searchTerm, setSearchTerm] = useState('');
  const [activeStatus, setActiveStatus] = useState(initialStatus);
  const [selectedProject, setSelectedProject] = useState<ProcurementProject | null>(null);

  const queryClient = useQueryClient();

  const statusMap = {
    Approved: 3,
    Processing: 1,
    Rejected: 2,
    Draft: 4,
  };

  const fetchProcurements = async (status: string) => {
    try {
      const response = await getAllProcurements(statusMap, status);
      return response?.data;
    } catch {
      toast.error('Failed to fetch procurements. Please try again later.');
      throw new Error('Failed to fetch procurements');
    }
  };

  const {
    data: procurements = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['procurements', activeStatus],
    queryFn: () => fetchProcurements(activeStatus),
    // staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
    retry: 2, // Retry fetching up to 2 times in case of failure
  });

  const handleStatusChange = (status: string) => {
    setActiveStatus(status);
    setSearchParams({ tab: 'Tender Opportunities', status });
    // queryClient.invalidateQueries({ queryKey: ['procurements', status] });
  };

  useEffect(() => {
    if (location.state && location.state.refresh) {
      refetch();
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, refetch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <div className="min-h-[75vh] rounded-2xl border border-[#E5E5E5] bg-white p-8">
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-3.5 py-2.5 pl-12 text-base text-gray-800"
          />
          <Search
            className="absolute left-4 top-1/2 -translate-y-1/2 transform text-gray-500"
            size={20}
          />
        </div>

        <div className="mb-6 flex space-x-10 border-b border-[#E5E5E5] pb-4">
          {Object.keys(statusMap).map((status) => (
            <button
              key={status}
              className={`rounded-full px-3.5 py-2.5 text-base ${
                activeStatus === status
                  ? 'bg-green-600 font-semibold text-white'
                  : 'bg-none text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => handleStatusChange(status)}
            >
              {status}
            </button>
          ))}
        </div>

        {isLoading ? (
          <div className="flex min-h-[40vh] items-center justify-center">
            <div>
              <h3 className="text-center text-2xl font-semibold text-[#161616]">Loading...</h3>
            </div>
          </div>
        ) : procurements.length > 0 ? (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 2xl:w-5/6">
            {procurements.map((procurementItem, index) => (
              <ProcurementCard
                key={index}
                procurement={procurementItem}
                status={activeStatus}
                setSelectedProject={setSelectedProject}
                fetchProcurements={refetch}
              />
            ))}
          </div>
        ) : (
          <div className="flex min-h-[40vh] items-center justify-center">
            <div>
              <h3 className="text-center text-2xl font-semibold text-[#161616]">
                No Tender Opportunities
              </h3>
              <p className="text-center text-sm font-medium text-[#7F7F7F]">
                Tender opportunities are not available for this tab
              </p>
            </div>
          </div>
        )}
      </div>

      {selectedProject && (
        <ProcurementProjectDetails
          project={selectedProject}
          activeStatus={activeStatus}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </>
  );
};

const ProcurementCard = ({
  procurement,
  status,
  setSelectedProject,
  fetchProcurements,
}: {
  procurement: ProcurementProject;
  status: string;
  setSelectedProject: React.Dispatch<React.SetStateAction<ProcurementProject | null>>;
  fetchProcurements: () => void;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleView = () => {
    setSelectedProject(procurement);
    handleMenuClose();
  };

  const handleEdit = () => {
    navigate(`/procurement/edit/${procurement.serviceInformationId}`);
    handleMenuClose();
  };

  const handleDelete = async () => {
    console.log('Delete clicked', procurement);
    try {
      const response = await deleteProcurement(procurement.serviceInformationId);

      if (response.isSuccess) {
        toast.success(response.data.message);
        fetchProcurements();
      }
    } catch (error) {
      console.error('Something went wrong: ', error);
      toast.error('Unabale to delete, Something went wrong!');
    }
    handleMenuClose();
  };

  const onConfirmDelete = () => {
    handleDelete();
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleMenuClose();
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <>
      <div className="relative cursor-pointer rounded-xl border border-gray-200 bg-white px-4 py-3 shadow-sm">
        <div className="mb-4 flex items-center justify-between">
          <span
            className={`border ${
              status === 'Approved'
                ? 'border-[#24A148] text-[#24A148]'
                : status === 'Rejected'
                  ? 'border-[#FA4D56] text-[#FA4D56]'
                  : 'border-[#E5E5E5] text-[#8D8D8D]'
            } rounded-[30px] px-2 py-1`}
          >
            {status === 'Approved' ? status : status === 'Rejected' ? 'Denied' : 'Unpublished'}
          </span>

          <div className="relative" ref={menuRef}>
            <button onClick={handleMenuToggle}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/outline/more.svg`}
                alt="more-icon"
              />
            </button>

            {menuOpen && (
              <div className="absolute right-0 top-6 z-10 w-36 rounded-lg border border-gray-200 bg-white shadow-lg">
                <button
                  onClick={handleView}
                  className="w-full px-4 py-2 text-left text-sm font-medium text-[#667085] hover:bg-gray-100"
                >
                  View
                </button>
                {status === 'Draft' && (
                  <button
                    onClick={handleEdit}
                    className="w-full px-4 py-2 text-left text-sm font-medium text-[#667085] hover:bg-gray-100"
                  >
                    Edit
                  </button>
                )}
                <button
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="w-full px-4 py-2 text-left text-sm font-medium text-[#FA4D56] hover:bg-red-100"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>

        <h3 className="mb-3 line-clamp-2 min-h-11 text-base font-medium text-[#161616]">
          {procurement.title}
        </h3>

        <p className="mb-6 line-clamp-3 text-sm text-[#667085]">{procurement.description}</p>

        <span className="text-xs italic text-[#24A148]">
          {moment(procurement.modifiedDate).format('MMMM DD, YYYY')}
        </span>
      </div>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteConfirmationModal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center bg-[#1C1B1F] bg-opacity-50">
      <div className="mt-[20vh] w-full max-w-md rounded-[30px] bg-white p-8">
        <div className="mb-12 flex justify-end">
          <button
            className="rounded-full border-[1.5px] border-[#101828] p-0.5 hover:bg-gray-200"
            onClick={onClose}
          >
            <XIcon className="h-3 w-3 text-[#1C1B1F]" />
          </button>
        </div>

        <h2 className="mb-6 text-center text-2xl font-semibold text-[#101828]">
          Delete this Tender Opportunity?
        </h2>

        <button
          className="mx-auto flex w-1/2 items-center justify-center rounded-lg bg-[#FA4D56] px-5 py-3 text-base font-semibold text-white"
          onClick={onConfirm}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default TenderOpportunitiesComponent;

import React, { useState, useEffect } from 'react';
import CustomTable, { Column, IPageRequest } from '../../../components/CustomTable';
import { useNavigate } from 'react-router-dom';
import Delete from '../../../components/Delete/Delete';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import { deleteRole } from '../../../services/AccountServices';
import { toast } from 'react-toastify';
import {
  deleteLang,
  deleteLookup,
  getAllLangs,
  getLookups,
} from '../../../services/utilityServices';
import { ILookup } from '../../../types/interfaces';
import LookupModal, { INotifyMgt } from './NotificationMgtModal';
import NotifyMgtModal from './NotificationMgtModal';

const breadcrumbPaths = [
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Configurations', path: '/configuration/lookup' },
  { label: 'Notifications', path: '/configuration/notifications' },
];

const NotificationManagementComponent = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [lookups, setLookups] = useState<INotifyMgt[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<INotifyMgt | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRolePermissionModalOpen, setIsRolePermissionModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling the modal
  const [isEditMode, setIsEditMode] = useState(false);

  const navigate = useNavigate();

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  // Fetch roles data from the API
  const fetchLanguageData = async () => {
    try {
      const response = await getAllLangs(pageRequest);
      if (response) {
        if (!response.data) return;
        response.data = response.data.map((item: any) => ({
          ...item,
          languageId: item.languageTranslationId,
        }));
        setLookups(response?.data);
        if (response?.totalRecords) {
          setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
        }
      }
    } catch (error) {
      // console.error('Error fetching roles:', error);
    }
  };

  useEffect(() => {
    fetchLanguageData();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // Implement additional search logic if needed
  };

  const handleDelete = (item: INotifyMgt) => {
    setSelectedNotification(item);
    setIsDeleteModalOpen(true);
  };

  const deleteSelectedLookup = async (selectedNotification?: INotifyMgt | null) => {
    if (selectedNotification) {
      if (!selectedNotification?.languageId) return;
      const response = await deleteLang(selectedNotification.languageId);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        fetchLanguageData();
      } else {
        toast.error(response?.errorMessage);
      }
      setIsDeleteModalOpen(false);
      setSelectedNotification(null);
    }
  };
  const handleEditLookup = (item: any) => {
    setSelectedNotification(item);
    setIsEditMode(true);
    setIsModalOpen(true);
    setIsRolePermissionModalOpen(true);
  };

  const handleOpenRolePermissionModal = () => {
    setIsRolePermissionModalOpen(true);
  };

  const handleCloseRolePermissionModal = () => {
    setSelectedNotification(null);
    setIsRolePermissionModalOpen(false);
  };

  const columns: Column[] = [
    {
      title: 'Key',
      dataKey: 'key',
      type: 'string',
    },
    {
      title: 'Culture',
      dataKey: 'culture',
      type: 'string',
    },
    {
      title: 'Message',
      dataKey: 'messageValue',
      type: 'string',
    },
    {
      title: 'Actions',
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: 'Edit',
          className: 'text-green-500',
          action: handleEditLookup,
          status: true,
        },
        {
          label: 'Delete',
          className: 'text-red-500',
          action: handleDelete,
          status: true,
        },
      ],
    },
  ];

  return (
    <div className="px-[32px] py-6">
      <h1 className="mb-4 text-4xl font-bold">Notifications</h1>
      <Breadcrumb paths={breadcrumbPaths} />

      <CustomTable
        data={lookups}
        columns={columns}
        pageCount={pageCount}
        showTitle={false}
        addButtonText="+ Add"
        showPagination={true}
        showSearch={true}
        onSearch={handleSearch}
        showAddButton={true}
        title="Add Lookup"
        onAddButtonClick={handleOpenRolePermissionModal}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />

      {isDeleteModalOpen && (
        <Delete
          title="Are you sure?"
          desc="This action is permanent, all data would be lost."
          onDelete={() => deleteSelectedLookup(selectedNotification)}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}

      {isRolePermissionModalOpen && (
        <NotifyMgtModal
          onClose={handleCloseRolePermissionModal}
          onSubmit={() => {
            fetchLanguageData();
            setSelectedNotification(null);
          }}
          isEditable={true}
          notification={selectedNotification}
        />
      )}
    </div>
  );
};

export default NotificationManagementComponent;

import React, { useEffect, useState } from 'react';
import { ModuleCard } from './components/CardList';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSupplierProgress } from '../../../services/supplierService';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { updateOnboardingStatus } from '../../../utils/helper';
import { OnboardingStatus, OnboardingStatus2 } from '../../../types/enums';
import ApplicationProgress from '../../../components/ApplicationProgressBar';
// import { OnboardingStatus } from '../../../types/enums';

const SupplierApplication: React.FC = () => {
  const [progress, setProgress] = useState<number>(20);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { supplierId } = useGlobalContext();
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus2 | null>(null);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        if (!supplierId) return;
        const response = await getSupplierProgress(supplierId);
        if (response?.isSuccess) {
          let percentage = 0;
          if (response?.data.totalStage > 0) {
            percentage = (response?.data.currentStage / response?.data.totalStage) * 100;
          }
          let status: OnboardingStatus2 | null = null;
          const supplierRole = localStorage.getItem('userRole');
          if (response?.data?.status == null && supplierRole === 'Supplier') {
            setOnboardingStatus(OnboardingStatus2.APPROVED);
          } else {
            //status = updateOnboardingStatus(response?.data.status);
            status = response?.data.status;
            setOnboardingStatus(status);
          }

          if (status === OnboardingStatus2.APPROVED) {
            setProgress(100);
          } else if (percentage === 100 && response?.data.status !== OnboardingStatus.APPROVED) {
            setProgress(80);
          } else {
            setProgress(percentage);
          }
        } else {
          // toast.error(response.errorMessage);
        }
      } catch (error: any) {
        toast.error(error);
      }
    };

    fetchProgress();
  }, []);

  return (
    <div className="relative mx-auto max-w-screen-xl p-6">
      <h1 className="mb-14 mt-4 text-3xl font-semibold">
        {t('supplierModule', 'Supplier Module')}
      </h1>

      <ApplicationProgress percentage={progress} />

      <div className="mt-11">
        {onboardingStatus === OnboardingStatus2.APPROVED && (
          <div className="w-full rounded-[10px] border border-[#E5E5E5] bg-transparent p-8">
            <div className="mb-2 flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium">
                  {t('applicationCompleted', 'Application completed')}
                </h3>
                <p className="text-gray-500">{t('youAreVerified', 'You are verified')}</p>
              </div>
              <button className="h-12 w-24 rounded-lg bg-[#F5F5F5] p-2 font-semibold text-[#C6C6C6]">
                {t('completed', 'Completed')}
              </button>
            </div>
          </div>
        )}

        {(onboardingStatus === OnboardingStatus2.DRAFT || onboardingStatus === null) && (
          <div className="w-full rounded-[10px] border border-[#E5E5E5] bg-transparent p-8">
            <div className="mb-2 flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium">
                  {t('applicationSavedProgress', 'Application Saved Progress')}
                </h3>
                <p className="text-gray-500">
                  {t(
                    'supplierApplicationVerificationProgress',
                    'This shows your supplier application verification progress'
                  )}
                </p>
              </div>

              <button
                className="h-12 min-w-24 rounded-lg bg-primary p-2 font-semibold text-black"
                onClick={() =>
                  navigate('/supplier-registration', {
                    state: { isSupplier: true },
                  })
                }
              >
                {t('update', 'Update')}
              </button>
            </div>
          </div>
        )}

        {onboardingStatus === OnboardingStatus2.REJECTED && (
          <div className="mt-8 w-full rounded-lg border border-[#118AA2] bg-[#EDFCFF] p-8">
            <div className="mb-2 flex items-center justify-between">
              <div className="flex gap-4">
                <div className="flex items-start">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/outline/Info.svg`}
                    alt={t('info', 'info')}
                  />
                </div>
                <div>
                  <h3 className="text-lg font-medium">
                    {t('requestedDocuments', 'Requested Documents')}
                  </h3>
                  <p className="text-gray-500">
                    {t(
                      'additionalDocumentsRequired',
                      'These shows additional documents you need to provide to complete your verification progress'
                    )}
                  </p>
                  <ol className="mt-4 list-inside list-decimal">
                    <li>Import and Export Licenses</li>
                    <li>Letter of Authorisation from a Licensed body</li>
                  </ol>
                </div>
              </div>
              <button
                className="h-12 w-24 rounded-lg bg-[#118AA211] p-2 font-semibold text-black"
                onClick={() =>
                  navigate('/supplier-registration', {
                    state: { isSupplier: true },
                  })
                }
              >
                {t('update', 'Update')}
              </button>
            </div>
          </div>
        )}

        {onboardingStatus !== OnboardingStatus2.APPROVED && (
          <div className="mt-8 w-full rounded-[10px] border border-[#E5E5E5] bg-transparent p-8">
            <div>
              <h3 className="mb-2 text-xl font-medium">
                {t('relevantTrainings', 'Relevant Trainings')}
              </h3>
              <p className="mb-10 text-sm text-gray-500">
                {t(
                  'trainingsAssistOnboarding',
                  'These trainings will assist you in your onboarding process.'
                )}
              </p>

              <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
                {ModuleCard.map((card) => (
                  <div
                    key={card.id}
                    className="flex cursor-pointer flex-col items-center justify-between rounded-lg bg-white p-4 transition-shadow duration-300 hover:shadow-md"
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      className="mb-4 h-40 w-full rounded-md object-cover"
                    />
                    <h4 className="mb-2 flex w-full justify-start text-sm font-medium">
                      {t(card.title, card.title)}
                    </h4>
                    <p className="justify start flex w-full text-xs text-gray-500">
                      {t(card.description, card.description)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupplierApplication;

import React, { useState } from 'react';
import { FaBars, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { HiMenu } from 'react-icons/hi';
import ReactCountryFlag from 'react-country-flag';
// import { NavLink } from 'react-router-dom';
import AppButton from '../../components/AppButton/AppButton';
// import { getNavData } from './index';
import './NavBar.css';
import { useNavigate, useLocation } from 'react-router-dom';
interface NavbarProps {
  onHamburgerClick?: () => void;
  theme?: 'light' | 'dark';
}
const Navbar: React.FC<NavbarProps> = ({ onHamburgerClick, theme = 'light' }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Available languages
  const languages = [
    { code: 'tet', label: 'Tetum', countryCode: 'TL' },
    { code: 'en-US', label: 'English', countryCode: 'US' },
    { code: 'pt-PT', label: 'Portugues', countryCode: 'PT' },
  ];

  // State to control the dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLangCode = localStorage.getItem('i18nextLng') || 'tet';
    const storedLang = languages.find((lang) => lang.code === storedLangCode);
    return storedLang ? storedLang.label : languages[0].label;
  });
  const currentPath = location.pathname;

  // Function to change language
  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
    const selectedLang = languages.find((lang) => lang.code === code)?.label;
    setSelectedLanguage(selectedLang || selectedLanguage);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  // Get navigation data using the function
  // const navData = getNavData(t);

  return (
    <div
      className={`navbar ${theme === 'dark' ? 'bg-[#1C1C1C] text-white' : 'bg-white text-black'}`}
    >
      <div className="flex gap-4">
        <div className="navbar-logo flex items-center space-x-2">
          <img
            className="nav-logo w-12 lg:w-16"
            src={`${process.env.PUBLIC_URL}/assets/images/navbar/AnpLogo.png`}
            alt={t('anpLogo', 'ANP Logo')}
          />
          <h6 className="logo-text text-[10px] font-light sm:text-[10px] sm:font-light md:text-[10px] md:font-semibold lg:text-[14px] lg:font-bold">
            {t('enterpriseSupportServiceCenter', 'Enterprise Support Service Center')}
          </h6>
        </div>
        {/* <div className="navbar-logo">
          <img
            className="nav-logo w-12 sm:w-12 md:w-12 lg:w-16"
            src={`${process.env.PUBLIC_URL}/assets/images/navbar/AnpLogo2.png`}
            alt={t('anpLogo2', 'ANP Logo')}
          />
          <h6 className="logo-text2 font-light sm:font-light md:font-semibold lg:font-bold text-[10px] sm:text-[10px] md:text-[10px] lg:text-[14px]">
            {t(
              'autoridadeNacionalDoPetroleo',
              'Autoridade Nacional Do Petroleo'
            )}
            <br />
            {t('timorLeste', 'Timor - Leste')}
          </h6>
        </div> */}
      </div>
      {/* <div className="nav-links">
        {navData.map(({ title, path }) => (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t(title, title)}
          </NavLink>
        ))}
      </div> */}
      <div className="nav-buttons hidden items-center gap-5 md:flex">
        <div className="relative">
          {/* Container for the button and selected language */}
          <div
            className="flex cursor-pointer items-center rounded-2xl border border-gray-300 bg-white px-2 py-1"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <ReactCountryFlag
              countryCode={
                languages.find((lang) => lang.label === selectedLanguage)?.countryCode || 'US'
              }
              svg
            />

            {/* Selected Language with Arrow */}
            <div className="ml-2 flex items-center gap-2">
              <span className="text-gray-800">{selectedLanguage}</span>
              <FaChevronDown className="text-gray-600" />
            </div>
          </div>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <ul className="dropdown-menu absolute right-0 mt-2 rounded-md border border-gray-300 bg-white shadow-lg">
              {languages.map(({ code, label, countryCode }) => (
                <li
                  key={code}
                  className={`flex cursor-pointer items-center space-x-2 p-2 hover:bg-gray-100 ${theme === 'dark' ? 'text-black' : ''}`}
                  onClick={() => handleLanguageChange(code)}
                >
                  <ReactCountryFlag countryCode={countryCode} svg />
                  <span>{label}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Render the Register button only if the current route is '/login' */}
        {currentPath === '/login' && (
          <AppButton
            onClick={() => navigate('/signup-options')}
            className="primary"
            title="Register"
          />
        )}

        {/* Render the Login button only if the current route is '/register' */}
        {(currentPath === '/register' || currentPath === '/signup-options') && (
          <AppButton onClick={() => navigate('/login')} className="secondary" title="Login" />
        )}
        {/* Hamburger Menu for Mobile */}
        {/* <button
        className="block sm:hidden p-2 rounded-md focus:outline-none"
        onClick={onHamburgerClick}
        aria-label="Open Menu"
      >
        <FaBars className="text-2xl text-gray-700" />
      </button> */}
      </div>
    </div>
  );
};

export default Navbar;

import React, { useMemo, useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { createMenu, updateMenu, getAllMenu } from '../../../services/AccountServices'; // Import your API functions
import { useTranslation } from 'react-i18next';

interface NavigationMenuModalProps {
  onClose: () => void;
  initialValues?: any | null;
  isEditable: boolean;
  menu?: any; // Add the new menu prop
}

const validationSchema = Yup.object().shape({
  menuUrl: Yup.string().required('Menu URL is required'),
  menuName: Yup.string().required('Menu Name is required'),
  isModule: Yup.boolean().required('Accessible Module is required'),
});

const NavigationMenuModal: React.FC<NavigationMenuModalProps> = ({
  onClose,
  initialValues,
  isEditable,
  menu, // Destructure the new menu prop
}) => {
  const [navigationMenus, setNavigationMenus] = useState<any[]>([]);
  const currentPage = 1; // Define currentPage and itemsPerPage as needed
  const itemsPerPage = 100;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchMenusData = async () => {
      const pageRequest = { pageNumber: currentPage, pageSize: itemsPerPage };
      try {
        const response = await getAllMenu(pageRequest);
        if (response?.data) {
          setNavigationMenus(response.data);
        }
      } catch (error) {
        // console.error('Error fetching menus:', error);
        toast.error('Error occurred while fetching navigation menus');
      }
    };

    fetchMenusData();
  }, [currentPage, itemsPerPage]);

  const formInitialValues = useMemo(() => {
    if (menu) {
      return {
        ...menu,
        active: menu.active || false, // Ensure active is handled
        parentMenuId: menu?.parentMenu?.menuId || null, // Initialize parentMenuId
        isModule: menu.isModule || false, // Initialize isModule
      };
    }
    return {
      parentMenuId: null,
      menuUrl: '',
      menuName: '',
      active: false, // Initialize toggle as false
      isModule: false, // Initialize isModule as false
    };
  }, [menu]);

  const handleSubmit = async (values: any) => {
    try {
      if (menu) {
        // Call the updateMenu API
        const payload = { ...values, isModule: values.isModule };
        if (!payload.parentMenuId) {
          delete payload.parentMenuId;
        }
        const response = await updateMenu(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onClose();
        } else {
          toast.error(response?.errorMessage);
        }
      } else {
        // Call the createMenu API
        const payload = { ...values, isModule: values.isModule };
        if (!payload.parentMenuId) {
          delete payload.parentMenuId;
        }
        const response = await createMenu(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.errorMessage);
        }
      }
      onClose(); // Close modal after successful operation
    } catch (error: any) {
      toast.error('Error occurred while saving menu');
    }
    // console.log(values);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="w-full max-w-4xl rounded-2xl bg-white p-8 shadow-lg">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold">
            {menu ? 'Edit Navigation Menu' : 'Add Navigation Menu'}
          </h2>
          <button className="text-xl text-red-500" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt={t('close', 'close')}
            />
          </button>
        </div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col gap-y-8 rounded-[30px] bg-white p-8">
              <div className="mb-4 grid grid-cols-1 gap-4">
                {/* Accessible Module Dropdown */}
                <div>
                  <label htmlFor="isModule" className="mb-1 block">
                    Accessible Module
                  </label>
                  <Field
                    as="select"
                    name="isModule"
                    className="h-11 w-full rounded-lg border p-2 text-sm"
                    onChange={(e: any) => {
                      setFieldValue('isModule', e.target.value === 'true');
                      if (e.target.value === 'true') {
                        setFieldValue('parentMenuId', '');
                      }
                    }}
                  >
                    <option value="false" label="No" />
                    <option value="true" label="Yes" />
                  </Field>
                  <ErrorMessage name="isModule" component="span" className="text-sm text-red-500" />
                </div>

                {/* Parent Name Dropdown */}
                {!values.isModule && (
                  <div>
                    <label htmlFor="parentMenuId" className="mb-1 block">
                      Parent Name
                    </label>
                    <Field
                      as="select"
                      name="parentMenuId"
                      className="h-11 w-full rounded-lg border p-2 text-sm"
                    >
                      <option value="" label="Select Parent Name" />
                      {navigationMenus.map((navMenu, index) => (
                        <option key={index} value={navMenu.menuId} label={navMenu.menuName} />
                      ))}
                    </Field>
                    <ErrorMessage
                      name="parentName"
                      component="span"
                      className="text-sm text-red-500"
                    />
                  </div>
                )}

                {/* Menu URL */}
                <div>
                  <label htmlFor="menuUrl" className="mb-1 block">
                    Menu URL<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="menuUrl"
                    placeholder="Enter Menu URL"
                    className="h-11 w-full rounded-lg border p-2 text-sm"
                  />
                  <ErrorMessage name="menuUrl" component="span" className="text-sm text-red-500" />
                </div>

                {/* Menu Name */}
                <div>
                  <label htmlFor="menuName" className="mb-1 block">
                    Menu Name<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="menuName"
                    placeholder="Enter Menu Name"
                    className="h-11 w-full rounded-lg border p-2 text-sm"
                  />
                  <ErrorMessage name="menuName" component="span" className="text-sm text-red-500" />
                </div>
              </div>

              {/* Toggle Status */}
              <div className="flex flex-col">
                <label htmlFor="activeStatus" className="mr-2">
                  Toggle Status
                </label>
                <label className="mt-2 flex items-center">
                  <Toggle
                    checked={values.active}
                    name="active"
                    icons={false}
                    onChange={(e) => setFieldValue('active', e.target.checked)} // Set toggle value correctly
                    className="toggle-green"
                  />
                  <span className="ml-2">Active</span>
                </label>
              </div>

              {/* Submit Button */}
              <div className="mt-4 flex justify-end">
                <button type="submit" className="rounded-md bg-green-500 px-6 py-2 text-white">
                  {menu ? 'Update Menu' : 'Save Menu'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NavigationMenuModal;

import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import CompanyInformation from './CompanyInformation';
import {
  CompanyRegisterFormValues,
  OperatorCompanyInfo,
  TabItemProps,
} from '../../types/interfaces';
import { PrimaryContactTable } from './PrimaryContact/PrimaryContact';
import CompanySuccessModal from './CompanySuccessModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createOperatorUser } from '../../services/OperatorServices';
import { toast } from 'react-toastify';

const OperatorManagement = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isCompanySuccessModalOpen, setIsCompanySuccessModalOpen] = useState(false);
  const [isSaveAndContinueDisabled, setIsSaveAndContinueDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState<'Company Information' | 'Primary Contact'>(
    'Company Information'
  );
  const [operatorInformation, setOperatorInformation] = useState<OperatorCompanyInfo | null>(null);
  // Handler for switching tabs
  const handleTabClick = (tab: 'Company Information' | 'Primary Contact', index: number) => {
    setActiveTab(tab);
    setSelectedIndex(index);
  };
  const moveToNextTab = () => {
    setSelectedIndex((prevTab) => prevTab + 1); // Move to the next tab
  };
  const breadcrumbPaths = [
    { label: 'Operators', path: '/users/operators' },
    { label: 'Add operator or other users', path: '/companyinformation' },
  ];
  const formRef = useRef<{ submitForm: () => void }>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode;
  const { id } = useParams();
  const operatorId = id;

  const handleNext = async () => {
    if (selectedIndex === 0 && formRef.current) {
      formRef.current.submitForm();
    } else {
      if (selectedIndex === 1) {
        if (!operatorInformation) return;
        const payload = {
          operatorId: operatorInformation?.organizationId,
        };
        const response = await createOperatorUser(payload);
        if (response?.isSuccess) {
          setIsCompanySuccessModalOpen(true);
        } else {
          toast.error(response?.errorMessage);
        }
      } else {
        setSelectedIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const handleExit = () => {
    navigate(-1);
  };

  const handleValuesChange = (values: OperatorCompanyInfo) => {
    setOperatorInformation(values);
  };

  const handlePrevious = () => {
    setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 1));
  };

  const checkListLength = (listLength: number) => {
    if (listLength === 0) {
      setIsSaveAndContinueDisabled(true);
    } else {
      setIsSaveAndContinueDisabled(false);
    }
  };

  return (
    <div className="bg-gray-50 px-8 pb-12 pt-10">
      <h1 className="mb-8 text-xl font-semibold">
        {t('Create Operator or Other Users', 'Create Operator or Other Users')}
      </h1>
      <Breadcrumb paths={breadcrumbPaths} />
      {/* Tabs Section */}
      <div className="mb-4 mt-4">
        <div
          className={`flex w-full justify-start space-x-8 px-3 pt-4 ${!isEditMode ? 'pointer-events-none' : ''}`}
        >
          <TabItem
            title={t('Company Information', 'Company Information')}
            isActive={activeTab === 'Company Information'}
            onClick={() => isEditMode && handleTabClick('Company Information', 0)}
          />
          <TabItem
            title={t('PrimaryContact', 'Primary Contact')}
            isActive={activeTab === 'Primary Contact'}
            onClick={() => isEditMode && handleTabClick('Primary Contact', 1)}
          />
        </div>
        {/* Content for Each Tab */}
        <div>
          {activeTab === 'Company Information' && (
            <CompanyInformation
              ref={formRef}
              isEditMode={isEditMode}
              affiliates={[]}
              onFormSubmitSuccess={() => handleTabClick('Primary Contact', 1)}
              onValuesChange={(values) => handleValuesChange(values)}
            />
          )}
          {activeTab === 'Primary Contact' && (
            <PrimaryContactTable isEditable={true} checkListLength={checkListLength} />
          )}
        </div>
      </div>
      <div className="mt-8 flex w-full justify-end gap-6">
        {selectedIndex > 0 ? (
          <button
            type="button"
            onClick={() => handleTabClick('Company Information', 0)}
            className="min-w-[172px] rounded-lg bg-[#F5F5F5] px-8 py-3 text-sm font-semibold text-[#101828]"
          >
            {selectedIndex === 0 ? t('exit', 'Exit') : t('previous', 'Previous')}
          </button>
        ) : null}

        {selectedIndex === 0 && operatorId && (
          <button
            type="button"
            onClick={() => handleTabClick('Primary Contact', 1)}
            className="min-w-[172px] rounded-lg bg-[#F5F5F5] px-8 py-3 text-sm font-semibold text-[#101828]"
          >
            {t('next', 'Next')}
          </button>
        )}

        <button
          type="button"
          onClick={handleNext}
          className={`min-w-[172px] rounded-lg px-8 py-3 text-sm font-semibold text-[#101828] ${isSaveAndContinueDisabled ? 'bg-[#F5F5F5]' : 'bg-primary'}`}
          disabled={isSaveAndContinueDisabled}
        >
          {selectedIndex === 1 ? t('create', 'Create') : t('saveAndContinue', 'Save & Continue')}
        </button>
        {isCompanySuccessModalOpen && (
          <CompanySuccessModal
            onClose={() => {
              setIsCompanySuccessModalOpen(false);
              navigate('/users/operators');
            }}
          />
        )}
      </div>
    </div>
  );
};
const TabItem: React.FC<TabItemProps> = ({ title, isActive, onClick }) => {
  return (
    <button
      className={`pb-2 text-lg font-medium focus:outline-none ${
        isActive ? 'border-b-4 border-[#FFC726] font-bold' : 'text-gray-500'
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default OperatorManagement;

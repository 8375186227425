import { useQuery } from '@tanstack/react-query';

import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import moment from 'moment';
import { getBidApplicants } from '../../services/procurementService';

interface AllBidsComponentProps {
  serviceInformationId: string;
}

const AllBidsComponent: React.FC<AllBidsComponentProps> = ({ serviceInformationId }) => {
  const navigate = useNavigate();

  const { data: allBids = [] } = useQuery({
    queryKey: ['procurements-proposals'],
    queryFn: async () => {
      try {
        const response = await getBidApplicants(serviceInformationId);
        return response?.data || [];
      } catch (error) {
        toast.error('Failed to fetch proposals. Please try again later.');
        throw error;
      }
    },
    enabled: !!serviceInformationId,
    // staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
    retry: 2, // Retry fetching up to 2 times in case of failure
  });

  return (
    <div className="min-h-[65vh] rounded-2xl border border-[#E5E5E5] bg-white p-8">
      <h2 className="mb-6 text-2xl font-semibold text-[#222]">Bids</h2>

      <div className="overflow-auto rounded-xl border border-[#E4E7EC] shadow-sm">
        {allBids.length > 0 ? (
          <>
            <div className="grid grid-cols-[1.5fr_1fr_1fr_1.5fr] gap-4 rounded-t-xl border-b border-[#D2D6DB] bg-[#F5F5F5] px-5 py-[14px] text-sm font-semibold text-[#40474F]">
              <span>Applicant</span>
              <span>Response Status</span>
              <span>Submission date</span>
              <span>Bid Details</span>
            </div>

            {allBids.map((bidItem, index) => (
              <div
                key={bidItem.tenderBidId}
                className="grid grid-cols-[1.5fr_1fr_1fr_1.5fr] items-center gap-4 border-b border-[#D2D6DB] px-5 py-[14px]"
              >
                <span className="text-base font-semibold text-[#101828]">
                  {bidItem.applicantName}
                </span>
                <span className="text-sm font-medium text-[#525252]">{bidItem.businessType}</span>
                <span className="text-sm font-medium text-[#525252]">
                  {moment(bidItem.submissionDate).format('DD/MM/YYYY')}
                </span>
                <button
                  className="flex h-9 w-[82px] items-center justify-center rounded-lg bg-[#FFC726] text-sm font-semibold text-[#101828]"
                  onClick={() => {
                    navigate(
                      `/procurement/proposals/${serviceInformationId}/bid/${bidItem.tenderBidId}/expressionOfInterest`
                    );
                  }}
                >
                  View Bid
                </button>
              </div>
            ))}

            <div className="flex items-center justify-between px-6 pb-4 pt-3">
              <span className="text-sm text-[#344054]">Page 1 of 10</span>

              <div className="flex gap-x-3">
                <button
                  className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm"
                  disabled
                >
                  Previous
                </button>

                <button
                  className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm"
                  disabled
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex min-h-[40vh] items-center justify-center">
            <div>
              <h3 className="text-center text-2xl font-semibold text-[#161616]">
                No Bid(s) Available
              </h3>
              <p className="text-center text-sm font-medium text-[#7F7F7F]">
                Bids are not available for this tab
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllBidsComponent;

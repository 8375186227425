import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { ILookup } from '../../../types/interfaces';
import { createLookup, updateLookup } from '../../../services/utilityServices';

export interface OptionType {
  value: string;
  label: string;
}

interface LookupModalProps {
  onClose: () => void;
  isEditable: boolean;
  lookup?: ILookup | null;
  onSubmit: () => void;
}

// Validation schema
const validationSchema = Yup.object().shape({
  lookupName: Yup.string().required('Lookup name is required'),
  lookupType: Yup.string().required('Lookup type is required'),
  lookupValue: Yup.string().required('Lookup value is required'),
});

const LookupModal: React.FC<LookupModalProps> = ({ onClose, isEditable, lookup, onSubmit }) => {
  const [navigationMenuOptions, setNavigationMenuOptions] = useState<OptionType[]>([]);
  const [permissionOptions, setPermissionOptions] = useState<OptionType[]>([]);
  const [initialValues, setInitialValues] = useState<ILookup>({
    lookupName: '',
    lookupType: '',
    lookupId: '',
    lookupValue: '',
  });
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (values: ILookup) => {
    try {
      const { lookupId, ...createPayload } = values;
      if (lookupId === '') {
        const payload = {
          ...createPayload,
          // lookupValue: values.lookupName,
        };
        const response = await createLookup(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onSubmit();
        } else {
          toast.error(response?.errorMessage);
        }
      } else {
        const payload = {
          ...values,
          lookupValue: values.lookupName,
        };
        const response = await updateLookup(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onSubmit();
        } else {
          toast.error(response?.errorMessage);
        }
      }

      onClose();
    } catch (error: any) {
      toast.error('Error saving role permissions');
    }
  };

  useEffect(() => {
    if (lookup) {
      setInitialValues(lookup);
    }
  }, [lookup, loading]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="w-full max-w-4xl overflow-y-auto rounded-2xl bg-white p-8 shadow-lg">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold">{lookup ? 'Edit Lookup' : 'Add Lookup'}</h2>
          <button className="text-xl text-red-500" onClick={onClose}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/close.svg`} alt="close" />
          </button>
        </div>

        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col gap-y-6">
              <div className="mb-4 grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="lookupName" className="mb-1 block">
                    Lookup Name<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="lookupName"
                    placeholder="Enter Lookup Name"
                    className="h-11 w-full rounded-lg border p-2 text-sm"
                  />
                  <ErrorMessage
                    name="lookupName"
                    component="span"
                    className="text-sm text-red-500"
                  />
                </div>

                <div>
                  <label htmlFor="lookupType" className="mb-1 block">
                    Lookup Type
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="lookupType"
                    placeholder="Enter lookup Type"
                    className="h-11 w-full rounded-lg border p-2 text-sm"
                  />
                  <ErrorMessage
                    name="lookupType"
                    component="span"
                    className="text-sm text-red-500"
                  />
                </div>

                <div>
                  <label htmlFor="lookupValue" className="mb-1 block">
                    Lookup Value<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="lookupValue"
                    placeholder="Enter Lookup Value"
                    className="h-11 w-full rounded-lg border p-2 text-sm"
                  />
                  <ErrorMessage
                    name="lookupValue"
                    component="span"
                    className="text-sm text-red-500"
                  />
                </div>
              </div>

              <div className="divider my-2 !bg-[#E5E5E5]"></div>

              <div className="mt-4 flex justify-end">
                <button type="submit" className="rounded-md bg-green-500 px-6 py-2 text-white">
                  {lookup ? 'Update Lookup' : 'Save Lookup'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LookupModal;

import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  AllowOrDenyTenderAccess,
  AllowOrdenyTenderProposal,
  getSingleProcurementProposal,
} from '../../services/procurementService';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import moment from 'moment';
import { supplierDetails } from '../../types/procurement-types';
import RequestViewProposalComponent from './RequestViewProposalComponent';
import AllBidsComponent from './AllBidsComponent';

const ProcurementProposalPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab = searchParams.get('tab') || 'Request to View';
  const [activeTab, setActiveTab] = useState(initialTab);

  const { id: serviceInformationId } = useParams<{ id: string }>();

  const { data: proposals } = useQuery({
    queryKey: ['procurements-proposals', activeTab, serviceInformationId],
    queryFn: async () => {
      try {
        const response = await getSingleProcurementProposal({
          //   isProposal: activeTab === 'Requests for proposal (RFP)',
          isView: activeTab === 'Request to View',
          serviceInformationId: serviceInformationId || '',
        });
        if (response.isSuccess) return response?.data || [];
      } catch (error) {
        toast.error('Failed to fetch proposals. Please try again later.');
        throw error;
      }
    },
    // Only enable the query if the activeTab is not 'All Bids'
    // enabled: activeTab !== 'All Bids',
    // staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    console.log('proposals: ', proposals);
  }, [proposals]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  const handleAction = useCallback(
    async (tenderAccessId: string, allow: boolean) => {
      try {
        const actionMap = {
          'Request to View': AllowOrDenyTenderAccess,
          'Requests for proposal (RFP)': AllowOrdenyTenderProposal,
        };

        const action = actionMap[activeTab as keyof typeof actionMap];

        if (action) {
          const response = await action({ tenderAccessId, allow });
          if (response.isSuccess) {
            toast.success(
              `${activeTab === 'Request to View' ? 'Request to Access' : 'Request to Propose'} ${allow ? 'granted' : 'denied'}.`
            );
          } else {
            toast.error(
              `Failed to ${allow ? 'grant' : 'deny'} ${activeTab === 'Request to View' ? 'access' : 'proposal'}. Please try again later.`
            );
          }
        }
      } catch (error) {
        toast.error('Failed to perform action. Please try again later.');
        console.error(error);
      }
    },
    [activeTab]
  );

  const handleAllowAccessOrProposal = (tenderAccessId: string) =>
    handleAction(tenderAccessId, true);
  const handleDenyAccessOrProposal = (tenderAccessId: string) =>
    handleAction(tenderAccessId, false);

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Electronic Procurement</h1>

      <div className="2xl:w-11/12">
        <div className="mb-4 mt-8 flex flex-row flex-wrap items-center gap-x-10 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12">
          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">Contract Name</span>
            <span className="text-sm font-medium text-[#525252]">
              {proposals?.detailModel?.title || 'N/A'}
            </span>
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">Type</span>
            <span className="text-sm font-medium text-[#525252]">
              {proposals?.detailModel?.businessType || 'N/A'}
            </span>
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">Date</span>
            <span className="text-sm font-medium text-[#525252]">
              {proposals?.detailModel?.bidEndDate
                ? moment(proposals?.detailModel?.bidEndDate).format('DD/MM/YYYY')
                : 'N/A'}
            </span>
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">No of Interests</span>
            <span className="text-sm font-medium text-[#24A148]">
              {proposals?.detailModel?.interest || 'N/A'}
            </span>
          </div>
        </div>

        <div className="mb-4 flex border-b border-[#F5F5F5]">
          {['All Bids', 'Requests for proposal (RFP)', 'Request to View'].map((tab) => (
            <button
              key={tab}
              className={`border-b-2 px-4 pb-4 text-sm text-[#101828] ${
                activeTab === tab ? 'border-primary font-semibold' : 'border-transparent'
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {activeTab === 'All Bids' ? (
          <AllBidsComponent serviceInformationId={serviceInformationId as string} />
        ) : (
          <RequestViewProposalComponent
            supplierDetails={proposals?.supplierDetails || []}
            handleAllowAccessOrProposal={handleAllowAccessOrProposal}
            handleDenyAccessOrProposal={handleDenyAccessOrProposal}
          />
        )}
      </div>
    </div>
  );
};

export default ProcurementProposalPage;

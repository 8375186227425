import React from 'react';
import AppButton from '../../../components/AppButton/AppButton';
import Navbar from '../../../layouts/NavBar/Navbar';
import { useNavigate } from 'react-router-dom';

const SignupOptions = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="flex h-[100vh] flex-col items-center bg-gray-50 pb-8">
        <div className="mb-8 mt-8 text-lg font-semibold">
          <h2>How do you want to register</h2>
        </div>
        <div
          className="mb-4 w-[40%] cursor-pointer rounded-lg bg-white px-8 py-6 shadow-sm"
          onClick={() => navigate('/register', { state: { currentStep: 1 } })}
        >
          <h2 className="mb-3 text-lg font-semibold">As a Supplier</h2>
          <p className="text-gray-500">
            I want to offer my products and services and find suitable tender opportunities.
          </p>
        </div>
        <div
          className="mb-8 w-[40%] cursor-pointer rounded-lg bg-white px-8 py-6 shadow-sm"
          onClick={() => navigate('/register', { state: { currentStep: 2 } })}
        >
          <h2 className="mb-3 text-lg font-semibold">As an Operator or other Users</h2>
          <p className="text-gray-500">
            I want to find and engage suppliers for various product and services.
          </p>
        </div>
        {/* <div className="flex w-[40%] justify-end">
          <AppButton
            title={'Proceed'}
            onClick={function (): void {
              alert('success');
            }}
            className="rounded-lg bg-primary px-8 py-3 text-sm font-medium"
          />
        </div> */}
      </div>
    </>
  );
};

export default SignupOptions;

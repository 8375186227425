import React, { useEffect, useState } from 'react';
import CustomTable, { Column, IPageRequest } from '../../components/CustomTable';
import { useNavigate } from 'react-router-dom';
import { getSuppliers } from '../../services/supplierService';
import { useTranslation } from 'react-i18next';
import { getAllProcurements } from '../../services/procurementService';
import { ProcurementProject } from '../../types/procurement-types';

const ProcurementRequests: React.FC = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [activeStatus, setActiveStatus] = useState('New');
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [procurements, setProcurements] = useState<ProcurementProject[]>([]);

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const statusMap = {
    Approved: 3,
    Processing: 1,
    New: 0,
    Rejected: 2,
    Draft: 4,
  };

  const navigate = useNavigate();

  const columns: Column[] = [
    {
      title: t('projectTitle', 'Project Title'),
      dataKey: 'title',
      type: 'string',
    },
    {
      title: t('projectDescription', 'Project Description'),
      dataKey: 'description',
      type: 'string',
    },
    {
      title: t('bidRequirement', 'Bid Requirements'),
      dataKey: 'bidRequirement',
      type: 'string',
    },
    // {
    //   title: t('prerequisites', 'Prerequisites'),
    //   dataKey: 'prerequisites',
    //   type: 'string',
    // },
    {
      title: t('status', 'Status'),
      dataKey: 'status',
      type: 'procurementWorkflowStatus',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions', // This key is used but not directly needed for rendering
      type: 'action',
      actions: [
        {
          label: t('view', 'View'),
          className: '',
          action: (item: ProcurementProject) => handleView(item),
          status: true,
        },
      ],
    },
  ];

  const handleView = (item: ProcurementProject) => {
    navigate(`/procurement/edit/${item.serviceInformationId}`, {
      state: {
        isOperator: false,
      },
    });
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    // You can also add your search logic here
  };

  const fetchProcurements = async () => {
    const response = await getAllProcurements(statusMap, activeStatus);
    if (response) {
      setProcurements(response.data);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchProcurements();
  }, [currentPage, itemsPerPage]);

  return (
    <div>
      <CustomTable
        data={procurements}
        columns={columns}
        pageCount={pageCount} // Pass the total page count
        showPagination={true} // Control whether to show pagination
        showSearch={true} // Control whether to show the search box
        onSearch={handleSearch} // Pass the search handler
        showAddButton={false}
        showTitle={true}
        title={t('procurementRequests', 'Procurement Requests')}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />
    </div>
  );
};

export default ProcurementRequests;

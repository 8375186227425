import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { ILookup } from '../../../types/interfaces';
import {
  createLang,
  createLookup,
  getLangKeys,
  getSupportedLanguages,
  updateLang,
  updateLookup,
} from '../../../services/utilityServices';

export interface OptionType {
  value: string;
  label: string;
}

interface LookupModalProps {
  onClose: () => void;
  isEditable: boolean;
  notification?: INotifyMgt | null;
  onSubmit: () => void;
}

export interface INotifyMgt {
  culture: string;
  key: string;
  languageId?: string;
  messageValue: string;
}

// Validation schema
const validationSchema = Yup.object().shape({
  culture: Yup.string().required('Culture is required'),
  key: Yup.string().required('Key is required'),
  messageValue: Yup.string().required('Message value is required'),
});

const NotifyMgtModal: React.FC<LookupModalProps> = ({
  onClose,
  isEditable,
  notification,
  onSubmit,
}) => {
  const [langKeyOptions, setLangKeyOptions] = useState<OptionType[]>([]);
  const [langCultureOptions, setLangCultureOptions] = useState<OptionType[]>([]);
  const [initialValues, setInitialValues] = useState<INotifyMgt>({
    culture: '',
    key: '',
    messageValue: '',
    languageId: '',
  });
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (values: INotifyMgt) => {
    try {
      const { languageId, ...createPayload } = values;
      if (languageId === '') {
        const payload = {
          ...createPayload,
          // lookupValue: values.lookupName,
        };

        const response = await createLang(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onSubmit();
        } else {
          toast.error(response?.errorMessage);
        }
      } else {
        const payload = {
          ...values,
        };
        const response = await updateLang(payload);
        if (response?.isSuccess) {
          toast.success(response?.data?.message);
          onSubmit();
        } else {
          toast.error(response?.errorMessage);
        }
      }

      onClose();
    } catch (error: any) {
      toast.error('Error saving role permissions');
    }
  };

  const fetchNotificationKeys = async () => {
    try {
      const response = await getLangKeys();
      if (response?.isSuccess) {
        if (response.data.length === 0) return;

        const options = response?.data.map((item: any) => ({
          value: item.key,
          label: item.label,
        }));
        setLangKeyOptions(options);
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error) {
      // console.error('Error fetching notification keys:', error);
    }
  };

  const fetchNotificationCulture = async () => {
    try {
      const response = await getSupportedLanguages();
      if (response) {
        const options = response.map((item: any) => ({
          value: item.culture,
          label: item.displayName.split(' (')[0].trim(),
        }));
        setLangCultureOptions(options);
      } else {
        toast.error('Failed to fetch supported languages');
      }
    } catch (error) {
      toast.error('Failed to fetch supported languages');
    }
  };

  useEffect(() => {
    fetchNotificationKeys();
    fetchNotificationCulture();
  }, []);

  useEffect(() => {
    if (notification) {
      setInitialValues(notification);
    }
  }, [notification, loading]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="w-full max-w-4xl overflow-y-auto rounded-2xl bg-white p-8 shadow-lg">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold">
            {notification ? 'Edit Notification' : 'Add Notification'}
          </h2>
          <button className="text-xl text-red-500" onClick={onClose}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/close.svg`} alt="close" />
          </button>
        </div>

        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col gap-y-6">
              <div className="mb-4 grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="key" className="mb-1 block">
                    Key<span className="text-red-500"> *</span>
                  </label>
                  <Select
                    isDisabled={!isEditable}
                    name="key"
                    options={langKeyOptions}
                    className="w-full rounded-lg text-sm"
                    onChange={(option: any) => setFieldValue('key', option?.value)}
                    value={langKeyOptions.find((option) => option.value === values.key)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: '44px',
                        borderRadius: '0.5rem',
                      }),
                    }}
                  />
                  <ErrorMessage name="key" component="span" className="text-sm text-red-500" />
                </div>

                <div>
                  <label htmlFor="culture" className="mb-1 block">
                    Culture
                  </label>
                  <Select
                    isDisabled={!isEditable}
                    name="culture"
                    options={langCultureOptions}
                    className="w-full rounded-lg text-sm"
                    onChange={(option: any) => setFieldValue('culture', option?.value)}
                    value={langCultureOptions.find((option) => option.value === values.culture)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: '44px',
                        borderRadius: '0.5rem',
                      }),
                    }}
                  />
                  <ErrorMessage name="culture" component="span" className="text-sm text-red-500" />
                </div>

                <div>
                  <label htmlFor="messageValue" className="mb-1 block">
                    Message Value<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    as="textarea"
                    disabled={!isEditable}
                    name="messageValue"
                    placeholder="Enter Message Value"
                    className="w-full rounded-lg border p-2 text-sm"
                    rows={4}
                  />
                  <ErrorMessage
                    name="messageValue"
                    component="span"
                    className="text-sm text-red-500"
                  />
                </div>
              </div>

              <div className="divider my-2 !bg-[#E5E5E5]"></div>

              <div className="mt-4 flex justify-end">
                <button type="submit" className="rounded-md bg-green-500 px-6 py-2 text-white">
                  {notification ? 'Update Notification' : 'Save Notification'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NotifyMgtModal;

import React, { useEffect, useState } from 'react';
import AddServices from './Services/AddServices';
import EditProducts from './Goods/EditProducts';
import OrderCompletedModal from '../../../components/OrderCompletedModal/OrderCompletedModal';
import MyCatalogueDeleteModal from './MyCatalogue/MyCatalogueDeleteModal';
import CustomTable, { Column, IPageRequest } from '../../../components/CustomTable';
import { toast } from 'react-toastify';
import {
  deleteGoods,
  getAllCategories,
  getGoodsList,
  setGoodsStatus,
} from '../../../services/catalogService';
import { ProductType, SortEnum } from '../../../types/enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { IProduct } from '../../../types/interfaces';

const Drafts: React.FC = () => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sortOrder, setSortOrder] = useState<SortEnum>(SortEnum.NEWEST);
  const [catalogueItems, setCatalogueItems] = useState<IProduct[]>([]);
  const [selectedCatalogueItem, setSelectedCatalogueItem] = useState<IProduct | null>(null);
  const [isAddProductsOpen, setIsAddProductsOpen] = useState(false);
  const [isAddServicesOpen, setIsAddServicesOpen] = useState(false);

  const { supplierId, setSupplierId } = useGlobalContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageRequest: IPageRequest = {
    pageNumber: currentPage + 1,
    pageSize: itemsPerPage,
  };

  const columns: Column[] = [
    { title: 'Product/Service', dataKey: 'productName', type: 'string' },
    { title: 'Description', dataKey: 'description', type: 'string' },
    { title: 'Category', path: 'productCategory.mainCategoryName', type: 'string' },
    { title: 'Sub-category', path: 'productCategory.categoryName', type: 'string' },
    { title: 'Location', dataKey: 'location', type: 'string' },
    { title: 'Status', dataKey: 'status', type: 'status' },
    {
      title: 'Action',
      dataKey: 'action',
      type: 'action',
      actions: [
        // {
        //   label: t('view', 'View'),
        //   className: '',
        //   action: (item: any) => handleViewProduct(item),
        //   status: true,
        // },
        {
          label: t('publish', 'Publish'),
          className: '',
          action: (item: any) => handleUnpublishCatalogueItem(item),
          status: true,
        },
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: any) => editCatalog(item),
          status: true,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: any) => handleDeleteModal(item),
          status: true,
        },
      ],
    },
  ];

  const editCatalog = (item: any) => {
    if (!item) return;
    setSelectedCatalogueItem(item);
    if (item.productType === ProductType.GOODS) {
      setIsAddProductsOpen(true);
    } else {
      setIsAddServicesOpen(true);
    }
  };

  const handleDeleteModal = (item: any) => {
    setIsDeleteModal(true);
    setSelectedCatalogueItem(item);
  };

  const handleViewProduct = (item: any) => {
    if (!item) return;
    if (item.productType === ProductType.GOODS) {
      navigate(`/goods/detail?Id=${item.productId}`, {
        state: {
          productType: ProductType.GOODS,
        },
      });
    }

    if (item.productType === ProductType.SERVICES) {
      navigate(`/services/detail?Id=${item.productId}`, {
        state: {
          productType: ProductType.SERVICES,
        },
      });
    }
  };

  const fetchProducts = async (filteredValues?: any) => {
    if (!supplierId) return setCatalogueItems([]);

    const fetchRequest = {
      supplierId,
      pageNumber: pageRequest.pageNumber,
      pageSize: pageRequest.pageSize,
      status: 'Draft',
      // sortOrder,
      // ...(selectedCategory && { categoryId: selectedCategory }),
      ...(filteredValues && { ...filteredValues }),
    };
    const response = await getGoodsList(fetchRequest);
    if (response) {
      const products = response.data;
      if (products.length > 0) {
        const params = { isParentCategory: true };
        const categoryResponse = await getAllCategories(params);
        if (categoryResponse?.isSuccess) {
          products.forEach((product: IProduct) => {
            const category = categoryResponse.data.find(
              (cat: any) => cat.categorId === product.productCategory.mainCategoryId
            );
            if (category) {
              product.productCategory.mainCategoryName = category.categoryName;
            }
          });
        }
      }

      setCatalogueItems(products);
      if (response?.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage, itemsPerPage, supplierId]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };
  const handleConfirmClick = (option: string | null) => {
    if (!option) return;
    if (option === 'delete') {
      handleDeleteCatalogueItem();
    } else {
      handleUnpublishCatalogueItem();
    }
  };

  const handleDeleteCatalogueItem = async () => {
    if (!selectedCatalogueItem) return;
    const response = await deleteGoods(selectedCatalogueItem.productId);
    if (response?.isSuccess) {
      setIsDeleteModal(false);
      setShowConfirmModal(true);
    }
  };

  const handleUnpublishCatalogueItem = async (item?: any) => {
    if (item) {
      setSelectedCatalogueItem(item);
    }
    if (!selectedCatalogueItem) return;
    const payload = {
      productId: selectedCatalogueItem.productId,
      status: 'Publish',
    };
    const response = await setGoodsStatus(payload);
    if (response?.isSuccess) {
      setIsDeleteModal(false);
      toast.success(response?.data?.message);
      fetchProducts();
    }
  };

  const handleSuccessfulDelete = () => {
    setShowConfirmModal(false);
    fetchProducts();
  };

  return (
    <div>
      <CustomTable
        data={catalogueItems}
        columns={columns}
        pageCount={pageCount} // Total page count for pagination
        showPagination={true} // Show pagination control
        showSearch={true} // Show search bar
        onSearch={handleSearch} // Search function
        showAddButton={false} // Add button (set to false here)
        showTitle={false}
        onPageChange={(selectedPage) => handlePageChange(selectedPage)}
      />
      {/* <button
        onClick={() => setIsDeleteModal(true)}
        className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
      >
        Open Modal
      </button> */}
      <MyCatalogueDeleteModal
        isOpen={isDeleteModal}
        secondOptionTitleText="No, publish instead"
        secondOptionDescriptionText="This action is temporary and reversible. Operators would be able to view."
        onClose={() => setIsDeleteModal(false)}
        handleConfirmClick={(option) => handleConfirmClick(option)}
      />

      {showConfirmModal && (
        <OrderCompletedModal
          orderNumber={''}
          orderText="Product/service removed completely"
          orderStatus="Deleted"
          appBtnOkay=""
          appBtnView=""
          onView={() => {}}
          onBack={handleSuccessfulDelete}
        />
      )}

      <EditProducts
        show={isAddProductsOpen}
        close={() => {
          setIsAddProductsOpen(false);
          setSelectedCatalogueItem(null);
        }}
        onProductAdded={() => {
          fetchProducts();
          setIsAddProductsOpen(false);
          setSelectedCatalogueItem(null);
        }}
        initialValues={selectedCatalogueItem}
      />
      <AddServices
        show={isAddServicesOpen}
        close={() => {
          setIsAddServicesOpen(false);
          setSelectedCatalogueItem(null);
        }}
        onProductAdded={() => {
          fetchProducts();
          setIsAddServicesOpen(false);
          setSelectedCatalogueItem(null);
        }}
      />
    </div>
  );
};

export default Drafts;

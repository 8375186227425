import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createContact, updateContact } from '../../../services/supplierService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  parsePhoneNumberFromString,
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  CountryCode,
} from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import { OperatorContactPayload } from '../../../types/interfaces';
import { useParams } from 'react-router-dom';
import { createOperatorContact, updateOperatorContact } from '../../../services/OperatorServices';

interface ModalContactFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: OperatorContactPayload | null;
  isEditable: boolean;
}

// Utility to handle splitting the fullName into parts
const splitFullName = (fullName: string) => {
  const [firstName = '', middleName = '', lastName = ''] = fullName.split(' ');
  return { firstName, middleName, lastName };
};

const ModalContactForm: React.FC<ModalContactFormProps> = ({
  isOpen,
  onClose,
  isEditable,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [selectedCountryCode, setSelectedCountryCode] = useState('US');
  const { id } = useParams();
  const operatorId = id;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    designation: Yup.string().required('Designation is required'),
    nationality: Yup.string().required('Nationality is required'),
    email: Yup.string()
      .required('Email is required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format'),
    phoneNumber: Yup.string()
      .required(t('required', 'Required'))
      .test('isValidPhoneNumber', t('invalidPhoneNumber', 'Invalid phone number'), (value) => {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value, selectedCountryCode as CountryCode); // Replace 'US' with your default country code
        if (!phoneNumber) {
          return false;
        }

        const isLengthValid = validatePhoneNumberLength(phoneNumber.number);

        return isLengthValid === undefined && isPossiblePhoneNumber(phoneNumber.number);
      }),
  });

  const formInitialValues = useMemo<OperatorContactPayload>(() => {
    if (initialValues) {
      // const { firstName, middleName, lastName } = splitFullName(initialValues.fullName);
      return {
        firstName: initialValues.firstName,
        middleName: initialValues.middleName || '',
        lastName: initialValues.lastName,
        designation: initialValues.designation || '',
        nationality: initialValues.nationality || '',
        phoneNumber: initialValues.phoneNumber || '',
        email: initialValues.email || '',
        organizationId: initialValues.organizationId || operatorId || '',
      };
    }
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      designation: '',
      nationality: '',
      phoneNumber: '',
      email: '',
      organizationId: operatorId || '',
    };
  }, [initialValues]);

  const handleSubmit = async (values: OperatorContactPayload) => {
    try {
      const payload: any = {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        designation: values.designation,
        nationality: values.nationality,
        phoneNumber: values.phoneNumber,
        email: values.email,
        organizationId: operatorId,
      };

      if (initialValues) {
        payload.contactId = initialValues.contactId;
        const response = await updateOperatorContact(payload);
        if (response.isSuccess) {
          toast.success(t('contactUpdatedSuccessfully', 'Contact updated successfully'));
          onClose();
        }
      } else {
        const response = await createOperatorContact(payload);
        if (response.isSuccess) {
          toast.success(t('contactCreatedSuccessfully', 'Contact created successfully'));
          onClose();
        }
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (initialValues) {
      const parsedNumber = parsePhoneNumberFromString(`+${initialValues.phoneNumber}`);
      setSelectedCountryCode(parsedNumber?.country || 'US');
    }
  }, []);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
          <div className="max-w-5xl rounded-2xl bg-white p-8 shadow-lg">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                {t('addPrimaryContact', 'Add Primary Contact')}
              </h2>
              <button className="text-xl text-red-500" onClick={onClose}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
                  alt={t('close', 'close')}
                />
              </button>
            </div>

            {/* Divider */}
            <div className="divider my-6 !bg-[#E5E5E5]"></div>

            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className="flex flex-col gap-x-4 gap-y-6">
                  <div className="flex gap-4">
                    <div className="w-[33%]">
                      <label className="mb-1 block">
                        {t('firstName', 'First Name')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="firstName"
                        className="h-[33px] rounded-lg border p-2 text-sm"
                        placeholder={t('enterFirstName', 'Enter first name')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-sm text-red-500"
                      />
                    </div>

                    <div className="w-[33%]">
                      <label className="mb-1 block">{t('middleName', 'Middle Name')}</label>
                      <Field
                        name="middleName"
                        className="h-[33px] rounded-lg border p-2 text-sm"
                        placeholder={t('enterMiddleName', 'Enter middle name')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                    </div>

                    <div className="w-[33%]">
                      <label className="mb-1 block">
                        {t('lastName', 'Last Name')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="lastName"
                        className="h-[33px] rounded-lg border p-2 text-sm"
                        placeholder={t('enterLastName', 'Enter last name')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-sm text-red-500"
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex w-full">
                    <div className="w-full">
                      <label className="mb-1 block">
                        {t('designation', 'Designation')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="designation"
                        className="h-[33px] w-full rounded-lg border p-2 text-sm"
                        placeholder={t('enterDesignation', 'Enter designation')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                      <ErrorMessage
                        name="designation"
                        component="div"
                        className="text-sm text-red-500"
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex gap-4">
                    <div className="w-[33%]">
                      <label className="mb-1 block">
                        {t('nationality', 'Nationality')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="select"
                        name="nationality"
                        className="h-[33px] w-full rounded-lg border p-2 text-sm"
                        disabled={!isEditable} // Set disabled based on isEditable
                      >
                        <option value="">{t('selectNationality', 'Select nationality')}</option>
                        <option value="United States">{t('unitedStates', 'United States')}</option>
                        <option value="Nigeria">{t('nigeria', 'Nigeria')}</option>
                        <option value="United Kingdom">
                          {t('unitedKingdom', 'United Kingdom')}
                        </option>
                        {/* Add more nationalities */}
                      </Field>
                      <ErrorMessage
                        name="nationality"
                        component="div"
                        className="text-sm text-red-500"
                      />
                    </div>

                    <div className="w-[33%]">
                      <label className="mb-1 block">
                        {t('email', 'Email')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="h-[33px] rounded-lg border p-2 text-sm"
                        placeholder={t('enterEmail', 'Enter email')}
                        disabled={initialValues} // Set disabled based on isEditable
                      />
                      <ErrorMessage name="email" component="div" className="text-sm text-red-500" />
                    </div>

                    <div className="w-[33%]">
                      <label className="mb-1 block">
                        {t('phoneNumber', 'Phone Number')}
                        <span className="text-red-500">*</span>
                      </label>
                      <PhoneInput
                        country={'us'}
                        disabled={!isEditable}
                        value={values.phoneNumber}
                        onChange={(phone, country: any) => {
                          setFieldValue('phoneNumber', phone);
                          setSelectedCountryCode(country.countryCode.toUpperCase());
                        }}
                        inputClass="!w-full border rounded-lg !h-[33px]"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-sm text-red-500"
                      />
                    </div>
                  </div>

                  {/* Divider */}
                  {isEditable && ( // Only show if editable
                    <div className="divider my-6 !bg-[#E5E5E5]"></div>
                  )}

                  {/* Save Button */}
                  <div className="mt-4 flex justify-end">
                    {isEditable && ( // Only show if editable
                      <button
                        type="submit"
                        className="h-12 rounded-lg bg-primary px-12 py-2 font-semibold text-black"
                        disabled={!isEditable || isSubmitting} // Disable when not editable or submitting
                      >
                        {initialValues ? t('update', 'Update') : t('save', 'Save')}
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContactForm;

// import React, { useEffect, useState } from 'react';

// import { MenuItem, menuItems } from './sidebarData';
// import './Sidebar.css';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useGlobalContext } from '../../../core/context/GlobalContext';

// const Sidebar: React.FC = () => {
//   const [selected, setSelected] = useState<number>(1); // Default selected to 'Home'
//   const navigate = useNavigate();
//   const { logout } = useGlobalContext();

//   const handleSelect = (id: number, link: string) => {
//     setSelected(id);
//     navigate(`${link}`);
//   };

//   const location = useLocation(); // Get the current route

//   // Sync the selected state with the current URL on component mount
//   useEffect(() => {
//     const currentPath = location.pathname;
//     const currentItem = menuItems.find((item) => item.link === currentPath);

//     if (currentItem) {
//       setSelected(currentItem.id); // Update selected based on the current route
//     }
//   }, [location.pathname]); // Re-run this effect whenever the path changes

//   return (
//     <div className="sidebar-container ">
//       <div className="sidebar-content">
//         <h2 className="mb-2">All ESSC modules</h2>
//         <ul className="menu-list">
//           {menuItems.map((item: MenuItem) => (
//             <li
//               key={item.id}
//               className={`menu-item ${selected === item.id ? 'selected' : ''}`}
//               onClick={() => handleSelect(item.id, item.link)}
//             >
//               <img src={item.icon} alt={item.name} />
//               {<span className=" text-[14px]">{item.name}</span>}
//             </li>
//           ))}
//         </ul>
//       </div>

//       <div className="sidebar-footer p-4">
//         <ul className="menu-list">
//           <li className="menu-item flex items-center space-x-2 cursor-pointer" onClick={logout}>
//             <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`} alt="Logout" className="w-7 h-7" />
//             <span className="text-2xl">Logout</span>
//           </li>
//        </ul>
//        <div className="border-t my-4"></div>
//        <p className="text-xs text-gray-500">&copy; 2024 Your Company. All rights reserved.</p>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

import React, { useEffect, useState } from 'react';
import { MenuItem, menuItems } from './sidebarData';
import './Sidebar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserProfile, myMenu } from '../../services/AccountServices';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'; // Icons for collapsible menus
import { toast } from 'react-toastify';
import { getUserRoleFromLocalStorage } from '../../utils/helper';
import { FaArrowRight, FaChevronDown } from 'react-icons/fa';
import { t } from 'i18next';
import { Profile } from '../../types/interfaces';

const Sidebar: React.FC = () => {
  const [selected, setSelected] = useState<number>(1);
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItem[]>([]);
  const [adminUser] = useState<boolean>(false); // assuming admin for now
  const [expandedItems, setExpandedItems] = useState<number[]>([]); // Track expanded/collapsed menu items
  const [userProfile, setUserProfile] = useState<Profile | null>(null);

  const customerEmail = localStorage.getItem('email') || '';
  const customerFullname = localStorage.getItem('fullName') || '';
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useGlobalContext();

  const handleSelect = (id: number, link: string) => {
    setSelected(id);
    navigate(`${link}`);
  };

  const toggleExpand = (id: number) => {
    setExpandedItems((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const response = await getUserProfile();
      if (response?.isSuccess) {
        setUserProfile(response.data);
      } else {
        toast.error(response?.errorMessage);
      }
    };

    fetchUserProfile();
  }, []);

  const findMenuItemByLink = (
    items: MenuItem[],
    link: string,
    parent: MenuItem | null = null
  ): { item: MenuItem | undefined; parent: MenuItem | null } => {
    for (const item of items) {
      if (item.link === link) {
        return { item, parent };
      }
      if (item.children) {
        const foundChild = findMenuItemByLink(item.children, link, item);
        if (foundChild.item) {
          return foundChild;
        }
      }
    }
    return { item: undefined, parent: null };
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const { item: currentItem, parent: parentItem } = findMenuItemByLink(menuItems, currentPath);

    if (currentItem) {
      setSelected(currentItem.id);
      if (parentItem) {
        setExpandedItems((prev) => [...prev, parentItem.id]);
      }
    }
  }, [location.pathname]);

  const mapMenuItems = (menus: any[]) => {
    const menuMap: { [key: string]: MenuItem } = {};
    const rootMenus: MenuItem[] = [];

    // Create a mapping of route names to icons and ids from the static menu
    const iconMap: { [key: string]: string } = {};
    const idMap: { [key: string]: number } = {};
    menuItems.forEach((item) => {
      iconMap[item.link] = item.icon;
      idMap[item.link] = item.id;
      if (item.children) {
        item.children.forEach((child) => {
          iconMap[child.link] = child.icon;
          idMap[child.link] = child.id;
        });
      }
    });

    menus.forEach((menu) => {
      const menuItem: MenuItem = {
        id: idMap[`/${menu.menuUrl}`] || menu.menuId, // Use static menu id if available
        name: menu.menuName,
        link: `/${menu.menuUrl}`,
        icon: iconMap[`/${menu.menuUrl}`] || '',
        children: [],
      };

      menuMap[menu.menuId] = menuItem;

      if (menu.parentMenu) {
        const parentMenu = menuMap[menu.parentMenu.menuId];
        if (parentMenu) {
          if (parentMenu.children) {
            parentMenu.children.push(menuItem);
          } else {
            parentMenu.children = [menuItem];
          }
        } else {
          menuMap[menu.parentMenu.menuId] = {
            id: idMap[`/${menu.parentMenu.menuUrl}`] || menu.parentMenu.menuId, // Use static menu id if available
            name: menu.parentMenu.menuName,
            link: `/${menu.parentMenu.menuUrl}`,
            icon: iconMap[`/${menu.parentMenu.menuUrl}`] || '',
            children: [menuItem],
          };
        }
      } else {
        rootMenus.push(menuItem);
      }
    });

    // Create a mapping of static menu links to their indices
    const staticMenuOrder: { [key: string]: number } = {};
    menuItems.forEach((item, index) => {
      staticMenuOrder[item.link] = index;
      if (item.children) {
        item.children.forEach((child, childIndex) => {
          staticMenuOrder[child.link] = index + childIndex / 100; // Ensure children are ordered within their parent
        });
      }
    });

    // Sort the rootMenus based on the static menu order
    rootMenus.sort((a, b) => (staticMenuOrder[a.link] || 0) - (staticMenuOrder[b.link] || 0));

    // Sort the children of each root menu item
    rootMenus.forEach((menuItem) => {
      if (menuItem.children && menuItem.children.length > 0) {
        menuItem.children.sort(
          (a, b) => (staticMenuOrder[a.link] || 0) - (staticMenuOrder[b.link] || 0)
        );
      }
    });

    return rootMenus;
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        let updatedMenuItems = [];
        if (adminUser) {
          updatedMenuItems = menuItems;
        } else {
          const response = await myMenu();
          if (response.isSuccess) {
            updatedMenuItems = mapMenuItems(response.data);
          } else {
            const userRole = getUserRoleFromLocalStorage();
            if (userRole === 'UnverifiedSupplier') {
              updatedMenuItems = menuItems.filter(
                (item) => item.id === 1 || item.id === 2 || item.id === 10 || item.id === 17
              );
            } else {
              updatedMenuItems = menuItems.filter((item) => item.id === 1); // Set to Home menu
            }
          }
        }

        setFilteredMenuItems([
          ...updatedMenuItems,
          {
            id: 100,
            name: 'Contracts',
            link: '/contracts',
            icon: `${process.env.PUBLIC_URL}/assets/icons/outline/brush.svg`,
          },
        ]);
      } catch (error) {
        console.error('Error fetching menu data:', error);
        toast.error('Error fetching menu data:');
      }
    };

    fetchMenuData();
  }, [adminUser]);

  const renderMenuItems = (items: MenuItem[]) => {
    return items.map((item) => {
      const isExpanded = expandedItems.includes(item.id);
      const hasChildren = item.children && item.children.length > 0;

      return (
        <li
          key={item.id}
          className={`menu-item ${!hasChildren && selected === item.id ? 'selected' : ''}`}
          onClick={() => !hasChildren && handleSelect(item.id, item.link)}
        >
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={(e) => {
              toggleExpand(item.id);
            }}
          >
            <div className="flex items-center space-x-2">
              <img src={item.icon} alt={item.name} className="h-6 w-6" />
              <span className="text-sm">{item.name}</span>
            </div>
            {hasChildren && (
              <button className="focus:outline-none">
                {isExpanded ? (
                  <ChevronDownIcon className="h-7 w-7 text-gray-500" />
                ) : (
                  <ChevronRightIcon className="h-7 w-7 text-gray-500" />
                )}
              </button>
            )}
          </div>
          {hasChildren && isExpanded && (
            <ul
              className={`menu-item mt-4 space-y-1 pl-8 ${selected === item.id ? 'selected' : ''}`}
            >
              {item.children && renderMenuItems(item.children)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-content">
        <div className="mt-5 flex items-center space-x-2 pb-4">
          <img
            className="nav-logo w-12 lg:w-16"
            src={`${process.env.PUBLIC_URL}/assets/images/navbar/AnpLogo.png`}
            alt={t('anpLogo', 'ANP Logo')}
          />
          <h6 className="logo-text text-[10px] font-light sm:text-[10px] sm:font-light md:text-[10px] md:font-semibold lg:text-[14px] lg:font-bold">
            {t('enterpriseSupportServiceCenter', 'Enterprise Support Service Center')}
          </h6>
        </div>

        <div className="mb-4 border-t"></div>
        <div className="flex gap-3">
          <div className="relative">
            <div className="rounded-full bg-gray-300 px-5 py-3 text-3xl font-semibold">
              {customerFullname.charAt(0).toUpperCase()}
            </div>
            <div className="absolute right-0 top-0 h-5 w-5 rounded-full bg-green-500"></div>
          </div>
          <div>
            <div>
              <p className="mt-1 text-base font-semibold">{customerFullname}</p>
            </div>
            <div className="flex items-center gap-2">
              {/* <p className="text-sm text-gray-500">@{customerEmail.split('@')[0]}</p> */}
              {userProfile?.supplierVerify.toLowerCase() === 'true' ? (
                <p className="rounded-full bg-green-50 px-2 text-sm text-green-500">Verified</p>
              ) : (
                <p className="rounded-full bg-gray-50 px-2 text-sm text-gray-500">Unverified</p>
              )}
            </div>
          </div>
          {/* <div className="flex text-gray-500 items-center ml-auto">
            <FaChevronDown />
          </div> */}
        </div>
        <div className="my-4 border-t"></div>
        <ul className="menu-list space-y-1">{renderMenuItems(filteredMenuItems)}</ul>
      </div>

      <div className="sidebar-footer p-4">
        <ul className="menu-list">
          <li className="menu-item flex cursor-pointer items-center space-x-2" onClick={logout}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`}
              alt="Logout"
              className="h-6 w-6"
            />
            <span className="text-sm">Logout</span>
          </li>
        </ul>
        <div className="my-4 border-t"></div>
        <p className="text-xs text-gray-500">&copy; 2024 Your Company. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Sidebar;

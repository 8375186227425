import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createAProcurement,
  editAProcurement,
  getAProcurement,
  getBusinessTypes,
  getIndustries,
} from '../../services/procurementService';

import FormikInput from '../../components/CustomTextInput/CustomTextInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikTextarea from '../../components/CustomTextArea/CustomTextArea';
import FormikSelect from '../../components/CustomDropdownSelect/CustomDropdownSelect';
import FormikDateInput from '../../components/CustomDatePicker/CustomDatePicker';
import { ProcurementDataProps } from '../../types/procurement-types';
import { toast } from 'react-toastify';
import { ProcurementStatus } from '../../types/enums';

interface FormValues {
  procurementProjectId?: string;
  status?: number;
  title?: string;
  industryId?: string;
  businessType?: string;
  bidStartDate?: string | Date;
  bidEndDate?: string | Date;
  description?: string;
  bidRequirement?: string;
  prerequisite?: string;
  additionalInformation?: string;
}

interface Industry {
  industryId: string;
  industryName: string;
}

interface LookupType {
  lookupId: string;
  lookupName: string;
  lookupValue: string;
}

interface ServiceInformationProps {
  goToNextTab: () => void;
  serviceInformationId: string | null;
  setServiceInformationId: React.Dispatch<React.SetStateAction<string | null>>;
}

const ServiceInformation: React.FC<ServiceInformationProps> = ({
  goToNextTab,
  serviceInformationId,
  setServiceInformationId,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [saveAndContinue, setSaveAndContinue] = useState(false);

  // Fetch industries using react-query
  const { data: industries, error: industriesError } = useQuery<Industry[]>({
    queryKey: ['industries'],
    queryFn: async () => {
      const response = await getIndustries();
      return response.data;
    },
    staleTime: 3 * 60 * 1000, // Cache data for 3 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
  });

  // Fetch business types using react-query
  const { data: businessTypes, error: businessTypesError } = useQuery<LookupType[]>({
    queryKey: ['businessTypes'],
    queryFn: async () => {
      const response = await getBusinessTypes();
      return response.data;
    },
    staleTime: 3 * 60 * 1000, // Cache data for 3 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
  });

  // Fetch procurement project data if serviceInformationId exists
  const { data: procurementProjectData, error: procurementError } = useQuery<ProcurementDataProps>({
    queryKey: ['procurementProject', serviceInformationId],
    queryFn: async () => {
      const response = await getAProcurement(serviceInformationId!);
      return response.data;
    },
    enabled: !!serviceInformationId,
    staleTime: 3 * 60 * 1000, // Cache data for 3 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
  });

  // Handle errors locally
  React.useEffect(() => {
    if (industriesError) {
      toast.error('Error fetching industries');
    }
    if (businessTypesError) {
      toast.error('Error fetching business types');
    }
    if (procurementError) {
      toast.error('Error fetching procurement project');
    }
  }, [industriesError, businessTypesError, procurementError]);

  // Mutation for creating or editing a procurement
  const { mutate: saveProcurement, isPending: isSaving } = useMutation({
    mutationFn: (values: FormValues) =>
      serviceInformationId ? editAProcurement(values) : createAProcurement(values),
    onSuccess: (response) => {
      toast.success(response.data.message);
      setServiceInformationId(response.data.id);
      queryClient.invalidateQueries({ queryKey: ['procurementProject'] });

      if (saveAndContinue) {
        goToNextTab();
      } else {
        navigate(-1);
      }
    },
    onError: () => {
      toast.error('An error occurred while saving the procurement');
    },
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Procurement Title is required'),
    industryId: Yup.string().required('Industry is required'),
    businessType: Yup.string().required('Business type is required'),
    bidStartDate: Yup.date()
      .required('Bidding start date is required')
      .min(new Date(), 'Bidding start date cannot be in the past'),
    bidEndDate: Yup.date()
      .required('Bidding end date is required')
      .min(Yup.ref('bidStartDate'), 'Bidding end date must be after the start date'),
    description: Yup.string().required('Description is required'),
    bidRequirement: Yup.string().required('Bid Requirement is required'),
    prerequisite: Yup.string().required('Prerequisites are required'),
    additionalInformation: Yup.string().required('Additional Information is required'),
  });

  const handleSubmit = async (values: FormValues) => {
    const payload = {
      ...values,
      ...(serviceInformationId && { serviceInformationId }),
      workReference: '',
      status: ProcurementStatus.DRAFT,
    };
    saveProcurement(payload);
  };

  return (
    <div>
      <div className="mb-8 border-b border-b-[#E5E5E5] pb-4">
        <h2 className="text-2xl font-semibold">Provide service details</h2>
      </div>

      <Formik<FormValues>
        initialValues={{
          title: procurementProjectData?.title || '',
          industryId: procurementProjectData?.industryId || '',
          businessType: procurementProjectData?.businessType || '',
          bidStartDate: procurementProjectData?.bidStartDate
            ? new Date(procurementProjectData?.bidStartDate)
            : '',
          bidEndDate: procurementProjectData?.bidEndDate
            ? new Date(procurementProjectData?.bidEndDate)
            : '',
          description: procurementProjectData?.description || '',
          bidRequirement: procurementProjectData?.bidRequirement || '',
          prerequisite: procurementProjectData?.prerequisite || '',
          additionalInformation: procurementProjectData?.additionalInformation || '',
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormikInput
              label="Title of Procurement"
              name="title"
              type="text"
              placeholder="Title of procurement plan. Easy to understand"
              isRequired
            />

            <FormikSelect
              label="Industry"
              name="industryId"
              options={
                industries?.map((industry) => ({
                  value: industry.industryId,
                  label: industry.industryName,
                })) || []
              }
              isRequired
            />

            <FormikSelect
              label="Which type of Supplier should bid"
              name="businessType"
              options={
                businessTypes?.map((businessType) => ({
                  value: businessType.lookupValue,
                  label: businessType.lookupName,
                })) || []
              }
              isRequired
            />

            <div className="flex w-full gap-x-4">
              <FormikDateInput label="Bidding start date" name="bidStartDate" isRequired />
              <FormikDateInput label="Bidding end date" name="bidEndDate" isRequired />
            </div>

            <FormikTextarea
              label="Description"
              name="description"
              placeholder="Describe the project"
              isRequired
            />

            <FormikTextarea
              label="Bid Requirement"
              name="bidRequirement"
              placeholder="Enter bidding criteria"
              isRequired
            />

            <FormikTextarea
              label="Prerequisites or Submission requirements"
              name="prerequisite"
              placeholder="What are the supplier requirements you are looking for?"
              isRequired
            />

            <FormikTextarea
              label="Additional Information"
              name="additionalInformation"
              placeholder="Note / Comment"
              isRequired
            />

            <div className="ml-auto mt-10 flex w-full items-center justify-end gap-x-[18px]">
              <button
                type="submit"
                className="min-w-[195px] rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0]"
                disabled={isSaving}
                onClick={() => setSaveAndContinue(false)}
              >
                Save and exit
              </button>
              <button
                type="submit"
                className="min-w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
                disabled={isSaving}
                onClick={() => setSaveAndContinue(true)}
              >
                Save & Continue
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ServiceInformation;

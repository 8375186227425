import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  CreateContractOffer,
  getExpressionBid,
  getTechincalInfoBid,
} from '../../services/procurementService';
import { TenderDocumentType } from '../../types/enums';

interface CoordinateDMSProps {
  value: number;
  type: 'Latitude' | 'Longitude';
}

function CoordinateDMS({ value, type }: CoordinateDMSProps) {
  if (value === undefined || value === null) return null; // Handle empty values

  const toDMS = (deg: number, isLatitude: boolean): string => {
    const absolute = Math.abs(deg);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    const direction = isLatitude ? (deg >= 0 ? 'N' : 'S') : deg >= 0 ? 'E' : 'W';

    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  };

  return (
    <span>
      {type}: {toDMS(value, type === 'Latitude')}
    </span>
  );
}

const TechnicalInfoProposalPage = () => {
  const navigate = useNavigate();

  const { tenderBidId, serviceInfoId } = useParams<{
    tenderBidId: string;
    serviceInfoId: string;
  }>();

  const { data: TechnicalInfoBidResponse } = useQuery({
    queryKey: ['bids-technicalInfo', tenderBidId],
    queryFn: async () => {
      try {
        const response = await getTechincalInfoBid(tenderBidId as string);
        console.log('response: ', response);
        if (response.isSuccess) return response?.data;
      } catch (error) {
        toast.error('Failed to technical information bid. Please try again later.');
        throw error;
      }
    },
    // Only enable the query if the activeTab is not 'All Bids'
    // enabled: activeTab !== 'All Bids',
    // staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const approveOrRejectMutation = useMutation({
    mutationFn: (status: 'Approved' | 'Denied') =>
      CreateContractOffer({
        tenderType: TenderDocumentType.EXPRESSION_OF_INTEREST,
        status: status,
        tenderBidId: tenderBidId as string,
      }),
    onSuccess: (response) => {
      if (response.isSuccess) {
        toast.success(response.message);
        // navigate(`/procurement/proposals/${serviceInfoId}/bid/${tenderBidId}/expressionOfInterest`);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error('Unable to perform action, please try again later');
    },
  });

  console.log('TechnicalInfoBidResponse: ', TechnicalInfoBidResponse);

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Technical Evaluation</h1>

      <div className="2xl:w-11/12">
        <div className="mb-2.5 mt-8 flex flex-col flex-wrap justify-between gap-x-7 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12 xl:flex-row xl:items-center">
          <div className="flex w-full flex-wrap justify-between gap-3">
            <div className="flex items-center gap-x-6">
              <img
                // src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
                src="https://placehold.co/400"
                alt="vendor images"
                className="h-[58px] w-[58px] rounded-full"
              />

              <div className="flex flex-col items-start gap-y-2">
                <p className="overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-[#101828] underline">
                  Green Earth Consultant Ltd
                </p>

                <span className="rounded-[30px] bg-[#42BE65] px-2.5 py-1 text-[10px] font-medium text-white">
                  Verified Operator
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Tender Opportunity</span>
              <span className="text-sm font-medium text-[#525252]">
                Pipeline Inspection and Maintenance
              </span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Type</span>
              <span className="text-sm font-medium text-[#525252]">Joint Venture</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Submited</span>
              <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
            </div>
          </div>

          <button className="ml-auto flex h-12 w-[140px] items-center justify-center rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
            View Details
          </button>
        </div>

        <div className="my-4 rounded-lg bg-[#24A148] px-5">
          <p className="text-xl font-semibold text-white">Stage 1 of 3</p>
        </div>
        {/* {TechnicalInfoBidResponse ? ( */}
        {
          // TechnicalInfoBidResponse ? (
          //   <>
          //     <div className="mb-4 rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          //       <h3 className="mb-[34px] text-xl font-medium text-[#101828]">Basic information</h3>

          //       <div className="mx-auto grid w-3/4 grid-cols-2 gap-x-[10%] gap-y-4">
          //         <div className="flex flex-col gap-y-4">
          //           <span className="text-sm font-medium text-[#8D8D8D]">Address</span>
          //           <span className="pb-2.5 text-sm font-medium text-[#101828]">
          //             {TechnicalInfoBidResponse?.address}
          //           </span>
          //         </div>

          //         <div className="flex flex-col gap-y-4">
          //           <span className="text-sm font-medium text-[#8D8D8D]">Location</span>
          //           <span className="pb-2.5 text-sm font-medium text-[#101828]">
          //             {TechnicalInfoBidResponse?.location}
          //           </span>
          //         </div>

          //         <div className="flex flex-col gap-y-4">
          //           <span className="text-sm font-medium text-[#8D8D8D]">State</span>
          //           <span className="pb-2.5 text-sm font-medium text-[#101828]">
          //             {TechnicalInfoBidResponse?.state}
          //           </span>
          //         </div>

          //         <div className="flex flex-col gap-y-4">
          //           <span className="text-sm font-medium text-[#8D8D8D]">Coordinates</span>
          //           <span className="pb-2.5 text-sm font-medium text-[#101828]">
          //             {CoordinateDMS({
          //               value: TechnicalInfoBidResponse?.longitude as number,
          //               type: 'Longitude',
          //             })}
          //             ,{' '}
          //             {CoordinateDMS({
          //               value: TechnicalInfoBidResponse?.latitude as number,
          //               type: 'Latitude',
          //             })}
          //           </span>
          //         </div>
          //       </div>
          //     </div>

          //     <div className="mb-4 rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          //       <h3 className="mb-[34px] text-xl font-medium text-[#101828]">Project Timeline</h3>

          //       <div className="mx-auto grid w-3/4 grid-cols-2 gap-x-[10%] gap-y-4">
          //         <div className="flex flex-col gap-y-4">
          //           <span className="text-sm font-medium text-[#8D8D8D]">
          //             Estimated Cost of Project
          //           </span>
          //           <span className="pb-2.5 text-[32px] font-semibold text-[#24A148]">
          //             $
          //             {TechnicalInfoBidResponse?.estimatedCost
          //               ? new Intl.NumberFormat('en-US').format(
          //                   TechnicalInfoBidResponse?.estimatedCost
          //                 )
          //               : ''}
          //           </span>
          //         </div>
          //         <div></div>

          //         <div className="flex flex-col gap-y-4">
          //           <span className="text-sm font-medium text-[#8D8D8D]">Estimated Start Date</span>
          //           <div className="flex items-center gap-x-2 pb-2.5">
          //             <img
          //               src={`${process.env.PUBLIC_URL}/assets/icons/outline/calendar.svg`}
          //               alt="calendar icon"
          //             />
          //             <span className="text-sm font-medium text-[#101828]">
          //               {moment(TechnicalInfoBidResponse?.estimatedStartDate).format('DD/MM/YYYY')}
          //             </span>
          //           </div>
          //         </div>

          //         <div className="flex flex-col gap-y-4">
          //           <span className="text-sm font-medium text-[#8D8D8D]">Estimated Start Date</span>
          //           <div className="flex items-center gap-x-2 pb-2.5">
          //             <img
          //               src={`${process.env.PUBLIC_URL}/assets/icons/outline/calendar.svg`}
          //               alt="calendar icon"
          //             />
          //             <span className="text-sm font-medium text-[#101828]">
          //               {moment(TechnicalInfoBidResponse?.estimatedEndDate).format('DD/MM/YYYY')}
          //             </span>
          //           </div>
          //         </div>
          //       </div>
          //     </div>

          //     <div className="mb-[45px] rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          //       <h3 className="mb-5 text-lg font-medium text-black">Cover Letter</h3>

          //       <p
          //         dangerouslySetInnerHTML={{ __html: TechnicalInfoBidResponse?.purpose as string }}
          //       />
          //     </div>

          //     <div className="grid grid-cols-2 gap-x-[32px]">
          //       <button
          //         className="rounded-lg border border-[#FA4D56] px-5 py-3 text-sm font-semibold text-[#FA4D56]"
          //         onClick={() => approveOrRejectMutation.mutate('reject')}
          //         disabled={approveOrRejectMutation.isPending}
          //       >
          //         Reject
          //       </button>

          //       <button
          //         className="rounded-lg border border-[#24A148] bg-[#24A148] px-5 py-3 text-sm font-semibold text-white"
          //         onClick={() => approveOrRejectMutation.mutate('approve')}
          //         disabled={approveOrRejectMutation.isPending}
          //       >
          //         Approve for Next Stage
          //       </button>
          //     </div>
          //   </>
          // ) :
          <div className="flex min-h-[40vh] items-center justify-center border border-[#E5E5E5] bg-white">
            <div>
              <h3 className="text-center text-2xl font-semibold text-[#161616]">No record found</h3>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default TechnicalInfoProposalPage;

import React from 'react';
import { FaTimes } from 'react-icons/fa';

interface ModalProps {
  onClose: () => void;
}

const CompanySuccessModal: React.FC<ModalProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Modal Container */}
      <div className="relative w-full max-w-sm rounded-2xl bg-white p-6 shadow-lg">
        <button
          className="absolute right-3 top-3 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
          onClick={onClose}
        >
          <FaTimes size={15} />
        </button>
        {/* Icon */}
        <div className="mb-4 flex justify-center">
          <div className="w-18 h-18 flex items-center justify-center rounded-full">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/outline/partyThumbsup.svg`}
              alt={'success'}
              className=" "
            />
          </div>
        </div>

        {/* Title */}
        <h2 className="mb-4 text-center text-2xl font-semibold text-green-500">
          Company ID Created
        </h2>

        {/* Order Number */}
        <p className="mb-6 text-center text-sm text-gray-600">
          Company ID for this user has been generated and sent to operators email
        </p>

        {/* Buttons */}
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="rounded-md bg-primary px-12 py-3 text-sm font-medium"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanySuccessModal;

import React, { useState } from 'react';
import { XIcon } from 'lucide-react';
import FormikTextarea from '../../components/CustomTextArea/CustomTextArea';
import FormikInput from '../../components/CustomTextInput/CustomTextInput';
import { Form, Formik } from 'formik';
import FormikSelect from '../../components/CustomDropdownSelect/CustomDropdownSelect';
import * as Yup from 'yup';
import {
  createTenderGoodsAndServices,
  deleteTenderGoodsAndServices,
  editTenderGoodsAndServices,
  listTenderGoodsAndServices,
} from '../../services/procurementService';
import { toast } from 'react-toastify';
import { TenderGoodsAndServices } from '../../types/procurement-types';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface GoodsAndServicesItem {
  itemName: string;
  description: string;
  quantity: string;
  estimatedCost: string;
  procurementMethod: string;
  warrantyDuration: string;
  warrantyType: string;
}

interface GoodsAndServicesProps {
  goToNextTab: () => void;
  goToPreviousTab: () => void;
  serviceInformationId: string | null;
}

const GoodsAndServices: React.FC<GoodsAndServicesProps> = ({
  serviceInformationId,
  goToNextTab,
  goToPreviousTab,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedGoodsAndServiceItem, setSelectedGoodsAndServiceItem] =
    useState<TenderGoodsAndServices | null>(null);

  // Fetch goods and services using react-query
  const { data: tenderGoodsServicesData = [] } = useQuery<TenderGoodsAndServices[]>({
    queryKey: ['tenderGoodsServices', serviceInformationId],
    queryFn: async () => {
      const response = await listTenderGoodsAndServices(serviceInformationId!);
      return response.data;
    },
    enabled: !!serviceInformationId, // Only fetch if serviceInformationId exists
    staleTime: 3 * 60 * 1000, // Cache data for 3 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
  });

  // Mutation for creating or editing goods and services
  const { mutate: saveGoodsAndServices, isPending: isSaving } = useMutation({
    mutationFn: (data: GoodsAndServicesItem) => {
      const payload = {
        ...data,
        ...(selectedGoodsAndServiceItem?.tenderGoodsServicesId && {
          tenderGoodsServicesId: selectedGoodsAndServiceItem.tenderGoodsServicesId,
        }),
        serviceInformationId,
      };

      return selectedGoodsAndServiceItem?.tenderGoodsServicesId
        ? editTenderGoodsAndServices(payload)
        : createTenderGoodsAndServices(payload);
    },
    onSuccess: () => {
      toast.success('Goods and Services saved!');
      queryClient.invalidateQueries({ queryKey: ['tenderGoodsServices'] }); // Refetch data
      handleCloseModal();
    },
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  // Mutation for deleting goods and services
  const { mutate: deleteGoodsAndServices } = useMutation({
    mutationFn: (tenderGoodsServicesId: string) =>
      deleteTenderGoodsAndServices(tenderGoodsServicesId),
    onSuccess: () => {
      toast.success('Goods and Services deleted!');
      queryClient.invalidateQueries({ queryKey: ['tenderGoodsServices'] }); // Refetch data
      setIsDeleteModalOpen(false);
    },
    onError: () => {
      toast.error('Unable to delete, something went wrong!');
    },
  });

  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setSelectedGoodsAndServiceItem(null); // Clear the selected item
  };

  const handleGoBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  const onConfirmDelete = () => {
    if (selectedGoodsAndServiceItem?.tenderGoodsServicesId) {
      deleteGoodsAndServices(selectedGoodsAndServiceItem.tenderGoodsServicesId);
    }
  };

  const GoodsAndServiceSchema = Yup.object().shape({
    itemName: Yup.string().required('Item name is required'),
    description: Yup.string().required('Description is required'),
    quantity: Yup.string().required('Quantity is required'),
    estimatedCost: Yup.string().required('Estimated cost is required'),
    procurementMethod: Yup.string().required('Procurement method is required'),
    warrantyDuration: Yup.string().required('Warranty duration is required'),
    warrantyType: Yup.string().required('Warranty type is required'),
  });

  return (
    <div className="mx-4 space-y-4">
      <div className="mb-8 flex items-center justify-between border-b border-b-[#E5E5E5] pb-4">
        <h2 className="text-2xl font-semibold">Goods and Service to be procured</h2>
        <button
          onClick={() => {
            setIsAddModalOpen(true);
            setSelectedGoodsAndServiceItem(null); // Reset selected item when adding
          }}
          className="rounded-lg border border-black bg-white px-3 py-1.5 font-medium text-[#101828]"
        >
          + Add
        </button>
      </div>

      <div className="rounded-md border border-[#E5E5E5]">
        <div className="grid grid-cols-[1fr_3fr_1fr] items-center border-b border-[#E5E5E5] p-3 pr-6">
          <span className="text-sm text-[#8D8D8D]">Item Name</span>
          <span className="text-sm text-[#8D8D8D]">Quantity (Units)</span>
        </div>

        {tenderGoodsServicesData?.map((item, index) => (
          <div
            key={item.tenderGoodsServicesId}
            className={`grid grid-cols-[1fr_3fr_1fr] items-center p-3 pr-6 ${tenderGoodsServicesData?.length - 1 !== index ? 'border-b border-[#E5E5E5]' : ''}`}
          >
            <span className="text-sm text-[#252C32]">{item.itemName}</span>
            <span className="text-sm text-[#252C32]">{item.quantity}</span>

            <div className="flex justify-end gap-x-3">
              <button className="border-b border-[#42BE65] text-sm font-semibold text-[#42BE65]">
                View
              </button>
              <button
                className="border-b border-[#101828] text-sm font-semibold text-[#101828]"
                onClick={() => {
                  setSelectedGoodsAndServiceItem(item);
                  setIsEditModalOpen(true); // Open edit modal
                }}
              >
                Edit
              </button>
              <button
                className="border-b border-[#FA4D56] text-sm font-semibold text-[#FA4D56]"
                onClick={() => {
                  setSelectedGoodsAndServiceItem(item);
                  setIsDeleteModalOpen(true); // Open delete modal
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="!mt-20 flex w-full flex-col-reverse justify-between gap-y-6 xl:flex-row xl:items-center">
        <button
          type="button"
          className="min-w-[195px] rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0]"
          disabled={isSaving}
          onClick={goToPreviousTab}
        >
          Back
        </button>

        <div className="flex items-center justify-between gap-x-[18px] xl:ml-auto">
          <button
            className="w-full rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0] xl:w-[unset] xl:min-w-[195px]"
            disabled={isSaving}
            onClick={handleGoBack}
          >
            Save and exit
          </button>
          <button
            className="w-full rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0] xl:w-[unset] xl:min-w-[195px]"
            disabled={isSaving}
            onClick={() => {
              toast.success('Goods and Services saved!');
              goToNextTab();
            }}
          >
            Save & Continue
          </button>
        </div>
      </div>

      {/* Modal */}
      {(isAddModalOpen || isEditModalOpen) && (
        <div className="fixed inset-0 z-50 !mt-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="max-h-[65vh] w-2/3 overflow-auto rounded-[30px] bg-white px-[65px] py-[55px] xl:max-h-none">
            <div className="mb-8 flex items-center justify-between">
              <h3 className="text-base font-medium text-black">
                {isEditModalOpen ? 'Edit Good or Service' : 'Add Good or Service'}
              </h3>

              <button className="rounded-full bg-[#F5F5F5] p-1" onClick={handleCloseModal}>
                <XIcon className="h-4 w-4 text-[#1C1B1F]" />
              </button>
            </div>

            <Formik<GoodsAndServicesItem>
              initialValues={{
                itemName: selectedGoodsAndServiceItem?.itemName ?? '',
                description: selectedGoodsAndServiceItem?.description ?? '',
                quantity: selectedGoodsAndServiceItem?.quantity?.toString() ?? '',
                estimatedCost: selectedGoodsAndServiceItem?.estimatedCost?.toString() ?? '',
                procurementMethod: selectedGoodsAndServiceItem?.procurementMethod ?? '',
                warrantyDuration: selectedGoodsAndServiceItem?.warrantyDuration?.toString() ?? '',
                warrantyType: selectedGoodsAndServiceItem?.warrantyType ?? '',
              }}
              validationSchema={GoodsAndServiceSchema}
              onSubmit={(values) => saveGoodsAndServices(values)}
            >
              {() => (
                <Form>
                  <FormikInput
                    label="Item name"
                    name="itemName"
                    type="text"
                    placeholder="Name of item"
                    isRequired
                  />

                  <FormikTextarea
                    label="Description"
                    name="description"
                    placeholder="Describe the item"
                    isRequired
                  />

                  <div className="grid gap-x-4 xl:grid-cols-3">
                    <FormikInput
                      label="Quantity"
                      name="quantity"
                      type="number"
                      placeholder="Quantity"
                      isRequired
                    />

                    <FormikInput
                      label="Estimated cost ($)"
                      name="estimatedCost"
                      type="text" // Use type="text" to allow commas
                      placeholder="Estimated cost"
                      isRequired
                      money // Enable money-specific behavior
                    />

                    <FormikSelect
                      label="Procurement method"
                      name="procurementMethod"
                      options={[
                        { label: 'Request for proposal', value: 'Request for proposal' },
                        { label: 'Tender', value: 'Tender' },
                      ]}
                      isRequired
                    />
                  </div>

                  <div className="grid items-end gap-x-4 xl:grid-cols-3">
                    <FormikInput
                      label="Warranty duration (Years)"
                      name="warrantyDuration"
                      type="number"
                      placeholder="Warranty duration"
                      isRequired
                    />

                    <FormikSelect
                      label="Warranty type"
                      name="warrantyType"
                      options={[
                        { label: 'Standard', value: 'Standard' },
                        { label: 'Extended', value: 'Extended' },
                      ]}
                      isRequired
                    />

                    <div className="mb-5 flex justify-end">
                      <button
                        type="submit"
                        className="w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
                        disabled={isSaving}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteConfirmationModal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center bg-[#1C1B1F] bg-opacity-50">
      <div className="mt-[20vh] w-full max-w-md rounded-[30px] bg-white p-8">
        <div className="mb-12 flex justify-end">
          <button
            className="rounded-full border-[1.5px] border-[#101828] p-0.5 hover:bg-gray-200"
            onClick={onClose}
          >
            <XIcon className="h-3 w-3 text-[#1C1B1F]" />
          </button>
        </div>

        <h2 className="mb-6 text-center text-2xl font-semibold text-[#101828]">
          Delete this Goods and Services?
        </h2>

        <button
          className="mx-auto flex w-1/2 items-center justify-center rounded-lg bg-[#FA4D56] px-5 py-3 text-base font-semibold text-white"
          onClick={onConfirm}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default GoodsAndServices;

import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { XIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import FormikInput from '../../components/CustomTextInput/CustomTextInput';
import FormikDateInput from '../../components/CustomDatePicker/CustomDatePicker';
import {
  createTenderDocument,
  deleteTenderDocument,
  editTenderDocument,
  listTenderDocuments,
  updateTenderStatus,
} from '../../services/procurementService';
import { ProcurementStatus, TenderDocumentType } from '../../types/enums';
import {
  TenderDocumentProps,
  TenderDocumentValues,
  StartEndDateValues,
} from './TenderDocumentTypes';
import { TenderDocumentValuesValidation, StartEndDateValidationSchema } from './ValidationSchema';
import { TenderDocument } from '../../types/procurement-types';

interface SpecifiedTenderDocument {
  tenderDocumentId?: string;
  documentName: string;
  assignPoint?: number;
}

const ExpressionOfInterest: React.FC<TenderDocumentProps> = ({
  serviceInformationId,
  goToNextTab,
  goToPreviousTab,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [pointsInputEnabled, setPointsInputEnabled] = useState(false);
  const [saveAndContinue, setSaveAndContinue] = useState(false);

  const [tenderDetailId, setTenderDetailId] = useState<string | null>(null);
  const [specifiedTenderDocuments, setSpecifiedTenderDocuments] = useState<
    SpecifiedTenderDocument[]
  >([]);

  // Fetch tender documents
  const { data: tenderDocuments = [] } = useQuery<TenderDocument[]>({
    queryKey: ['tenderDocuments', serviceInformationId],
    queryFn: async () => {
      const response = await listTenderDocuments(
        serviceInformationId as string,
        TenderDocumentType.EXPRESSION_OF_INTEREST
      );
      console.log('response: ', response);
      return response.data;
    },
    enabled: !!serviceInformationId,
    // select: (data) =>
    //   data.filter(
    //     (item) => item?.tenderDetail.tenderType === TenderDocumentType.EXPRESSION_OF_INTEREST
    //   ),
    // staleTime: 3 * 60 * 1000, // Cache data for 3 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
  });

  // Mutation for creating a tender document
  const createTenderDocumentMutation = useMutation({
    mutationFn: createTenderDocument,
    onSuccess: (response) => {
      toast.success(response.data.message);
      queryClient.invalidateQueries({ queryKey: ['tenderDocuments', serviceInformationId] });
      if (saveAndContinue) {
        goToNextTab();
      } else {
        navigate(-1);
      }
    },
    onError: (error) => {
      console.error('Something went wrong: ', error);
      toast.error('Something went wrong');
    },
  });

  // Mutation for editing a tender document
  const editTenderDocumentMutation = useMutation({
    mutationFn: editTenderDocument, // Replace with your actual edit API function
    onSuccess: (response) => {
      toast.success(response.data.message);
      queryClient.invalidateQueries({ queryKey: ['tenderDocuments', serviceInformationId] });
      if (saveAndContinue) {
        goToNextTab();
      } else {
        navigate(-1);
      }
    },
    onError: (error) => {
      console.error('Something went wrong: ', error);
      toast.error('Something went wrong');
    },
  });

  // Mutation for deleting a tender document
  const deleteTenderDocumentMutation = useMutation({
    mutationFn: deleteTenderDocument,
    onSuccess: (response) => {
      toast.success(response.data.message);
      queryClient.invalidateQueries({ queryKey: ['tenderDocuments', serviceInformationId] });
    },
    onError: (error) => {
      console.error('Something went wrong: ', error);
      toast.error('Something went wrong');
    },
  });

  // Mutation for updating tender status
  const updateTenderStatusMutation = useMutation({
    mutationFn: updateTenderStatus,
    onError: (error) => {
      console.error('Something went wrong: ', error);
      toast.error('Something went wrong');
    },
  });

  const handleSaveDoc = useCallback(
    async (data: TenderDocumentValues) => {
      const { documentName, assignPoint } = data;

      const payload: SpecifiedTenderDocument = {
        documentName,
        ...(pointsInputEnabled && { assignPoint: Number(assignPoint) }),
      };

      console.log('specifiedTenderDocuments: ', specifiedTenderDocuments);
      setSpecifiedTenderDocuments([...specifiedTenderDocuments, payload]);
      setIsAddModalOpen(false);
    },
    [pointsInputEnabled, specifiedTenderDocuments, serviceInformationId]
  );

  const handleSaveExpressionOfInterestDocument = async (data: StartEndDateValues) => {
    if (specifiedTenderDocuments.length === 0) {
      toast.error('Please add at least one document');
      return;
    }

    const payload = {
      serviceInformationId,
      startdate: data.startdate,
      endDate: data.endDate,
      tenderType: TenderDocumentType.EXPRESSION_OF_INTEREST,
      tenderDocuments: specifiedTenderDocuments,
    };

    if (tenderDetailId) {
      // If tenderDetailId exists, call the edit mutation
      editTenderDocumentMutation.mutate({
        tenderDetailId,
        ...payload,
      });
    } else {
      // If tenderDetailId does not exist, call the create mutation
      createTenderDocumentMutation.mutate(payload);
    }

    updateTenderStatusMutation.mutate({
      serviceInformationId: serviceInformationId as string,
      status: ProcurementStatus.DRAFT,
    });
  };

  const handleDeleteDocument = async (tenderDocumentId: string | undefined) => {
    // deleteTenderDocumentMutation.mutate(tenderDocumentId);

    if (!tenderDocumentId) {
      // If there's no tenderDocumentId, just remove it from the local state
      setSpecifiedTenderDocuments((prev) =>
        prev.filter((doc) => doc.tenderDocumentId !== tenderDocumentId)
      );
      return;
    }

    // If tenderDocumentId exists, make the API call to delete it
    deleteTenderDocumentMutation.mutate(tenderDocumentId, {
      onSuccess: () => {
        // Remove the document from the local state after successful deletion
        setSpecifiedTenderDocuments((prev) =>
          prev.filter((doc) => doc.tenderDocumentId !== tenderDocumentId)
        );
      },
      onError: (error) => {
        console.error('Failed to delete document: ', error);
        toast.error('Failed to delete document');
      },
    });
  };

  useEffect(() => {
    console.log('tenderDocuments: ', tenderDocuments);
    setTenderDetailId(tenderDocuments[0]?.tenderDetailId);
    setSpecifiedTenderDocuments(
      tenderDocuments.map((doc) => ({
        tenderDocumentId: doc.tenderDocumentId,
        documentName: doc.documentName,
        assignPoint: doc.assignPoint,
      }))
    );
  }, [tenderDocuments]);

  return (
    <div className="mx-4 space-y-4">
      <div className="mb-6 flex items-center justify-between border-b border-b-[#E5E5E5] pb-4">
        <h2 className="text-2xl font-semibold">Expression of Interest Criteria</h2>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="rounded-lg border border-black bg-white px-3 py-1.5 font-medium text-[#101828]"
        >
          + Add
        </button>
      </div>

      <h3 className="text-xl font-medium text-[#344054]">Requested Documents</h3>

      <div className="!mt-4 rounded-md border border-[#E5E5E5]">
        <div className="grid grid-cols-[2fr_3fr_1fr] items-center border-b border-[#E5E5E5] p-3 pr-6">
          <span className="text-sm text-[#8D8D8D]">Document Name</span>
          <span className="text-sm text-[#8D8D8D]">Points Assigned</span>
        </div>

        {specifiedTenderDocuments.map((doc, index) => (
          <div
            key={doc?.tenderDocumentId ? doc?.tenderDocumentId : index}
            className="grid grid-cols-[2fr_3fr_1fr] items-center border-b border-[#E5E5E5] p-3 pr-6"
          >
            <span className="text-sm text-[#252C32]">{doc.documentName}</span>
            <span className="text-sm text-[#252C32]">{doc.assignPoint ?? 'N/A'}</span>

            <div className="flex justify-end gap-x-3">
              <button
                className="border-b border-[#FA4D56] text-sm font-semibold text-[#FA4D56]"
                onClick={() => handleDeleteDocument(doc?.tenderDocumentId)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="my-4 border-b border-[#F5F5F5]"></div>

      <Formik<StartEndDateValues>
        initialValues={{
          startdate: tenderDocuments[0]?.tenderDetail.startDate || '',
          endDate: tenderDocuments[0]?.tenderDetail.endDate || '',
        }}
        enableReinitialize
        validationSchema={StartEndDateValidationSchema}
        onSubmit={handleSaveExpressionOfInterestDocument}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="flex w-full gap-x-4">
              <FormikDateInput label="Start date" name="startdate" isRequired />
              <FormikDateInput label="End date" name="endDate" isRequired />
            </div>

            <div className="!mt-20 flex w-full flex-col-reverse justify-between gap-y-6 xl:flex-row xl:items-center">
              <button
                type="button"
                className="min-w-[195px] rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0]"
                disabled={createTenderDocumentMutation.isPending}
                onClick={goToPreviousTab}
              >
                Back
              </button>

              <div className="ml-auto flex w-full flex-col items-center justify-end gap-[18px] sm:flex-row">
                <button
                  type="submit"
                  className="w-full rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0] xl:w-[unset] xl:min-w-[195px]"
                  disabled={createTenderDocumentMutation.isPending}
                  onClick={() => setSaveAndContinue(false)}
                >
                  Save and exit
                </button>
                <button
                  type="submit"
                  className="transition-colorer:bg-tion-300 w-full rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0] xl:w-[unset] xl:min-w-[195px]"
                  disabled={createTenderDocumentMutation.isPending}
                  onClick={() => setSaveAndContinue(true)}
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {/* Modal */}
      {isAddModalOpen && (
        <div className="fixed inset-0 z-50 !mt-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="max-h-[65vh] w-2/3 overflow-auto rounded-[30px] bg-white px-[65px] py-[55px] xl:max-h-none">
            <div className="mb-8 flex items-center justify-between">
              <h3 className="text-base font-medium text-black">Request documents</h3>

              <button
                className="rounded-full bg-[#F5F5F5] p-1"
                onClick={() => setIsAddModalOpen(false)}
              >
                <XIcon className="h-4 w-4 text-[#1C1B1F]" />
              </button>
            </div>

            <Formik<TenderDocumentValues>
              initialValues={{
                documentName: '',
                assignPoint: '',
              }}
              validationSchema={TenderDocumentValuesValidation}
              onSubmit={handleSaveDoc}
            >
              {({ setFieldValue }) => (
                <Form>
                  <FormikInput
                    label="Type of document"
                    name="documentName"
                    type="text"
                    placeholder="Licenses and Permits"
                    isRequired
                  />

                  <div className="flex justify-between">
                    <label className="custom-checkbox flex items-center">
                      <input
                        type="checkbox"
                        checked={pointsInputEnabled}
                        onChange={(e) => setPointsInputEnabled(e.target.checked)}
                      />
                      <span className="checkbox"></span>
                      <span className="label !text-sm !text-[#525252]">
                        Assign points to this requirement
                      </span>
                    </label>

                    <div className="flex items-center">
                      <FormikInput
                        name="assignPoint"
                        type="number"
                        placeholder="0"
                        inputClassName="mb-0 w-[70px]"
                        disabled={!pointsInputEnabled}
                        onChange={(e) => {
                          const value = Math.max(0, Math.min(100, Number(e.target.value)));
                          setFieldValue('assignPoint', value);
                        }}
                        isRequired
                      />
                      <span>/100</span>
                    </div>
                  </div>

                  <div className="mt-11 flex justify-end">
                    <button
                      type="submit"
                      className="w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
                      disabled={createTenderDocumentMutation.isPending}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpressionOfInterest;

export enum OnboardingStatus {
  NEW,
  PROCESSING,
  REJECTED,
  APPROVED,
  DRAFT,
}

export enum OnboardingStatus2 {
  NEW = 'New',
  PROCESSING = 'Processing',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  DRAFT = 'Draft',
}

export enum WorkflowType {
  SUPPLIER = 0,
  BID = 1,
  PROCUREMENT = 2,
  CONTRACT = 3,
  PROJECT = 4,
}

export enum WorkflowActionType {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Canceled',
  IN_PROGRESS = 'InProgress',
}

export enum ProductType {
  GOODS = 0,
  SERVICES = 1,
  SUPPLIERS = 2,
}

export enum SortEnum {
  NEWEST = 0,
  PRICE_LOW_HIGH = 1,
  PRICE_HIGH_LOW = 2,
  ASCENDING = 3,
  DESCENDING = 4,
}

export enum ProcurementStatus {
  NEW = 0,
  PROCESSING = 1,
  REJECTED = 2,
  APPROVED = 3,
  DRAFT = 4,
}

export enum TenderDocumentType {
  EXPRESSION_OF_INTEREST = 'ExpressionOfInterest',
  TECHNICAL_INFORMATION = ' TechnicalInformation',
  COMMERCIALS = 'Commercials',
}

export enum TenderStatus {
  ACTIVE = 'Active',
  PROCESSING = 'Processing',
  DENIED = 'Denied',
  NEW = 'New',
  APPROVED = 'Approved',
}

export enum StageStatus {
  'InProgress' = 'InProgress',
  'Completed' = 'Completed',
  //   'Approved' = 'Approved',
  'Rejected' = 'Rejected',
}

export enum TenderOpportunitiesSort {
  DENIED = 0,
  APPROVED = 1,
  BESTMATCH = 2,
  SAVED = 3,
}

export enum ProductStatus {
  DRAFT = 0,
  PUBLISH = 1,
}

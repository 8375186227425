import { Endpoint } from './endpoint/endpoint';
import axiosInstance from '../axiosInstance';
import { toast } from 'react-toastify';
import { RegisterFormValues } from '../types/interfaces';

interface RegisterData {
  userName: string;
  password: string;
  confirmPassword: string;
  email: string;
  phoneNumber: string;
}
interface LoginData {
  userName: string;
  password: string;
}
interface PageRequest {
  pageNumber: number;
  pageSize: number;
}

export interface OtpData {
  code: string;
  email: string;
}
export const Register = async (userData: RegisterFormValues) => {
  const url = Endpoint.account.register;

  try {
    const response = await axiosInstance.post(url, userData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (error: any) {
    if (error?.response) {
      toast.error(error?.response?.data?.errors[0]?.reason);
    }
  }
};

export const Login = async (loginData: LoginData) => {
  const url = Endpoint.account.login;

  try {
    const response = await axiosInstance.post(url, loginData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ConfirmEmail = async (otpData: OtpData) => {
  const url = Endpoint.account.confirmEmail;

  try {
    const response = await axiosInstance.post(url, otpData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ResendOtp = async (otpData: { email: string }) => {
  const url = Endpoint.account.resendOtp;

  try {
    const response = await axiosInstance.post(url, otpData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const GoogleAuth = async (tokenData: { token: string }) => {
  const url = Endpoint.account.googleAuth;
  try {
    const response = await axiosInstance.post(url, tokenData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ExternalOAuth = async (provider: string) => {
  const url = Endpoint.account.externalOAuth + provider;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const RefreshToken = async (data: { accessToken: string; refreshToken: string }) => {
  const url = Endpoint.account.refreshToken;
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ForgotPassword = async (data: { email: string }) => {
  const url = Endpoint.account.forgotPassword;
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ResetPassword = async (data: {
  newPassword: string;
  token: string;
  email: string;
}) => {
  const url = Endpoint.account.resetPassword;
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const ChangePassword = async (data: {
  newPassword: string;
  oldPassword: string;
  userId: string;
}) => {
  const url = Endpoint.account.changePassword;
  try {
    const response = await axiosInstance.put(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const getRoles = async (pageRequest?: PageRequest) => {
  const url = pageRequest
    ? `${Endpoint.account.role.get}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`
    : Endpoint.account.role.get;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getRole = async (roleId: string) => {
  const url = `${Endpoint.account.role.getOne(roleId)}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateRole = async (data: any) => {
  const url = `${Endpoint.account.role.update}`;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createRole = async (data: any) => {
  const url = `${Endpoint.account.role.create}`;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteRole = async (roleId: string) => {
  const url = `${Endpoint.account.role.delete}`;
  const body = { roleId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createMenu = async (data: any) => {
  const url = `${Endpoint.account.menu.create}`;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateMenu = async (data: any) => {
  const url = `${Endpoint.account.menu.update}`;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteMenu = async (menuId: string) => {
  const url = `${Endpoint.account.menu.delete}`;
  const body = { menuId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error)
  }
};

export const getAllMenu = async (pageRequest?: PageRequest) => {
  const url = pageRequest
    ? `${Endpoint.account.menu.getAll}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`
    : Endpoint.account.menu.getAll;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getMenu = async (menuId: string) => {
  const url = `${Endpoint.account.menu.get(menuId)}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const myMenu = async () => {
  const url = `${Endpoint.account.menu.mymenu}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const getPermissions = async () => {
  const url = `${Endpoint.account.permission.get}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const getUsers = async () => {
  const url = `${Endpoint.account.user.get}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const getStaff = async () => {
  const url = `${Endpoint.account.user.getStaff}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const getOneStaff = async (userId: string) => {
  const url = `${Endpoint.account.user.getOne(userId)}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createStaff = async (data: any) => {
  const url = `${Endpoint.account.user.create}`;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const updateStaff = async (data: any) => {
  const url = `${Endpoint.account.user.update}`;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const deleteStaff = async (employeeId: string) => {
  const url = `${Endpoint.account.user.delete}`;
  const body = { employeeId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    //console.log(error)
  }
};

export const getLookupType = async (lookupType: string) => {
  const url = `${Endpoint.account.lookup.getType(lookupType)}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createLookup = async (data: any) => {
  const url = `${Endpoint.account.lookup.create}`;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateLookup = async (data: any) => {
  const url = `${Endpoint.account.lookup.update}`;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteLookup = async (lookupId: string) => {
  const url = `${Endpoint.account.lookup.delete}`;
  const body = { lookupId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getUserProfile = async () => {
  const url = `${Endpoint.account.profile}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const accessibleModules = async () => {
  const url = `${Endpoint.account.accessibleModules}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

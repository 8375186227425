import { Form, Formik, FormikValues } from 'formik';

import FormikInput from '../../components/CustomTextInput/CustomTextInput';
import FormikDateInput from '../../components/CustomDatePicker/CustomDatePicker';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateExpressionOfInterestBid, getDocumentTypes } from '../../services/procurementService';
import { toast } from 'react-toastify';
import { ExpressionOfInterestValues } from '../../types/procurement-types';
import FormikWysiwyg from '../../components/CustomWysiwyg/CustomWysiwyg';
import { TenderDocumentType } from '../../types/enums';
import { useQuery } from '@tanstack/react-query';
import ProcurementDocumentList from '../../components/DocumentManagement/ProcurementDocumentList';
import { DocumentInterface } from '../../types/interfaces';

const ExpressionOfInterestPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const serviceInformationId = location.state?.serviceInformationId || null;
  const supplierId = localStorage.getItem('supplierId') || '';

  const [declaration, setDeclaration] = useState(false);
  const [geoLocation, setGeoLocation] = useState({ latitude: 0, longitude: 0 });
  const [documentTypeList, setDocumentTypeList] = useState<{ key: string; value: string }[]>([]);

  const [documents, setDocuments] = useState<DocumentInterface[]>([
    // {
    //   documentId: '1',
    //   documentName: 'Document 1',
    //   documentType: 'Type 1',
    //   uploadedBy: 'User 1',
    //   uploadDate: new Date().toISOString(),
    // },
    // {
    //   documentId: '2',
    //   documentName: 'Document 2',
    //   documentType: 'Type 2',
    //   uploadedBy: 'User 2',
    //   uploadDate: new Date().toISOString(),
    // },
  ]);

  // Fetch tender documents
  const { data: tenderDocuments = [], refetch } = useQuery({
    queryKey: ['tenderDocuments', serviceInformationId],
    queryFn: async () => {
      const response = await getDocumentTypes(
        serviceInformationId as string,
        TenderDocumentType.COMMERCIALS
      );
      console.log('response: ', response);
      if (response?.isSuccess) {
        setDocumentTypeList(
          response.data.map((lookup) => ({
            key: lookup.tenderDocumentId,
            value: lookup.documentName,
          }))
        );
      }
      return response.data;
    },
    enabled: !!serviceInformationId,
    // staleTime: 3 * 60 * 1000, // Cache data for 3 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeoLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
          toast.error('Failed to get location. Please enable location services.');
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    location: Yup.string().required('Location is required'),
    state: Yup.string().required('State is required'),
    latitude: Yup.number().typeError('Latitude must be a number').required('Latitude is required'),
    longitude: Yup.number()
      .typeError('Longitude must be a number')
      .required('Longitude is required'),
    estimatedCost: Yup.number()
      .typeError('Estimated cost must be a number')
      .required('Estimated cost is required')
      .positive('Estimated cost must be a positive number'),
    estimatedStartDate: Yup.date()
      .required('Estimated start date is required')
      .min(new Date(), 'Estimated start date cannot be in the past'),
    estimatedEndDate: Yup.date()
      .required('Estimated end date is required')
      .min(Yup.ref('estimatedStartDate'), 'Estimated end date must be after the start date'),
    purpose: Yup.string()
      .required('Cover letter is required')
      .min(50, 'Cover letter must be at least 50 characters'),
    // declaration: Yup.boolean()
    //   .oneOf([true], 'You must agree to the statutory declaration')
    //   .required('You must agree to the statutory declaration'),
  });

  const handleSubmit = async (values: ExpressionOfInterestValues) => {
    console.log('handleSubmit values: ', values);

    if (!declaration) {
      toast.error('You must agree to the statutory declaration');
      return;
    }

    const payload = {
      ...values,
      declaration,
      serviceInformationId,
      companyId: supplierId,
    };

    console.log('Expression of Interest Payload:', payload);

    try {
      const response = await CreateExpressionOfInterestBid(payload);
      console.log('Expression of Interest submitted:', response);

      if (response.isSuccess) {
        toast.success('Expression of Interest submitted successfully');

        setTimeout(() => {
          navigate(`/procurement/bids/${response.data.id}`, { replace: true });
        }, 1000);
      } else {
        toast.error('Failed to submit expression of interest. Please try again later.');
      }
    } catch (error) {
      console.error('Failed to submit expression of interest:', error);
      toast.error('Failed to submit expression of interest . Please try again later.');
    }
  };

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Expression of Interest</h1>

      <div className="2xl:w-11/12">
        <div className="mb-2.5 mt-8 flex flex-col justify-between gap-x-7 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12 xl:flex-row xl:items-center">
          <div className="grid w-full grid-cols-2 gap-3 xl:grid-cols-4">
            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Contract Name</span>
              <span className="text-sm font-medium text-[#525252]">
                Pipeline Inspection and Maintenance
              </span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Type</span>
              <span className="text-sm font-medium text-[#525252]">Joint Venture</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Date</span>
              <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Status</span>
              <span className="text-sm font-medium text-[#24A148]">Active</span>
            </div>
          </div>

          <button className="ml-auto flex h-12 w-[140px] items-center justify-center rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
            View Details
          </button>
        </div>

        <div className="my-9">
          {/* Background Bar */}
          <div className="relative h-[3px] w-full bg-[#E5E5E5]">
            {/* Completed Section 1 */}
            <div className="absolute -top-[20px] z-10 flex h-[38px] w-[38px] items-center justify-center rounded-full bg-[#42BE65] text-lg font-medium text-white">
              1
            </div>

            <div className="absolute left-0 top-0 h-full w-[50%] bg-[#42BE65]"></div>

            <div className="absolute right-0 top-0 h-full w-[50%] bg-[#42BE65]"></div>

            {/* Completed Section 2 */}
            {/* <div className="absolute top-[25%] h-[25%] w-full bg-[#42BE65]"></div> */}
          </div>
        </div>

        <div className="my-4 rounded-lg bg-[#24A148] px-5">
          <p className="text-xl font-semibold text-white">Stage 1 of 3</p>
        </div>

        <div className="rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          <h3 className="mb-[34px] text-xl font-medium text-[#101828]">
            Fill out the information below
          </h3>

          <Formik<ExpressionOfInterestValues>
            initialValues={{
              serviceInformationId: '',
              address: '',
              longitude: geoLocation.longitude,
              latitude: geoLocation.latitude,
              state: '',
              location: '',
              estimatedCost: 0,
              estimatedStartDate: '',
              estimatedEndDate: '',
              purpose: '',
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <h4 className="mb-[34px] text-base font-semibold text-[#101828]">Location</h4>

                <FormikInput
                  label="Address"
                  name="address"
                  type="text"
                  placeholder="Enter Address"
                  isRequired
                />

                <div className="flex w-full gap-x-4">
                  <FormikInput
                    label="Location"
                    name="location"
                    type="text"
                    placeholder="Location"
                    isRequired
                  />

                  <FormikInput
                    label="State"
                    name="state"
                    type="text"
                    placeholder="State"
                    isRequired
                  />
                </div>

                <h4 className="mb-[34px] text-base font-semibold text-[#101828]">Coordinates</h4>

                <div className="flex w-full gap-x-4">
                  <FormikInput
                    label="Latitude"
                    name="latitude"
                    type="number"
                    placeholder="0.00"
                    isRequired
                    disabled
                  />

                  <FormikInput
                    label="Longitude"
                    name="longitude"
                    type="number"
                    placeholder="0.00"
                    isRequired
                    disabled
                  />
                </div>

                <div className="w-1/3">
                  <FormikInput
                    label="Estimated Cost of Project*"
                    name="estimatedCost"
                    type="text"
                    placeholder="Enter Amount"
                    money={true}
                    isRequired
                  />
                </div>

                <div className="flex w-full gap-x-4">
                  <FormikDateInput
                    label="Estimated Start Date"
                    name="estimatedStartDate"
                    isRequired
                  />
                  <FormikDateInput label="Estimated End Date" name="estimatedEndDate" isRequired />
                </div>

                {/* <FormikTextarea
                  label="Cover Letter"
                  name="purpose"
                  placeholder="Pitch yourself"
                  isRequired
                /> */}

                <FormikWysiwyg
                  label="Cover Letter"
                  name="purpose"
                  placeholder="Pitch yourself"
                  isRequired
                />

                <div className="my-8">
                  <h5 className="mb-8 text-xl font-semibold text-[#101828]">
                    Statutory Declaration
                  </h5>

                  <label className="custom-checkbox flex items-center">
                    <input
                      type="checkbox"
                      checked={declaration} // Bind to Formik value
                      onChange={(e) => {
                        setDeclaration(e.target.checked); // Update Formik value
                      }}
                    />
                    <span className="checkbox"></span>
                    <span className="label !text-sm !text-[#525252]">
                      I, the applicant, hereby attest that the information provided in this
                      submission is accurate and true to the best of my knowledge.
                    </span>
                  </label>
                </div>

                <div className="ml-auto mt-10 flex w-full items-center justify-end gap-x-[18px]">
                  <button
                    type="submit"
                    className="min-w-[195px] rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0]"
                    // disabled={isLoading}
                    // onClick={() => setSaveAndContinue(false)} // Save and exit
                  >
                    Save & Exit
                  </button>
                  <button
                    type="submit"
                    className="min-w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
                    // disabled={isLoading}
                    // onClick={() => setSaveAndContinue(true)} // Save & Continue
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <ProcurementDocumentList
            entityId={supplierId}
            module="Procurement"
            subModule={TenderDocumentType.EXPRESSION_OF_INTEREST}
            documentTypeList={documentTypeList}
            documents={documents}
            fetchDocuments={refetch}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpressionOfInterestPage;

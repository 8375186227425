import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ServiceInformation from './ServiceInformation';
import GoodsAndServices from './GoodsAndServices';
import ExpressionOfInterest from './ExpressionOfInterest';
import TechnicalInformation from './TechnicalInformation';
import Commercials from './Commercials';
import { initiateWorkFlow, initiateWorkFlowApproval } from '../../services/procurementService';
import { toast } from 'react-toastify';
import ApplicationProgress from '../../components/ApplicationProgressBar';
import { WorkflowActionType } from '../../types/enums';

const CreateProcurementForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [serviceInformationId, setServiceInformationId] = useState<string | null>(null);

  const location = useLocation();
  const isOperator = location.state?.isOperator;
  const isApprover = location.state?.isApprover;
  const requestId = location.state?.requestId;
  const entityName = location.state?.entityName;
  const entityId = location.state?.entityId;

  const navigate = useNavigate();
  const { id: editProcurementId } = useParams<{ id: string }>();

  const goToTab = (direction: 'next' | 'previous') => {
    setCurrentStep((prevStep) => {
      if (direction === 'next') {
        return Math.min(prevStep + 1, 5); // Move to the next step, max step is 5
      } else {
        if (
          window.confirm('Are you sure you want to leave this tab? Unsaved changes will be lost.')
        ) {
          return Math.max(prevStep - 1, 1); // Move to the previous step, min step is 1
        }
        return prevStep; // Return the current step if not confirmed
      }
    });
  };
  useEffect(() => {
    if (editProcurementId) {
      setServiceInformationId(editProcurementId);
    }
  }, [editProcurementId]);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ServiceInformation
            goToNextTab={() => goToTab('next')}
            serviceInformationId={serviceInformationId}
            setServiceInformationId={setServiceInformationId}
          />
        );
      case 2:
        return (
          <GoodsAndServices
            goToNextTab={() => goToTab('next')}
            goToPreviousTab={() => goToTab('previous')}
            serviceInformationId={serviceInformationId}
          />
        );
      case 3:
        return (
          <ExpressionOfInterest
            goToNextTab={() => goToTab('next')}
            goToPreviousTab={() => goToTab('previous')}
            serviceInformationId={serviceInformationId}
          />
        );
      case 4:
        return (
          <TechnicalInformation
            goToNextTab={() => goToTab('next')}
            goToPreviousTab={() => goToTab('previous')}
            serviceInformationId={serviceInformationId}
          />
        );
      case 5:
        return (
          <Commercials
            goToPreviousTab={() => goToTab('previous')}
            serviceInformationId={serviceInformationId}
          />
        );
      default:
        return null;
    }
  };

  const handleWorkFlow = async () => {
    const workFlowData = {
      entityId: serviceInformationId || editProcurementId,
      entityName: 'Procurement',
      workflowDescription: 'Procurement Approval WorkFlow',
      parameters: [
        {
          parameterName: 'baseUrl',
          parameterValue: `${window.location.origin}`,
        },
        {
          parameterName: 'procurementId',
          parameterValue: serviceInformationId || editProcurementId,
        },
      ],
      selectedWorkflowType: 'Procurement',
    };

    const response = await initiateWorkFlow(workFlowData);
    if (response.isSuccess) {
      navigate('/requests/procurement-requests', { state: { refresh: true } });
    }
  };

  const handleWorkFlowApproval = async (workflowAction: string) => {
    const workFlowData = {
      requestId: requestId,
      entityId: entityId,
      entityName: entityName,
      comment: '',
      workflowAction: workflowAction,
      approverId: localStorage.getItem('userId'),
      descriptions: '',
    };

    const response = await initiateWorkFlowApproval(workFlowData);
    if (response?.isSuccess) {
      toast.success(response?.data?.message);
      navigate('/application-desk', { state: { refresh: true } });
    } else {
      toast.error(response.errorMessage);
    }
  };

  return (
    <div className="mx-auto max-w-screen-xl px-6 pb-11 pt-9">
      <h1 className="mb-9 text-3xl font-semibold">
        {isOperator === false || isApprover === true
          ? 'Procurement WorkFlow Approval'
          : `${editProcurementId ? 'Edit' : 'Create New'} Procurement Plan`}
      </h1>

      {isOperator === false ? (
        <div className="grid justify-items-end">
          <button
            className="rounded-lg bg-green-500 px-4 py-2 font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            onClick={() => handleWorkFlow()}
          >
            Review Application
          </button>
        </div>
      ) : isApprover === true ? (
        <div className="flex justify-end gap-3">
          <button
            className="w-40 rounded-lg bg-red-500 px-4 py-2 font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            onClick={() => handleWorkFlowApproval(WorkflowActionType.REJECTED)}
          >
            Reject
          </button>
          <button
            className="w-40 rounded-lg bg-green-500 px-4 py-2 font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            onClick={() => handleWorkFlowApproval(WorkflowActionType.APPROVED)}
          >
            Approve
          </button>
        </div>
      ) : null}

      <div>
        <ApplicationProgress percentage={currentStep * 20} />

        <div className="mt-6 flex items-center gap-x-2">
          {[
            'Service Information',
            'Goods and Services',
            'Expression of Interest',
            'Technical Information',
            'Commercials',
          ].map((step, index) => (
            <button
              key={step}
              className={`px-4 py-2.5 ${currentStep === index + 1 ? 'bg-primary text-[#101828]' : 'bg-white-200 text-black-700'} rounded-t-lg text-xs font-semibold`}
              onClick={() => (editProcurementId ? setCurrentStep(index + 1) : undefined)}
            >
              {step}
            </button>
          ))}
        </div>
      </div>

      <div className="rounded-[30px] border border-[#E5E5E5] bg-white px-9 pb-[38px] pt-6 xl:mr-28">
        {renderStep()}
      </div>
    </div>
  );
};

export default CreateProcurementForm;

import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { useLocation } from 'react-router-dom';
import { getLookupType } from '../../../../services/AccountServices';
import { DocumentInterface } from '../../../../types/interfaces';
import { getSupplierDocuments } from '../../../../services/edmsService';
import DocumentList from '../../../../components/DocumentManagement/DocumentList';

interface DocumentProps {
  isEditable: boolean;
  checkListLength: (length: number) => void;
}

export const DocumentTab: React.FC<DocumentProps> = ({ isEditable, checkListLength }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [documentTypeList, setDocumentTypeList] = useState<{ key: string; value: string }[]>([]);
  const [documents, setDocuments] = useState<DocumentInterface[]>([
    {
      documentId: '1',
      documentName: 'Document 1',
      documentType: 'Type 1',
      uploadedBy: 'User 1',
      uploadDate: new Date().toISOString(),
    },
    {
      documentId: '2',
      documentName: 'Document 2',
      documentType: 'Type 2',
      uploadedBy: 'User 2',
      uploadDate: new Date().toISOString(),
    },
  ]);

  const { supplierId, setSupplierId } = useGlobalContext();
  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId');
  const selectedSupplierId = isSupplier ? supplierId : companyId;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    const documentsLength = event.target.checked ? documents.length : 0;
    checkListLength(documentsLength);
  };

  const fetchDocuments = async () => {
    const request = {
      supplierId: selectedSupplierId,
    };
    const response = await getSupplierDocuments(request);
    if (response?.isSuccess) {
      setDocuments(response.data);
      const documentsLength = isChecked ? response.data.length : 0;
      checkListLength(documentsLength);
    }
  };

  useEffect(() => {
    const getDocumentTypes = async () => {
      const response = await getLookupType('supplier_document');
      if (response?.isSuccess) {
        setDocumentTypeList(
          response.data.map((lookup: any) => ({
            key: lookup.lookupId,
            value: lookup.lookupValue,
          }))
        );
      }
    };

    getDocumentTypes();
    fetchDocuments();
  }, []);

  return (
    <div>
      <DocumentList
        isEditable={isEditable}
        entityId={selectedSupplierId}
        module="Supplier"
        documentTypeList={documentTypeList}
        documents={documents}
        fetchDocuments={fetchDocuments}
      />
      <div className="mx-4 mt-5 flex items-center">
        <input
          type="checkbox"
          style={{ marginRight: '8px', width: '20px', height: '20px' }}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className="text-base text-[#525252]">
          To proceed, I confirm that the information provided is accurate and complete to the best
          of my knowledge.
        </label>
      </div>
    </div>
  );
};

export interface MenuItem {
  id: number;
  name: string;
  link: string;
  icon: string;
  children?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    id: 1,
    name: 'Home',
    link: '/dashboard',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/home.svg`,
  },
  {
    id: 2,
    name: 'Supplier Management',
    link: '/supplier-application',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/people.svg`,
  },
  {
    id: 3,
    name: 'Catalogue',
    link: '/catalogue',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/task.svg`,
  },
  {
    id: 4,
    name: 'E-Procurement',
    link: '/procurement',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/bill.svg`,
  },
  {
    id: 5,
    name: 'Electronic Management',
    link: '/electronic-management',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
  },
  {
    id: 6,
    name: 'Requests',
    link: '/requests',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
    children: [
      {
        id: 7,
        name: 'Supplier Requests',
        link: '/requests/supplier-requests',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
      },
      {
        id: 8,
        name: 'Procurement Requests',
        link: '/requests/procurement-requests',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
      },
    ],
  },
  {
    id: 9,
    name: 'Application Desk',
    link: '/application-desk',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/folder-open.svg`,
  },
  {
    id: 10,
    name: 'Notification',
    link: '/notification',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/notification.svg`,
  },
  // {
  //   id: 11,
  //   name: 'Support',
  //   link: '/support',
  //   icon: `${process.env.PUBLIC_URL}/assets/icons/outline/message-question.svg`,
  // },
  {
    id: 11,
    name: 'Configurations',
    link: '/configuration',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
    children: [
      {
        id: 12,
        name: 'Workflow',
        link: '/configuration/workflow',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
      {
        id: 13,
        name: 'Navigations',
        link: '/configuration/menu',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
      {
        id: 14,
        name: 'Roles',
        link: '/configuration/roles',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
      {
        id: 15,
        name: 'Lookup',
        link: '/configuration/lookup',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
      {
        id: 16,
        name: 'Notifications',
        link: '/configuration/notifications',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
      },
    ],
  },
  {
    id: 17,
    name: 'User Management',
    link: '/users',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/people.svg`,
    children: [
      {
        id: 18,
        name: 'Operators',
        link: '/users/operators',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/people.svg`,
      },
      {
        id: 19,
        name: 'Regulators',
        link: '/users/regulators',
        icon: `${process.env.PUBLIC_URL}/assets/icons/outline/people.svg`,
      },
    ],
  },
  {
    id: 20,
    name: 'Settings',
    link: '/settings',
    icon: `${process.env.PUBLIC_URL}/assets/icons/outline/setting.svg`,
  },
];

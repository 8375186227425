import { supplierDetails } from '../../types/procurement-types';

interface RequestViewProposalComponentProps {
  supplierDetails: supplierDetails[];
  handleAllowAccessOrProposal: (tenderAccessId: string) => void;
  handleDenyAccessOrProposal: (tenderAccessId: string) => void;
}

const RequestViewProposalComponent: React.FC<RequestViewProposalComponentProps> = ({
  supplierDetails,
  handleAllowAccessOrProposal,
  handleDenyAccessOrProposal,
}) => {
  return (
    <div className="flex flex-col gap-y-4">
      {supplierDetails.map((supplier, index) => (
        <SuppliersCard
          key={index}
          {...{ supplier }}
          allowAccessOrProposalfn={() => handleAllowAccessOrProposal(supplier.tenderAccessId)}
          denyAccessOrProposalfn={() => handleDenyAccessOrProposal(supplier.tenderAccessId)}
        />
      ))}
    </div>
  );
};

interface supplierCardProps {
  supplier: supplierDetails;
  allowAccessOrProposalfn: () => void;
  denyAccessOrProposalfn: () => void;
}

const SuppliersCard: React.FC<supplierCardProps> = ({
  supplier,
  allowAccessOrProposalfn,
  denyAccessOrProposalfn,
}) => {
  return (
    <div className="rounded-2xl border border-[#E5E5E5] bg-white px-3.5 pb-4 pt-5">
      <div className="mb-4 flex flex-col justify-between gap-y-6 border-b border-[#E9EFF2] pb-4 md:flex-row md:items-center">
        <div className="flex items-center gap-x-6">
          <img
            // src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
            src="https://placehold.co/400"
            alt="vendor images"
            className="h-[58px] w-[58px] rounded-full"
          />

          <div className="flex flex-col items-start gap-y-2">
            <span className="font-semibold text-[#101828] underline">{supplier.businessName}</span>

            <span className="rounded-[30px] bg-[#42BE65] px-2.5 py-1 text-[10px] font-medium text-white">
              Verified Operator
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-4 sm:flex-row">
          {/* //! Bring this back, it is important */}
          {/* <button className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054]">
            View Profile
          </button> */}

          <button
            className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054]"
            onClick={denyAccessOrProposalfn}
          >
            Not Interested
          </button>

          <button
            className="rounded-lg border border-[#FFC726] bg-[#FFC726] px-3.5 py-2 text-sm font-semibold text-[#344054]"
            onClick={allowAccessOrProposalfn}
          >
            Allow Access
          </button>
        </div>
      </div>

      <div className="grid grid-cols-[1.5fr_2fr]">
        <div>
          <p>Ratings & Reviews</p>
          <div className="mb-4 flex gap-x-2.5">
            <div className="flex">
              {Array.from({ length: 4 }).map((_, index) => (
                <img
                  key={index}
                  src={`${process.env.PUBLIC_URL}/assets/icons/outline/ri_star-s-fill.svg`}
                  alt="rating"
                  className="h-[24px] w-[24px] rounded-full"
                />
              ))}
            </div>
            <p>({supplier.review} Reviews)</p>
          </div>

          <div>
            <p className="mb-2 text-base font-semibold text-[#292D32]">Services</p>
            {supplier.tagsList.length > 0 ? (
              <ul className="list-disc pl-4 text-[#667085]">
                {supplier.tagsList.map((tag, index) => (
                  <li key={tag + index}>{tag}</li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-400">N/A</p>
            )}
          </div>
        </div>

        {supplier.productImages.length > 0 ? (
          <div className="grid grid-cols-[2fr_1.8fr_2fr] gap-4">
            {supplier.productImages[0] && (
              <img
                // src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                src={supplier.productImages[0]}
                alt="vendor icon"
                className="h-full max-h-[220px] w-full max-w-[201px] rounded-lg border border-gray-400 object-cover"
              />
            )}

            <div className="grid grid-cols-2 gap-4">
              {/* {Array.from({ length: 4 }).map((_, index) => (
                <img
                  key={index}
                  // src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                  src="https://placehold.co/400"
                  alt="vendor icon"
                  className="h-full w-full rounded-lg object-cover"
                />
              ))} */}

              {supplier.productImages[1] && (
                <img
                  // src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                  src={supplier.productImages[1]}
                  alt="vendor icon"
                  className="h-[100px] w-full rounded-lg border border-gray-400 object-cover"
                />
              )}
              {supplier.productImages[2] && (
                <img
                  // src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                  src={supplier.productImages[2]}
                  alt="vendor icon"
                  className="h-[100px] w-full rounded-lg border border-gray-400 object-cover"
                />
              )}
              {supplier.productImages[3] && (
                <img
                  // src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                  src={supplier.productImages[3]}
                  alt="vendor icon"
                  className="h-[100px] w-full rounded-lg border border-gray-400 object-cover"
                />
              )}
              {supplier.productImages[4] && (
                <img
                  // src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                  src={supplier.productImages[4]}
                  alt="vendor icon"
                  className="h-[100px] w-full rounded-lg border border-gray-400 object-cover"
                />
              )}
            </div>

            {supplier.productImages[5] && (
              <img
                // src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                src={supplier.productImages[5]}
                alt="vendor icon"
                className="h-full max-h-[220px] w-full max-w-[201px] rounded-lg border border-gray-400 object-cover"
              />
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <p>No Image Available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestViewProposalComponent;

import React, { useEffect, useState } from 'react';
import CustomTable, { Column, IPageRequest } from '../../components/CustomTable';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { useTranslation } from 'react-i18next';
import AppButton from '../../components/AppButton/AppButton';
import { useNavigate } from 'react-router-dom';
import { deleteOperator, getOperators } from '../../services/OperatorServices';
import Delete from '../../components/Delete/Delete';
import { OperatorCompanyInfo } from '../../types/interfaces';
import { toast } from 'react-toastify';

const Index = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [operators, setOperators] = useState<OperatorCompanyInfo[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<OperatorCompanyInfo | null>(null);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [pageCount, setPageCount] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const breadcrumbPaths = [
    { label: 'Dashboard', path: '/dashboard' },
    { label: 'Operators', path: '/users/operators' },
  ];

  const pageRequest: IPageRequest = {
    pageNumber: currentPage,
    pageSize: itemsPerPage,
  };

  const openModal = (item: any) => {
    setIsOpenModal(true);
  };

  const deleteModal = (item: OperatorCompanyInfo) => {
    setIsDeleteModalOpen(true);
    setSelectedOperator(item);
  };

  const deleteOperatorItem = async () => {
    if (!selectedOperator) return;
    setIsDeleteModalOpen(false);
    const response = await deleteOperator(selectedOperator.organizationId!);
    if (response?.isSuccess) {
      toast.success(response?.data.message);
      fetchOperators();
    }
  };

  const fetchOperators = async () => {
    const response = await getOperators(pageRequest);
    if (response) {
      setOperators(response.data);
      if (response.totalRecords) {
        setPageCount(Math.ceil(response.totalRecords / itemsPerPage));
      }
    }
  };

  useEffect(() => {
    fetchOperators();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const columns: Column[] = [
    {
      title: t('operatorName', 'Operator Name'),
      dataKey: 'organizationName',
      type: 'string',
    },
    {
      title: t('rcNumber', 'RC Number'),
      dataKey: 'registrationNumber',
      type: 'string',
    },
    {
      title: t('country', 'Country'),
      dataKey: 'country',
      type: 'string',
    },
    {
      title: t('dateCreated', 'Date Created'),
      dataKey: 'dateOfEstablishment',
      type: 'dateTime',
    },
    {
      title: t('actions', 'Actions'),
      dataKey: 'actions',
      type: 'action',
      actions: [
        {
          label: t('edit', 'Edit'),
          className: 'text-green-500',
          action: (item: OperatorCompanyInfo) =>
            navigate(`/operator-management/${item.organizationId}`, {
              state: { isEditMode: true },
            }),
          status: true,
        },
        {
          label: t('delete', 'Delete'),
          className: 'text-red-500',
          action: (item: OperatorCompanyInfo) => deleteModal(item), // Handle delete
          status: true,
        },
      ],
    },
  ];
  return (
    <div className="h-[100vh] bg-gray-50 px-6 py-6">
      <div className="mt-12 flex justify-between">
        <div className="">
          <h1 className="text-2xl font-semibold">{t('Operators', 'Operators')}</h1>
          <Breadcrumb paths={breadcrumbPaths} />
        </div>
        <div>
          <AppButton
            className="rounded-lg bg-primary px-7 py-3 text-sm font-medium"
            title={'Add Operator'}
            onClick={() => navigate('/operator-management')}
          />
        </div>
      </div>
      <div>
        <CustomTable
          data={operators}
          columns={columns}
          pageCount={pageCount}
          showPagination={true}
          showSearch={true}
          onSearch={handleSearch}
          onPageChange={(selectedPage) => handlePageChange(selectedPage)}
        />
      </div>

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure?')}
          desc={t('deleteConfirmation', 'This action is permanent, all data would be lost.')}
          onDelete={deleteOperatorItem}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Index;

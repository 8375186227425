import { useQuery } from '@tanstack/react-query';
import { Search } from 'lucide-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getProcurementProposals } from '../../../../services/procurementService';
import { toast } from 'react-toastify';
import moment from 'moment';

const ProposalsComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: proposals = [] } = useQuery({
    queryKey: ['procurements-proposals'],
    queryFn: async () => {
      try {
        const response = await getProcurementProposals();
        return response?.data || [];
      } catch (error) {
        toast.error('Failed to fetch proposals. Please try again later.');
        throw error;
      }
    },
    // staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Do not refetch when the window regains focus
    retry: 2, // Retry fetching up to 2 times in case of failure
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="min-h-[85vh] rounded-2xl border border-[#E5E5E5] bg-white p-8">
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full rounded-lg border border-[#D0D5DD] bg-white px-3.5 py-2.5 pl-12 text-base text-gray-800"
        />
        <Search
          className="absolute left-4 top-1/2 -translate-y-1/2 transform text-gray-500"
          size={20}
        />
      </div>

      <div className="overflow-auto rounded-xl border border-[#E4E7EC] shadow-sm">
        {proposals.length > 0 ? (
          <>
            <div className="grid grid-cols-[2fr_1fr_1fr_1fr_.5fr] gap-4 rounded-t-xl border-b border-[#D2D6DB] bg-[#F5F5F5] px-5 py-[14px] text-sm font-semibold text-[#40474F]">
              <span>Opportunity</span>
              <span>Type</span>
              <span>Deadline</span>
              <span>No of Interests</span>
              <span>Action</span>
            </div>

            {proposals.map((proposal, index) => (
              <Link
                key={proposal.serviceInformationId}
                to={`/procurement/proposals/${proposal.serviceInformationId}`}
                className="grid grid-cols-[2fr_1fr_1fr_1fr_.5fr] items-center gap-4 border-b border-[#D2D6DB] px-5 py-[14px]"
              >
                <span className="text-base font-semibold text-[#101828]">{proposal.title}</span>
                <span className="text-sm font-medium text-[#525252]">{proposal.businessType}</span>
                <span className="text-sm font-medium text-[#525252]">
                  {moment(proposal.bidEndDate).format('DD/MM/YYYY')}
                </span>
                <span className="text-sm font-medium text-[#525252]">{proposal.interest}</span>
                <span></span>
              </Link>
            ))}

            <div className="flex items-center justify-between px-6 pb-4 pt-3">
              <span className="text-sm text-[#344054]">Page 1 of 10</span>

              <div className="flex gap-x-3">
                <button
                  className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm"
                  disabled
                >
                  Previous
                </button>

                <button
                  className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm"
                  disabled
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex min-h-[40vh] items-center justify-center">
            <div>
              <h3 className="text-center text-2xl font-semibold text-[#161616]">
                No Proposals Available
              </h3>
              <p className="text-center text-sm font-medium text-[#7F7F7F]">
                Proposals are not available for this tab
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProposalsComponent;

import React, { TextareaHTMLAttributes } from 'react';
import { useField } from 'formik';

type FormikTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  name: string;
  isRequired?: boolean;
};

const FormikTextarea: React.FC<FormikTextareaProps> = ({ label, isRequired, ...props }) => {
  const [field, meta] = useField({
    name: props.name,
    value: props.value,
  });

  return (
    <div className="mb-5">
      {label ? (
        <label
          htmlFor={props.id || props.name}
          className="mb-1.5 block text-sm font-medium text-[#344054]"
        >
          {label}
          {isRequired && <span className="ml-1 text-red-500">*</span>}
        </label>
      ) : null}

      <textarea
        {...field}
        {...props}
        className={`min-h-32 w-full resize-none rounded-md border px-3.5 py-2.5 shadow-sm ${meta.touched && meta.error ? 'border-red-500' : 'border-[#D0D5DD]'} ${props.className || ''} `}
      />

      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikTextarea;

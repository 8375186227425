import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmEmail } from '../../services/AccountServices';

const ConfirmEmailComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [confirmationStatus, setConfirmationStatus] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    const tokenParam = params.get('code');

    if (emailParam) setEmail(emailParam);
    if (tokenParam) setToken(tokenParam);
  }, [location]);

  useEffect(() => {
    if (email && token) {
      handleConfirm();
    }
  }, [email, token]);

  const handleConfirm = async () => {
    if (email && token) {
      try {
        const otpData = {
          email,
          code: token,
        };

        const response = await ConfirmEmail(otpData);
        if (response?.isSuccess) {
          setConfirmationStatus('success');
        } else {
          setConfirmationStatus('error');
        }
      } catch (error) {
        setConfirmationStatus('error');
      }
    }
  };

  return (
    <div className="py-8">
      <h1 className="mb-6 text-center text-3xl font-semibold">Authorization</h1>
      {confirmationStatus && (
        <div>
          <div
            className={`w-full ${confirmationStatus === 'success' ? 'bg-green-600' : 'bg-red-500'} p-5 text-center text-white`}
          >
            {confirmationStatus === 'success'
              ? 'Email confirmed successfully!'
              : 'Email confirmation failed.'}
          </div>
          <div style={{ marginTop: '20px' }}>
            <p
              onClick={() => navigate('/login')}
              className="cursor-pointer text-center text-black underline"
            >
              Go to Login
            </p>
          </div>
        </div>
      )}
      {email && token ? (
        <div>
          {/* <p>Email: {email}</p>
                    <p>Token: {token}</p>
                    <button onClick={handleConfirm}>Confirm</button> */}
        </div>
      ) : (
        <p className="w-full bg-red-500 p-5 text-center text-white">
          Invalid or missing email/token.
        </p>
      )}
    </div>
  );
};

export default ConfirmEmailComponent;

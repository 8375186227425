import axiosInstance from '../axiosInstance';
import { Endpoint } from './endpoint/endpoint';
import { toast } from 'react-toastify';

interface PageRequest {
  pageNumber: number;
  pageSize: number;
}

const handleError = (error: any) => {
  if (error?.response) {
    const errorMessage = error?.response?.data?.errors?.[0]?.reason || 'An error occurred';
    toast.error(errorMessage);
  }
};

export const getOperators = async (pageRequest: PageRequest) => {
  const url = `${Endpoint.operator.get}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getOperatorById = async (operatorId: string) => {
  const url = Endpoint.operator.getAOperator(operatorId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createOperator = async (data: any) => {
  const url = Endpoint.operator.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createOperatorUser = async (data: any) => {
  const url = Endpoint.operator.createUser;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateOperator = async (data: any) => {
  const url = Endpoint.operator.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteOperator = async (operatorId: string) => {
  const url = Endpoint.operator.delete(operatorId);
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getOperatorContacts = async (operatorId: string, pageRequest: PageRequest) => {
  const url = `${Endpoint.operator.contact.get}?operatorId=${operatorId}&pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getOperatorContactById = async (contactId: string) => {
  const url = Endpoint.operator.contact.getOne(contactId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createOperatorContact = async (data: any) => {
  const url = Endpoint.operator.contact.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateOperatorContact = async (data: any) => {
  const url = Endpoint.operator.contact.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteOperatorContact = async (contactId: string) => {
  const url = Endpoint.operator.contact.delete(contactId);
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};
